import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';


// Define the initial state for home data
const initialState = {
    privacyPolicy: null,  // Store blogs
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    loading: false,
    error: null,
};


// Create async thunk for fetching home data
export const fetchPolicyData = createAsyncThunk('policy/fetchPolicyData', async () => {
    const response = await axios.get(`${API_URL}/privacy-policy`);
    return response.data;
});


// Create the slice
const privacyPolicySlice = createSlice({
    name: 'privacyPolicy',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPolicyData.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchPolicyData.fulfilled, (state, action) => {
                state.privacyPolicy = action.payload.privacyPolicy;
                state.status = 'succeeded';
                state.loading = false;
            })
            .addCase(fetchPolicyData.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            });

    },
});

// Export the async thunk and the reducer
export default privacyPolicySlice.reducer;