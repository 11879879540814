import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/slice/authSlice';
import Swal from 'sweetalert2';

export default function LoginPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Extract query parameter
    const params = new URLSearchParams(location.search);
    const redirectTo = params.get('redirectTo');

    const { loading, isVendor } = useSelector(state => state.auth);

    const showConfirm = (message) => {
        Swal.fire({
            title: 'Success', 
            icon: 'success',
            text: message,
            confirmButtonText: 'OK',
        }).then(() => {
            // Redirect based on vendor status
            console.log("Vendor__", isVendor);
            if (isVendor) {
                navigate('/');
            } else {
                if(redirectTo === 'verify-account'){
                    console.log("redirect--", redirectTo);
                    navigate('/');
                }else{
                    navigate(-1);
                }
            }
        });
    };

    const showError = (message) => {
        Swal.fire({
            title: 'Error', 
            icon: 'error',
            text: message,
            confirmButtonText: 'OK',
        });
    };

    const initialValues = {
        email: '',
        password: ''
    };

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await dispatch(loginUser(values)).unwrap();
            if (response?.access_token) {
                showConfirm("Login Successful!");
            } else if (response?.notification) {
                showError(response.notification);
            }
        } catch (error) {
            showError("Login failed! Please try again.");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <InnerBanner title="Login" />
            <section className="flat-spacing-10">
                <div className="container">
                    <div className="tf-grid-layout lg-col-2 tf-login-wrap">
                        <div className="tf-login-form">
                            <div id="login">
                                <h5 className="mb_36">Log in</h5>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="tf-field style-1 mb_15">
                                                <Field 
                                                    name="email" 
                                                    type="email"
                                                    className="tf-field-input tf-input" 
                                                    placeholder=" "
                                                />
                                                <label className="tf-field-label fw-4 text_black-2" htmlFor="email">
                                                    Email *
                                                </label>
                                                <ErrorMessage 
                                                    name="email" 
                                                    component="p" 
                                                    className="text-danger mb-3" 
                                                />
                                            </div>

                                            <div className="tf-field style-1 mb_30">
                                                <Field 
                                                    name="password" 
                                                    type="password"
                                                    className="tf-field-input tf-input" 
                                                    placeholder=" "
                                                />
                                                <label className="tf-field-label fw-4 text_black-2" htmlFor="password">
                                                    Password *
                                                </label>
                                                <ErrorMessage 
                                                    name="password" 
                                                    component="p" 
                                                    className="text-danger mb-3" 
                                                />
                                            </div>

                                            <div className="mb_20">
                                                <NavLink to="/forgot" className="tf-btn btn-line">Forgot your password?</NavLink>
                                            </div>

                                            <div>
                                                <button
                                                    type="submit"
                                                    className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center"
                                                    disabled={isSubmitting || loading}
                                                >
                                                    {loading ? 'Logging...' : 'Log In'}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                        <div className="tf-login-content">
                            <h5 className="mb_36">Create Your New Account</h5>
                            <p className="mb_20">
                                Register with us to explore a seamless shopping experience and keep track of your orders. Stay connected with Apoorva Products!
                            </p>
                            <NavLink to="/register" className="tf-btn btn-line">
                                Register<i className="icon icon-arrow1-top-left"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
