import React, { useEffect, useMemo, useState } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { Container, Spinner } from 'react-bootstrap'
import { currency } from '../../Currency'
import { useDispatch, useSelector } from 'react-redux'
import { useAddToCart } from '../../hooks/useAddToCart'
import { fetchAddToCart } from '../../redux/slice/addToCartSlice'
import Skeleton from 'react-loading-skeleton'
import { NavLink, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../gobal/Global'
import { fetchCountryData } from '../../redux/slice/Location'
import CheckDelivery from '../elements/CheckDelivery'
import { showError } from '../../gobal/sweetalertManage'
import DeliveryReturn from '../elements/models/DeliveryReturn'
import { fetchFlashSale } from '../../redux/slice/flashSaleSlice'



export default function ShoppingCartPage() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToCart, loading } = useSelector(state => state.addToCart);
  const { isLoggedIn } = useSelector(state => state.auth);
  const { handleRemoveCart, incrementQty, decrementQty } = useAddToCart(isLoggedIn);
  const { setting } = useSelector((state) => state.setup);
  const { image_content } = useSelector(state => state.setup);
  const {
    flashSale, 
    products
  } = useSelector(state => state.flashSale);
  
  useEffect(() => {
    if(isLoggedIn){
        dispatch(fetchAddToCart());
        dispatch(fetchCountryData());
        dispatch(fetchFlashSale());
    }
  },[isLoggedIn])

  
  const getProductPrice = (product) => {
    return flashSale?.offer && product?.is_flash_sale
    ? product.flash_price
    : product.offer_price;
  };

  
  const subTotal = useMemo(() => {
    return addToCart?.reduce((total, item) => total + getProductPrice(item.product) * item.qty, 0);
  }, [addToCart, flashSale]);
  
  
  const handleCheckout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/checkout");
  };


  return (
    <React.Fragment>
        <InnerBanner title="Shopping Cart"/>
        <section className="flat-spacing-11">
          <Container>
            <div className="tf-page-cart-wrap">
              {
                loading ? <div className='position-relative'><div className="spinner-overlay"><Spinner/></div></div> :
                <div className="tf-page-cart-item">
                  <form >
                    <table className="tf-table-page-cart">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                        </tr>
                      </thead>

                      <tbody>
                      {
                        loading ? <Skeleton count={2} height={100}/> :
                        (addToCart?.length === 0) ? 
                          <div className="text-center mt-3">
                            <img src={`${BASE_URL}/${image_content?.empty_cart}`} alt="Empty Cart"/>
                            <p>Your cart is empty. Browse products to add items to your cart!</p>
                          </div> :
                          addToCart?.map((data,index) => {
                          return (
                            <tr key={`cart-${index}`} className="tf-cart-item file-delete">
                              <td className="tf-cart-item_product">
                                <NavLink to={`/product-detail/${data.product.slug}`} className="img-box">
                                  <img src={`${BASE_URL}/${data.product.thumb_image}`} alt="img-product"/>
                                </NavLink>
                                <div className="cart-info">
                                  <NavLink to={`/product-detail/${data.product.slug}`} className="cart-title link">{data.product.short_name}</NavLink>
                                    <span className="remove-cart link remove" onClick={() => handleRemoveCart(data.id)}>Remove</span>
                                </div>
                              </td>
                              <td className="tf-cart-item_total" cart-data-title="Total">
                                <div className="cart-total">
                                  {setting ? setting.currency_icon : currency.icon}
                                  {getProductPrice(data.product)}
                                </div>
                              </td>
                              <td className="tf-cart-item_quantity" cart-data-title="Quantity">
                                <div className="cart-quantity">
                                  <div className="wg-quantity">
                                    <span className="btn-quantity minus-btn" onClick={() => decrementQty(data.id)} aria-label="Decrease Quantity">
                                      <svg className="d-inline-block" width="9" height="1" viewBox="0 0 9 1" fill="currentColor"><path d="M9 1H5.14286H3.85714H0V1.50201e-05H3.85714L5.14286 0L9 1.50201e-05V1Z"></path></svg>
                                    </span>
                                    <input type="text" name="number" value={data.qty} readOnly/>
                                    <span className="btn-quantity plus-btn" onClick={() => incrementQty(data.id)} aria-label="Increase Quantity">
                                      <svg className="d-inline-block" width="9" height="9" viewBox="0 0 9 9" fill="currentColor"><path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z"></path></svg>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="tf-cart-item_total" cart-data-title="Total">
                                <div className="cart-total">{ setting ? setting.currency_icon : currency.icon }{(getProductPrice(data.product) * data.qty).toFixed(2)}</div>
                              </td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </table>
                    
                  </form>
                </div>
              }
              <div className="tf-page-cart-footer">
                <div className="tf-cart-footer-inner">
                  <div className="tf-page-cart-checkout">
                    <CheckDelivery/>
                    
                    <div className="tf-cart-totals-discounts">
                        <h3>Subtotal</h3>
                        <span className="total-value">{ setting ? setting.currency_icon : currency.icon }{subTotal.toFixed(2)} {currency.code}</span>
                    </div>
                    <p className="tf-cart-tax">
                        Taxes and <NavLink data-bs-target="#delivery_return" data-bs-toggle="modal">shipping</NavLink>  calculated at checkout
                    </p>
                    
                    <div className="cart-checkout-btn">
                        <button onClick={handleCheckout} className="tf-btn w-100 btn-fill animate-hover-btn radius-3 justify-content-center">
                            <span>Check out</span>
                        </button>
                    </div>
                  </div>
                </div>
                <div className="tf-product-info-delivery-return">
                  <div className="row">
                    <div className="col-xl-12 col-12 mt-3">
                      <div className="tf-product-delivery">
                        <div className="icon">
                          <i className="icon-delivery-time"></i>
                        </div>
                        <p>Estimate delivery times: <span className="fw-7">12-26 days</span> (India).</p>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <DeliveryReturn/>
    </React.Fragment>
  )
}
