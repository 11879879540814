import React, { useDebugValue, useEffect } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWishlist, removeFromWishlist } from '../../redux/slice/wishlistSlice';
import Preloader from '../include/Preloader';
import { NavLink, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../gobal/Global';
import { currency } from '../../Currency';
import { showConfirm, showError } from '../../gobal/sweetalertManage';
import { useAddToCart } from '../../hooks/useAddToCart';

export default function Wishlist() {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(state => state.auth);
  const { wishlist, loading : wishloading } = useSelector((state) => state.wishlist);
  const { handleCartListToggle, isProductInCartlist } = useAddToCart(); // No longer needs productId as a parameter
  const { image_content } = useSelector(state => state.setup);

  useEffect(() => {
    dispatch(fetchWishlist());
  },[]);

  const handleAddToCart = async (productId) => {
    if (isLoggedIn) {
        await handleCartListToggle(productId);
        // showConfirm(isProductInCartlist(productId) ? "Removed from cart" : "Added to cart");
    } else {
        navigate('/login'); // Redirect to login if not logged in
    }
  };

  // Handle removing from wishlist
  const handleRemoveFromWishlist = (wishlistId) => {
    if (wishlistId) { // If the product is in the wishlist
        dispatch(removeFromWishlist(wishlistId))
          .unwrap()
          .then(() => showConfirm("Removed successfully"))
          .catch((error) => showError(error.message || "Failed to remove from wishlist"));
    }
  };

  if(wishloading || !wishlist){
    return <Preloader/>
  }

  

  console.log("WishList__:",wishlist);

  return (
    <React.Fragment>
        <InnerBanner title="Your Wishlist"/>
        <section className="flat-spacing-2">
            <Container>
                <div className="grid-layout wrapper-shop" data-grid="grid-4">
                    {
                      wishlist?.length > 0 ? 
                      wishlist.map((data, index) => {
                        const inCart = isProductInCartlist(data.product_id);
                        return(
                          <div key={`wishlistproduct-${index}`} class="card-product">
                            <div class="card-product-wrapper">
                                <NavLink to={`/product-detail/${data.product.slug}`} class="product-img" style={{height: "314px"}}>
                                    <img class="lazyload img-product" data-src={`${BASE_URL}/${data.product.thumb_image}`} src={`${BASE_URL}/${data.product.thumb_image}`} alt="image-product"/>
                                    <img class="lazyload img-hover" data-src={`${BASE_URL}/${data.product.thumb_image}`} src={`${BASE_URL}/${data.product.thumb_image}`} alt="image-product"/>
                                </NavLink>
                                <div class="list-product-btn type-wishlist">
                                    <button onClick={() => handleRemoveFromWishlist(data.id)} class="box-icon bg_white wishlist">
                                        <span class="tooltip">Remove Wishlist</span>
                                        <span class="icon icon-delete"></span>
                                    </button>
                                </div>
                                <div class="list-product-btn ">
                                  <button onClick={() => handleAddToCart(data.product_id)} class="box-icon bg_white quick-add tf-btn-loading">
                                  <span className={inCart ? "icon icon-delete" : "icon icon-bag"}></span>
                                  <span className="tooltip">{inCart ? "Remove from cart" : "Add to cart"}</span>
                                  </button>
                                </div>
                            </div>
                            <div class="card-product-info">
                                <NavLink to={`/product-detail/${data.product.slug}`} class="title link">{data.product.name}</NavLink>
                                <span class="price">{currency.icon}{data.product.price}</span>
                            </div>
                          </div>
                        )
                      }
                        
                      ) : 
                        <div className="text-center mt-3">
                          <img src={`${BASE_URL}/${image_content?.empty_wishlist}`} alt="Empty Cart"/>
                          <p>No products added in wishslist</p>
                        </div>
                    }
                </div>
            </Container>
        </section>
    </React.Fragment>
  )
}
