import React, { useEffect, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import DateFormat from '../../../utils/DateFormat';
import { fetchShowOrder } from '../../../redux/slice/orderShowSlice';
import { statuses } from '../../../gobal/Global';
import { cancelOrder } from '../../../redux/slice/cancelOrderSlice';
import { showConfirm, showError } from '../../../gobal/sweetalertManage';

export default function OrderDetail() {


    const { order_id } = useParams();
    const dispatch = useDispatch();
    const { order, loading } = useSelector((state) => state.order);
    const { setting } = useSelector(state => state.setup);

    const [activeTab, setActiveTab] = useState(0);
    const [subTotal, setSubtotal ] = useState(0);
    const [cancelLoading, setCancelLoading] = useState(false); // Track cancel button loading state

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    useEffect(() => {
        dispatch(fetchShowOrder(order_id)); // Fetch the first page of orders
    }, []);
    
    // Calculate and set the subtotal whenever addToCart or quantities change
    useEffect(() => {
        const calculatedSubtotal = order?.order_products?.reduce((total, item) => total + item.unit_price * item.qty, 0);
        setSubtotal(calculatedSubtotal);
    }, [order?.order_products]);
    


    const handleCancelOrder = async () => {
        setCancelLoading(true);
    
        try {
            const action = await dispatch(cancelOrder(order_id)); // Await the dispatched action
            if (cancelOrder.fulfilled.match(action)) {
                // Extract notification from the action payload
                const notification = action.payload?.notification;
                if (notification) {
                    showConfirm(notification); // Show success message
                }
            } else if (cancelOrder.rejected.match(action)) {
                // Extract error notification
                const errorMessage = action.payload?.notification || 'Failed to cancel the order. Please try again later.';
                showError(errorMessage);
            }
        } catch (error) {
            console.error("Unexpected Error:", error);
            showError('An unexpected error occurred.');
        } finally {
            setCancelLoading(false);
        }
    };
    
    if(loading) {
        return <Spinner/>
    }


    // console.log("OrderDetail__", order);

    return (
        <React.Fragment>
            <Col lg="9">
                <div className="wd-form-order">
                    <div className="order-head">
                        <div className="content">
                            <div className="badge">{statuses.find(status => status.id === order?.order_status)?.name}</div>
                            <h6 className="mt-8 fw-5">Order #{order?.order_id}</h6>
                        </div>
                        {/* Add the Cancel Order Button */}
                        {order?.order_status !== 5 && (  // Assuming status 5 is for canceled orders
                            <button
                                type='button'
                                className={`tf-cancel-order ${ order?.order_status === 4 ? "disable" : ""}`}
                                onClick={handleCancelOrder}
                                disabled={cancelLoading || order?.order_status === 4} // Disable when already canceled or loading
                            >
                                {cancelLoading ? 'Canceling...' : 'Cancel Order'}
                            </button>
                        )}
                    </div>
                    <div className="tf-grid-layout md-col-2 gap-15">
                        <div className="item">
                            <div className="text-2 text_black-2">Item</div>
                            <div className="text-2 mt_4 fw-6">{order?.order_products?.reduce((qty, item) => qty + item.qty, 0)}</div>
                        </div>
                        <div className="item">
                            <div className="text-2 text_black-2">Courier</div>
                            <div className="text-2 mt_4 fw-6">Package Picker</div>
                        </div>
                        <div className="item">
                            <div className="text-2 text_black-2">Start Time</div>
                            <div className="text-2 mt_4 fw-6">{DateFormat(order?.created_at)}</div>
                        </div>
                        <div className="item">
                            <div className="text-2 text_black-2">Billing Name</div>
                            <div className="text-2 mt_4 fw-6">{order?.order_address?.billing_name}</div>
                        </div>
                        <div className="item">
                            <div className="text-2 text_black-2">Address</div>
                            <div className="text-2 mt_4 fw-6">{order?.order_address?.billing_address}, {order?.order_address?.billing_city}, {order?.order_address?.billing_state}, {order?.order_address?.billing_country}</div>
                        </div>
                        <div className="item">
                        <div className="text-2 text_black-2">Billing</div>
                            <NavLink to={`/invoice/${order_id}`} className="tf-btn btn-fill animate-hover-btn">
                                Invoice 
                            </NavLink>
                        </div>
                    </div>
                    <div className="widget-tabs style-has-border widget-order-tab">
                        <ul className="widget-menu-tab">
                            {['Items', 'Order History', 'Courier', 'Receiver'].map((tab, index) => (
                                <li
                                    key={index}
                                    className={`item-title ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index)}
                                >
                                    <span className="inner">{tab}</span>
                                </li>
                            ))}
                        </ul>
                        <div className="widget-content-tab">
                            <div className={`widget-content-inner ${activeTab === 0 ? 'active' : ''}`}>
                                <ul>
                                {
                                    order?.order_products?.map(data => (
                                    <li key={`product-${data.id}`} className=''>
                                        <div className="order-head">
                                            <div className="content">
                                                <div className="text-2 fw-6">{data.product_name}</div>
                                                <div className="mt_4"><span className="fw-6">Price :</span> {setting?.currency_icon}{data?.unit_price?.toFixed(2)}</div>
                                                <div className="mt_4"><span className="fw-6">Quantity :</span> {data.qty}</div>
                                            </div>
                                            
                                        </div>

                                    </li>
                                    ))
                                }
                                </ul>
                                <ul>
                                    <li className="d-flex justify-content-between text-2">
                                        <span>Total Price</span>
                                        <span className="fw-6">{setting.currency_icon}{subTotal?.toFixed(2)}</span>
                                    </li>
                                    <li className="d-flex justify-content-between text-2 mt_4 pb_8 line">
                                        <span>Shipping Cost</span>
                                        <span className="fw-6">{setting?.currency_icon}{order?.shipping_cost?.toFixed(2)}</span>
                                    </li>
                                    <li className="d-flex justify-content-between text-success text-2 mt_4 pb_8 line">
                                        <span>Discount</span>
                                        <span className="fw-6">-{setting?.currency_icon}{order?.coupon_coast?.toFixed(2)}</span>
                                    </li>
                                    <li className="d-flex justify-content-between text-2 mt_8">
                                        <span>Order Total</span>
                                        <span className="fw-6">{setting?.currency_icon}{order?.total_amount?.toFixed(2)}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className={`widget-content-inner ${activeTab === 1 ? 'active' : ''}`}>
                                <div className="widget-timeline">
                                    {/* <ul className="timeline">
                                        <li>
                                            <div className="timeline-badge success"></div>
                                            <div className="timeline-box">
												<a className="timeline-panel" href="javascript:void(0);">
													<div className="text-2 fw-6">Product Shipped</div>
													<span>10/07/2024 4:30pm</span>
												</a>
												<p><strong>Courier Service : </strong>FedEx World Service Center</p>
												<p><strong>Estimated Delivery Date : </strong>12/07/2024</p>
											</div>
                                        </li>
                                        <li>
                                            <div className="timeline-badge success"></div>
                                            <div className="timeline-box">
												<a className="timeline-panel" href="javascript:void(0);">
													<div className="text-2 fw-6">Product Shipped</div>
													<span>10/07/2024 4:30pm</span>
												</a>
												<p><strong>Tracking Number : </strong>2307-3215-6759</p>
												<p><strong>Warehouse : </strong>T-Shirt 10b</p>
											</div>
                                        </li>
                                        <li>
                                            <div className="timeline-badge"></div>
                                            <div className="timeline-box">
												<a className="timeline-panel" href="javascript:void(0);">
													<div className="text-2 fw-6">Product Packaging</div>
													<span>12/07/2024 4:34pm</span>
												</a>
											</div>
                                        </li>
                                        <li>
                                            <div className="timeline-badge"></div>
                                            <div className="timeline-box">
												<a className="timeline-panel" href="javascript:void(0);">
													<div className="text-2 fw-6">Order Placed</div>
													<span>11/07/2024 2:36pm</span>
												</a>
											</div>
                                        </li>
                                    </ul> */}
                                    <p>Courier service facality avail soon...</p>
                                </div>
                            </div>
                            
                            <div className={`widget-content-inner ${activeTab === 2 ? 'active' : ''}`}>
                                <p>Our courier service is dedicated to providing fast, reliable, and secure delivery solutions tailored to meet your needs. Whether you're sending documents, parcels, or larger shipments, our team ensures that your items are handled with the utmost care and delivered on time. With a commitment to customer satisfaction, real-time tracking, and a wide network of routes, we make it easy for you to send and receive packages both locally and internationally. Choose our service for a seamless and efficient delivery experience.</p>
                            </div>
                            <div className={`widget-content-inner ${activeTab === 3 ? 'active' : ''}`}>
                                <p className="text-2 text_success">Thank you Your order has been received</p>
                                <ul className="mt_20">
                                    <li>Order Number : <span className="fw-7">#{order_id}</span></li>
                                    <li>Date : <span className="fw-7"> {DateFormat(order?.created_at)}</span></li>
                                    <li>Total : <span className="fw-7">{setting?.currency_icon} {order?.total_amount?.toFixed(2)}</span></li>
                                    <li>Payment Methods : <span className="fw-7">{order?.payment_method}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </React.Fragment>
    )
}
