

export const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const WEB_URL = process.env.REACT_APP_PRODUCTION_URL;

export const statuses = [
    { id: 0, name: 'pending' },
    { id: 1, name: 'in progress' },
    { id: 2, name: 'delivered' },
    { id: 3, name: 'completed' },
    { id: 4, name: 'declined' },
    { id: 5, name: 'dispatch' }
];


// Category List 
export const CategoryList = [
    {
        id: 1,
        name: 'Cables',
        image: 'cable.svg',
    },
    {
        id: 2,
        name: 'Screen Protection',
        image: 'screen-protection.svg',
    },
    {
        id: 3,
        name: 'Chargers',
        image: 'chargers.svg',
    },
    {
        id: 4,
        name: 'Audio',
        image: 'audio.svg',
    },
    {
        id: 5,
        name: 'Wireless Chargers',
        image: 'wireless-chargers.svg',
    },
    {
        id: 6,
        name: 'MagSafe',
        image: 'magSafe.svg',
    },
    {
        id: 7,
        name: 'Gaming',
        image: 'gaming.svg',
    },
    {
        id: 8,
        name: 'Storage',
        image: 'storage.svg',
    },
];

// Slider Data list 
export const SliderData = [
    {
        id: 1,
        title: 'Account Solutions',
        image: 'ac-soft-banner.png',
        content: 'Find your accounting solution.',
    },
    {
        id: 2,
        title: 'Bumper Sale',
        image: 'sale-banner-2.png',
        content: 'Offer on Mouse.',
    },
    {
        id: 3,
        title: 'Accessory Sale',
        image: 'accessories2_slideshow3.jpg',
        content: 'Up to 40% off chargers, earbuds, and more.',
    }
];


//  Product Data list 
export const ProductData = [
    {
        id: 1,
        name: 'Case with MagSafe',
        price: '199.00',
        image1: 'bark-phone-blue.jpg',
        image2: 'bark-phone-blue2.jpg',
        description: 'A sleek and durable case designed for MagSafe compatibility, providing reliable protection for your phone.',
        slug: 'case-with-magsafe'
    },
    {
        id: 2,
        name: 'MagSafe 3 Cable',
        price: '39.00',
        image1: 'cable-black.jpg',
        image2: 'cable-black2.jpg',
        description: 'A high-quality MagSafe 3 cable, perfect for fast charging and secure connectivity.',
        slug: 'magsafe-3-cable'
    },
    {
        id: 3,
        name: 'Beats Studio Buds',
        price: '199.00',
        image1: 'headphone-red2.jpg',
        image2: 'headphone-red.jpg',
        description: 'Wireless earbuds with powerful sound and noise-cancellation, ideal for music lovers on the go.',
        slug: 'beats-studio-buds'
    },
    {
        id: 4,
        name: 'Blue Ocean Band',
        price: '1800.00',
        image1: 'albert-black.jpg',
        image2: 'albert-white.jpg',
        description: 'A premium band designed for the stylish professional, made from high-quality materials with a sleek finish.',
        slug: 'blue-ocean-band'
    },
    {
        id: 5,
        name: '3-in-1 Wireless Charging',
        price: '199.00',
        image1: 'wireless-charging-white.jpg',
        image2: 'wireless-charging-white2.jpg',
        description: 'A convenient 3-in-1 wireless charging station that powers your phone, smartwatch, and earbuds simultaneously.',
        slug: '3-in-1-wireless-charging'
    },
    {
        id: 6,
        name: 'USB-C Charge Cable',
        price: '199.00',
        image1: 'cable-black3.jpg',
        image2: 'cable-black2.jpg',
        description: 'A versatile and durable USB-C charge cable for fast and reliable charging on all your devices.',
        slug: 'usb-c-charge-cable'
    }
];


// Campaign Data 
export const CampaignData = [
    {
        id: 1,
        head1ing: 'UV charger',
        subTitle: 'Every piece is made to last beyond the season',
        image: 'accessories2_b1.jpg',
    },
    {
        id: 2,
        heading: 'Ness Headphone',
        subTitle: 'Every piece is made to last beyond the season',
        image: 'accessories2_b2.jpg',
    },
];


// Testimonial data //
export const TestimonialData = [
    {
        id: 1,
        name: 'Robert Smith',
        heading: 'OUR CUSTOMER’S REVIEWS',
        message: 'Consider if the headphones support any advanced audio technologies, such as noise cancellation or surround sound, depending on your preferences. Comfort is crucial for long listening sessions',
        productName: '3-in-1 Wireless Charging',
        image: 'accessories-tes1.png',
        rating: 4,
    },
    {
        id: 2,
        name: 'Josaphine Smith',
        heading: 'OUR CUSTOMER’S REVIEWS',
        message: 'Love love love! This product is so easy to use!   I absolutely love the this product. High-quality headphones deliver a balanced and clear sound. Look for headphones with good bass, clear mids, and crisp highs.',
        productName: '3-in-1 Wireless Charging',
        image: 'accessories-tes2.png',
        rating: 4,
    }
];


export const BlogList = [
    {
        id: 1,
        image: 'dyson.svg'
    },
    {
        id: 2,
        image: 'sony.svg'
    },
    {
        id: 3,
        image: 'samsung.svg'
    },
    {
        id: 4,
        image: 'oneplus.svg'
    },
    {
        id: 5,
        image: 'monster.svg'
    },
    {
        id: 6,
        image: 'sonos.svg'
    },
]