import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the initial state for home data
const initialState = {
    sectionTitle: [],
    seoSetting: null,
    sliderVisibility: false,
    sliders: [],
    banners: {
        bannerOne: null,
        bannerTwo: null,
        bannerThree: null,
        bannerFour: null,
    },
    homepageCategories: [],
    popularCategorySidebarBanner: '',
    popularCategoryVisibility: false,
    popularCategories: [],
    popularCategoryProducts: [],
    brandVisibility: false,
    brands: [],
    flashSale: null,
    flashSaleSidebarBanner: null,
    flashsaleProducts: [],
    bestProductVisibility:true,
    bestProducts:[],
    topRatedVisibility: false,
    topRatedProducts: [],
    newArrivalProducts: [],
    sellerVisibility: false,
    sellers: [],
    featuredProductVisibility: false,
    featuredCategorySidebarBanner: '',
    featuredCategories: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

// Create async thunk for fetching home data
export const fetchHomeData = createAsyncThunk('home/fetchHomeData', async () => {
    const response = await axios.get('https://admin.apoorvaproducts.com/api/');
    return response.data;
});

// Create the slice
const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomeData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchHomeData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const data = action.payload;

                // Update state with the fetched data
                state.sectionTitle = data.section_title || [];
                state.seoSetting = data.seoSetting || null;
                state.sliderVisibility = data.sliderVisibilty || false;
                state.sliders = data.sliders || [];
                state.banners.bannerOne = data.banner_one || null;
                state.banners.bannerTwo = data.banner_two || null;
                state.banners.bannerThree = data.banner_three || null;
                state.banners.bannerFour = data.banner_four || null;
                state.newArrivalProducts = data.newArrivalProducts || null;
                state.homepageCategories = data.homepage_categories || [];
                state.popularCategorySidebarBanner = data.popularCategorySidebarBanner || '';
                state.popularCategoryVisibility = data.popularCategoryVisibilty || false;
                state.popularCategories = data.popularCategories || [];
                state.popularCategoryProducts = data.popularCategoryProducts || [];
                state.brandVisibility = data.brandVisibility || false;
                state.brands = data.brands || [];
                state.flashSale = data.flashSale || null;
                state.flashSaleSidebarBanner = data.flashSaleSidebarBanner || null;
                state.flashsaleProducts = data.flashsale_products || [];
                state.topRatedVisibility = data.topRatedVisibility || false;
                state.topRatedProducts = data.topRatedProducts || [];
                state.sellerVisibility = data.sellerVisibility || false;
                state.bestProductVisibility = data.bestProductVisibility || false;
                state.bestProducts = data.bestProducts || [];
                state.sellers = data.sellers || [];
                state.featuredProductVisibility = data.featuredProductVisibility || false;
                state.featuredCategorySidebarBanner = data.featuredCategorySidebarBanner || '';
                state.featuredCategories = data.featuredCategories || [];
            })
            .addCase(fetchHomeData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Export the async thunk and the reducer
export default homeSlice.reducer;
