import React, { useEffect } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { useDispatch, useSelector } from 'react-redux';
import { fetchTermsData } from '../../redux/slice/termConditionSlice';
import Preloader from '../include/Preloader';

export default function TermAndCondition() {
    
    const dispatch = useDispatch();
    const {termsConditions, loading } = useSelector((state) => state.termsConditions);

    useEffect(() => {
        dispatch(fetchTermsData());
    }, []);


    if(loading) {
        return <Preloader/>
    }


    return (
    <React.Fragment>
        <InnerBanner title="Terms & Conditions"/>

        <section className="flat-spacing-25">
            <div className="container">
                <div className="tf-main-area-page"  dangerouslySetInnerHTML={{ __html: termsConditions?.terms_and_condition }}></div>
            </div>
        </section>
    </React.Fragment>
  )
}
