// src/redux/locationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../gobal/AxiosSetting';

// Async action to fetch data
export const fetchCountryData = createAsyncThunk(
    'country/fetchCountryData',
    async () => {
        const response = await axiosInstance.get(`/user/country`);
        return response.data; // Assumes response.data contains { countries: [...] }
    }
);

export const fetchStateByCountry = createAsyncThunk(
    'state/fetchStateByCountry',
    async (countryId) => {
        const response = await axiosInstance.get(`/user/state-by-country/${countryId}`);
        return response.data;
    }
);

export const fetchCityByState = createAsyncThunk(
    'city/fetchCityByState',
    async (stateId) => {
        const response = await axiosInstance.get(`/user/city-by-state/${stateId}`);
        return response.data;
    }
);

// Create a slice
const locationSlice = createSlice({
    name: 'location',
    initialState: {
        country: [],
        state: [], 
        city: [], // Adjusting to match expected data structure
        loading: false,
        error: null,
    },
    reducers: {
        // Reducer to update location (if needed)
        updateLocation: (state, action) => {
            state.country = action.payload.country || state.country;
            state.state = action.payload.state || state.state;
            state.city = action.payload.city || state.city;
        },
    },
    extraReducers: (builder) => {
        builder
            // Country data
            .addCase(fetchCountryData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCountryData.fulfilled, (state, action ) => {
                state.loading = false;
                state.country = action.payload;
            })
            .addCase(fetchCountryData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // State data
            .addCase(fetchStateByCountry.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStateByCountry.fulfilled, (state, action) => {
                state.loading = false;
                state.state = action.payload;
            })
            .addCase(fetchStateByCountry.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // City data
            .addCase(fetchCityByState.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCityByState.fulfilled, (state, action) => {
                state.loading = false;
                state.city = action.payload;
            })
            .addCase(fetchCityByState.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the action and reducer
export const { updateLocation } = locationSlice.actions;
export default locationSlice.reducer;
