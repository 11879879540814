import React, {useEffect, useMemo, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {currency} from '../../../Currency';
import {useDispatch, useSelector} from 'react-redux';
import {BASE_URL} from '../../../gobal/Global';
import Skeleton from 'react-loading-skeleton';
import {useAddToCart} from '../../../hooks/useAddToCart';
import {fetchAddToCart} from '../../../redux/slice/addToCartSlice';


const ShoppingCart = () => {

    const dispatch = useDispatch();
    const {addToCart, loading} = useSelector(state => state.addToCart);
    const {isLoggedIn} = useSelector(state => state.auth);
    const {image_content} = useSelector(state => state.setup);
    const {
        handleRemoveCart,
        incrementQty,
        decrementQty
    } = useAddToCart(isLoggedIn);
    const {
        flashSale, 
      } = useSelector(state => state.flashSale);
    
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchAddToCart());
        }
    }, [isLoggedIn]);

    // Calculate and set the subtotal whenever addToCart or quantities change
    
    const getProductPrice = (product) => {
        return flashSale?.offer && product?.is_flash_sale
        ? product.flash_price
        : product.offer_price;
    };

    const subTotal = useMemo(() => {
        return addToCart?.reduce((total, item) => total + getProductPrice(item.product) * item.qty, 0);
    }, [addToCart, flashSale]);


   


    const handleCheckout = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate("/checkout");
    };

    // console.log("addProduct__", addToCart);

    return (
        <div className="modal fullRight fade modal-shopping-cart " id="shoppingCartModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="header">
                        <div className="title fw-5">Shopping cart</div>
                        <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                    </div>
                    <div className="wrap">
                        <div className="tf-mini-cart-wrap">
                            <div className="tf-mini-cart-main">
                                <div className="tf-mini-cart-sroll">
                                    <div className="tf-mini-cart-items">
                                        {
                                            loading ? <Skeleton count={2} height={100}/> :
                                                (addToCart?.length === 0) ?
                                                    <img src={`${BASE_URL}/${image_content?.empty_cart}`}
                                                         className='mt-3'/> :
                                                    addToCart?.map((data, index) => {
                                                        return (
                                                            <div key={`cart_item_${index}`}
                                                                 className="tf-mini-cart-item">
                                                                <div className="tf-mini-cart-image">
                                                                    <NavLink
                                                                        to={`/product-detail/${data.product.slug}`}>
                                                                        <img
                                                                            src={`${BASE_URL}/${data.product.thumb_image}`}
                                                                            alt={data.product.slug}/>
                                                                    </NavLink>
                                                                </div>
                                                                <div className="tf-mini-cart-info">
                                                                    <NavLink className="title link" to={`/product-detail/${data.product.slug}`}>{data.product.short_name}</NavLink>
                                                                    {
                                                                        data?.product?.is_flash_sale ? 
                                                                        <div className="price fw-6">{currency.icon}{(data.product?.flash_price * data.qty).toFixed(2)}</div> :
                                                                        <div className="price fw-6">{currency.icon}{(data.product?.offer_price * data.qty).toFixed(2)}</div>
                                                                    }
                                                                    <div className="tf-mini-cart-btns">
                                                                        <div className="wg-quantity small">
                                                                            <span className="btn-quantity minus-btn"
                                                                                  onClick={() => decrementQty(data.id)}>-</span>
                                                                            <input type="text" name="number"
                                                                                   value={data.qty || 1}
                                                                                   readOnly/>
                                                                            <span className="btn-quantity plus-btn"
                                                                                  onClick={() => incrementQty(data.id)}>+</span>
                                                                        </div>
                                                                        <div className="tf-mini-cart-remove"
                                                                            onClick={() => handleRemoveCart(data.id)}>Remove
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="tf-mini-cart-bottom">
                                
                                <div className="tf-mini-cart-bottom-wrap">
                                    <div className="tf-cart-totals-discounts">
                                        <div className="tf-cart-total">Subtotal</div>
                                        <div className="tf-totals-total-value fw-6">{currency.icon}{subTotal} {currency.code}</div>
                                    </div>
                                    <div className="tf-mini-cart-line"></div>
                                    <div className="tf-mini-cart-view-checkout">
                                        <NavLink to="/view-cart"
                                                 onClick={(e)=>{
                                                     e.stopPropagation();
                                                     navigate("/view-cart")
                                                 }}
                                                 className="tf-btn btn-outline radius-3 link w-100 justify-content-center"  data-bs-dismiss="modal">View
                                            cart</NavLink>
                                        <button to="checkout"
                                            onClick={handleCheckout}
                                            className="tf-btn btn-fill animate-hover-btn radius-3 w-100 justify-content-center"  data-bs-dismiss="modal"><span>Check out</span></button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShoppingCart;
