import React, { useEffect, useState } from 'react';
import InnerBanner from '../elements/InnerBanner';
import { currency } from '../../Currency';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCheckout } from '../../redux/slice/checkoutSlice';
import { API_URL, BASE_URL } from '../../gobal/Global';
import { fetchAddresses } from '../../redux/slice/addresSlice';
import { fetchCountryData } from '../../redux/slice/Location';
import AddressForm from '../elements/AddressForm';
import { showConfirm, showError } from '../../gobal/sweetalertManage';
import axiosInstance from '../../gobal/AxiosSetting';
import {toast} from "react-toastify";
import { checkPostalCode } from '../../utils/CheckPostalCode';
import CouponList from '../elements/models/CouponList';
import { applyCoupon, fetchCouponData } from '../../redux/slice/couponSlice';
import { Spinner } from 'react-bootstrap';

export default function Checkout() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { flashSale, } = useSelector(state => state.flashSale);
    const{ 
        cartProducts, 
        addresses, 
        shippings, 
        couponOffer, 
        razorpayPaymentInfo, 
        subtotal, 
        coupon_price, 
        total_price, 
        loading 
    } = useSelector(state => state.checkout);

    useEffect(() => {
        dispatch(fetchCouponData());
    },[]);

    
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [selectedAddress, setSelectedAddress] = useState(null); // Store address to edit
    const [shippingFee, setShippingFee] = useState(0);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [isFormVisible, setFormVisible] = useState(true);
    const [editMode, setEditMode] = useState(false); // Track if editing
    const [shippingMethod, setShippingMethod] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [couponCode, setCouponCode] = useState(''); // Input field for coupon


    const getProductPrice = (product) => {
        return flashSale?.offer && product?.is_flash_sale
        ? product.flash_price
        : product.offer_price;
    };

    useEffect(() => {
        dispatch(fetchCheckout(couponCode));
    },[])

    const loadAddresses = () => {
        dispatch(fetchAddresses());
    };

    useEffect(() => {
        loadAddresses();
    }, []);

    const handlePaymentChange = (e) => {
        setSelectedPaymentMethod(e.target.id);
    };

    const handleApplyCoupon = async () => {
        if (couponCode) {
            try {
                // Dispatch the action to apply the coupon
                const response = await dispatch(applyCoupon(couponCode)).unwrap();
                // If successful, fetch updated checkout details
                await dispatch(fetchCheckout(couponCode));
    
                // Display success message
                toast.success(response.message || 'Coupon applied successfully!');
            } catch (error) {
                // Display error message from API
                const errorMessage = error || 'Failed to apply coupon. Please try again.';
                toast.error(errorMessage);
            }
        } else {
            toast.error('Please enter a coupon code.');
        }
    };

    const handleAddNew = () => {
        setEditMode(false);
        setSelectedAddress(null);
        setFormVisible(true);
    };


    useEffect(() => {
        dispatch(fetchCountryData());
    }, []);


    // Calculate shipping fee based on selected address and product quantity
    useEffect(() => {
        if (selectedAddress && cartProducts.length > 0) {
            const totalQuantity = cartProducts.reduce((sum, item) => sum + item.qty, 0);
             // Find matching shipping based on city_id and quantity conditions
            const matchingShipping = shippings.find(shipping =>
                shipping.city_id === selectedAddress.city_id &&
                totalQuantity >= parseInt(shipping.condition_from) &&
                (parseInt(shipping.condition_to) === -1 || totalQuantity <= parseInt(shipping.condition_to))
            );

            if (matchingShipping) {
                setShippingFee(matchingShipping.shipping_fee);
                setShippingMethod(matchingShipping.id);
            } else {
                 // Fallback to shipping with city_id 0 and matching quantity conditions
                const fallbackShipping = shippings.find(shipping =>
                    shipping.city_id === 0 &&
                    totalQuantity >= parseInt(shipping.condition_from) &&
                    (parseInt(shipping.condition_to) === -1 || totalQuantity <= parseInt(shipping.condition_to))
                );

                if (fallbackShipping) {
                    setShippingFee(fallbackShipping.shipping_fee);
                    setShippingMethod(fallbackShipping.id);
                } else {
                    setShippingFee(0); // Default to no shipping fee if no shipping method applies
                }
            }
        }
    }, [selectedAddress, cartProducts, shippings]);

    // Handle edit address selection
    const handleEdit = (address) => {
        setSelectedAddress(address);
        setFormVisible(true);
        setEditMode(true);
    };

   
    // Handle address selection
    const handleAddressSelect = (address) => {
        setSelectedAddress(address);
    };

    // Handle order placement
    const handleOrderSubmit = async (e) => {
        e.preventDefault();

        if (!selectedAddress) {
            showError("Please select an address.");
            return;
        }
        if (!selectedPaymentMethod) {
            showError("Please select any Payment method.");
            return;
        }

        if (!agreeTerms) {
            showError("Please agree to the terms and conditions.");
            return;
        }

        if (!shippingMethod) {
            showError("No valid shipping method available for the selected address.");
            return;
        }

        const isPostalCodeValid = await checkPostalCode(selectedAddress.postal_code);
        if (!isPostalCodeValid) {
            return; // Stop checkout process if postal code is not deliverable
        }

        setIsSubmitting(true); // Set loading state to true when submitting starts

        try {
            if (selectedPaymentMethod === 'cod') {
                // Send Cash on Delivery request
                const codData = {
                    shipping_address_id: selectedAddress.id,
                    billing_address_id: selectedAddress.id,  // Assuming the same address is used for billing
                    shipping_method_id: shippingMethod, // use appropriate shipping method ID
                    coupon: couponOffer?.code,
                };

                const response = await axiosInstance.post(`${API_URL}/user/checkout/cash-on-delivery`, codData);

                const { order_id } = response.data;

                showConfirm(response?.data?.message || "Order placed successfully!");

                // Redirect to the invoice page after a successful COD order
                navigate(`/invoice/${order_id}`);

            } else if(selectedPaymentMethod === 'razorpay'){

                // Step 1: Call the API to get Razorpay order details
                const url = `${API_URL}/user/checkout/razorpay-order?shipping_method_id=${parseInt(shippingMethod)}&coupon=${couponOffer.code}`;
                let response
                try{
                    response=  await axiosInstance.get(url);
                } catch(err){
                    err.response.data.message ? toast.error(err.response.data.message) :toast.error(err.message)
                }

                const order_id = response?.data?.order_id;
                const amount = response.data?.amount;

                if (order_id && amount && razorpayPaymentInfo) {
                    // Step 2: Initialize Razorpay
                    const options = {
                        key: razorpayPaymentInfo.key, // Razorpay API key from the backend
                        amount: amount * 100, // Amount in paise
                        currency: razorpayPaymentInfo.currency_code,
                        name: razorpayPaymentInfo.name,
                        description: "Payment for your order",
                        order_id: order_id, // Razorpay order ID
                        prefill: {
                            name: selectedAddress.name,
                            email: selectedAddress.email,
                            contact: selectedAddress.phone,
                        },
                        handler: async function (response) {
                            // Step 3: Handle successful payment
                            const paymentVerificationUrl = `${API_URL}/user/checkout/razorpay-verify`;
                            const verificationData = {
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_signature: response.razorpay_signature,
                                // billing_address_id: selectedAddress?.id,
                                shipping_method_id: shippingMethod,
                                billing_address_id: selectedAddress?.id,
                                shipping_address_id: selectedAddress?.id,
                                coupon: couponOffer.code,
                            };

                            try {
                                const verificationResponse = await axiosInstance.post(paymentVerificationUrl, verificationData);

                                console.log("Verification__", verificationResponse);
                                showConfirm(verificationResponse.data?.message || "Payment successful! Redirecting to order summary...");
                                navigate(`/account/my-account-orders`);
                            } catch (verificationError) {
                                showError("Payment verification failed. Please contact support.");
                            }
                        },
                        theme: {
                            color: "#3399cc",
                        },
                    };

                        const razorpay = new window.Razorpay(options);
                        razorpay.open();

                        razorpay.on("payment.failed", function (response) {
                            showError("Payment failed. Please try again.");
                        });
                    } else {
                        showError("Failed to create Razorpay order. Please try again.");
                    }

                    }
                } catch (error) {
                    showError(error.message || "Failed to place order. Please try again.");
                }finally {
                    setIsSubmitting(false); // Reset loading state after submission completes
                }
    };

   
    
    // console.log("CouponOffer__:",couponOffer);
    // console.log("Cart__",cartProducts);
    // console.log("Subtoal__",subtotal);
    // console.log("Coupon_price__",coupon_price);
    // console.log("Total Price__",total_price);

    return (
        <React.Fragment>
            <InnerBanner title="Checkout"/>
            <section className="flat-spacing-11">
                <div className="container">
                    <h5 className="fw-5 mb_20">Address details</h5>
                    {
                        loading ? <div className="position-relative"><div className="spinner-overlay"><Spinner/></div></div> :
                        <div className="tf-page-cart-wrap layout-2">
                        {
                            addresses?.length > 0 && !editMode ?
                                <div className="list-account-address">
                                    {
                                        addresses?.map((data, index) => (
                                            <div key={`address-${index}`}  className="d-flex align-items-start position-relative">
                                                <div className="list-product-btn type-address">
                                                    <button className="box-icon bg_white wishlist" onClick={() => handleEdit(data)}>
                                                        <span className="tooltip">Edit Address</span>
                                                        <span className="icon icon-edit"></span>
                                                    </button>
                                                </div>
                                                <input
                                                    type="radio"
                                                    name="selectedAddress"
                                                    id={`address-${data?.id}`}
                                                    className='mt-2'
                                                    onClick={() => handleAddressSelect(data)}
                                                    defaultChecked={ selectedAddress?.id === data.id ? true : false}
                                                />
                                                <label htmlFor={`address-${data?.id}`} className='account-address-item ms-3'>
                                                    {/* Radio button to select an address */}
                                                    {/* Address details */}
                                                    <h6 className="mb_20">{data.default_shipping === 1 ? 'Default' : ''}</h6>
                                                    <p className='text-capitalize fw-6 fs-6 mb-2'>{data.name}</p>
                                                    <p className='text-capitalize fw-6'>{data.type}</p>
                                                    {
                                                        data.gst_number ? <p className='text-capitalize fw-6 text-danger'>GST Number - {data.gst_number}</p> : ""
                                                    }
                                                    <p className='text-capitalize'>{data.address}, {data.city?.name}, </p>
                                                    <p>{data.postal_code} - {data.country_state?.name},</p>
                                                    <p className='text-capitalize'>{data.country?.name}</p>
                                                    <p>{data.email}</p>
                                                    <p className="mb_10">{data.phone}</p>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div> :
                                
                                <div className="tf-page-cart-item">
                                    <AddressForm
                                        isFormVisible={isFormVisible}
                                        setFormVisible={setFormVisible}
                                        editMode={editMode}
                                        selectedAddress={selectedAddress}
                                        setEditMode={setEditMode}
                                        refreshAddresses={loadAddresses}
                                    />
                                </div>
                        }
                            <div className="tf-page-cart-footer">
                                <div className="tf-cart-footer-inner">
                                    <h5 className="fw-5 mb_20">Your order</h5>
                                    <form className="tf-page-cart-checkout widget-wrap-checkout"  onSubmit={handleOrderSubmit}>
                                        <ul className="wrap-checkout-product">
                                        {
                                            cartProducts?.map((data, index) => (
                                                <li key={`cartProduct-${index}`} className="checkout-product-item">
                                                    <figure className="img-product">
                                                        <img src={`${BASE_URL}/${data.product.thumb_image}`} alt="product"/>
                                                        <span className="quantity">{data.qty}</span>
                                                    </figure>
                                                    <div className="content">
                                                        <div className="info">
                                                            <p className="name">{data.product.short_name}</p>
                                                        </div>
                                                        <span className="price">{currency.icon}{ (getProductPrice(data.product) * data.qty).toFixed(2)}</span>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        </ul>
                                        <div className="coupon-box">
                                            <input
                                                type="text"
                                                name="coupon"
                                                placeholder="Discount code"
                                                value={couponCode}
                                                onChange={(e) => setCouponCode(e.target.value)}
                                                disabled={loading}
                                            />
                                            <button
                                                type="button"
                                                className="tf-btn btn-sm radius-3 btn-fill btn-icon animate-hover-btn"
                                                onClick={handleApplyCoupon}
                                                disabled={loading}
                                            > { loading ? 'Applying...' : 'Apply' }
                                            </button>
                                        </div>
                                        <div className="tf-cart-tax m-0">view all <NavLink data-bs-target="#coupon_code" data-bs-toggle="modal" >coupons</NavLink></div>
                                    
                                        <div className="d-flex justify-content-between line pb_20">
                                            <p className="fw-5">Sub Total</p>
                                            <p className="total fw-5">{currency.icon}{subtotal.toFixed(2)}</p>
                                        </div>
                                        {couponOffer?.discount && (
                                            <div className="d-flex justify-content-between line pb_20">
                                                <p className="fw-5 text-success">Discount ({couponOffer.code})</p>
                                                <p className="total text-success fw-5">- {currency.icon}{coupon_price.toFixed(2)}</p>
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-between line pb_20">
                                            <NavLink to="" className="text-danger fw-5">Shipping</NavLink>
                                            <p className="total text-danger fw-5">{shippingFee.toFixed(2)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between line pb_20">
                                            <h6 className="fw-5">Total</h6>
                                            <h6 className="total fw-5">{currency.icon}{ (total_price + shippingFee).toFixed(2)}</h6>
                                        </div>
                                        <div className="wd-check-payment">
                                            <div className="fieldset-radio mb_20">
                                                <input
                                                    type="radio"
                                                    name="payment"
                                                    id="razorpay"
                                                    className="tf-check"
                                                    checked={selectedPaymentMethod === 'razorpay'}
                                                    onChange={handlePaymentChange}
                                                />
                                                <label htmlFor="razorpay">Online bank payment</label>
                                            </div>
                                            <div className='bank-option'>
                                                {/* <PaymentOptions/> */}
                                            </div>
                                            <div className="fieldset-radio mb_20">
                                                <input
                                                    type="radio"
                                                    name="payment"
                                                    id="cod"
                                                    className="tf-check"
                                                    checked={selectedPaymentMethod === 'cod'}
                                                    onChange={handlePaymentChange}
                                                />
                                                <label htmlFor="cod">Cash on delivery</label>
                                            </div>
                                            <hr />
                                            <div className="box-checkbox fieldset-radio mb_20">
                                                <input  type="checkbox"
                                                    id="check-agree"
                                                    className="tf-check"
                                                    checked={agreeTerms}
                                                    onChange={() => setAgreeTerms(!agreeTerms)}/>
                                                <label htmlFor="check-agree" className="text_black-2">I have read and agree to the website <NavLink to="/terms-conditions" className="text-decoration-underline">terms and conditions</NavLink>.</label>
                                            </div>
                                        </div>
                                        <button
                                            type='submit'
                                            className="tf-btn radius-3 btn-fill btn-icon animate-hover-btn justify-content-center"
                                            disabled={isSubmitting}
                                            >
                                            { isSubmitting ? "Placing Order..." : "Place Order" }
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
            <CouponList setCouponCode={setCouponCode} />
        </React.Fragment>
  )
}
