import React, { useEffect } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { useDispatch, useSelector } from 'react-redux'
import { Accordion, Card, Container } from 'react-bootstrap';
import { fetchFaqData } from '../../redux/slice/faqSlice';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';

export default function Faq() {

    const dispatch = useDispatch();
    const { faq, loading } = useSelector(state => state.faq);

    useEffect(() => {
        dispatch(fetchFaqData());
    },[]);

    console.log(faq);

    if(loading) {
        return <Skeleton/>
    }


    return (
        <React.Fragment>
            <InnerBanner title="FAQ"/>

            <section className='flat-spacing-11'>
                <Container>
                    <div id='accordianExample' className="accordion tf-accordion-wrap d-flex justify-content-between">
                        <div className="content">
                            
                            <div className="flat-accordion style-default has-btns-arrow mb_60">
                                {faq?.faqs?.map((data, index) => (
                                    <div key={`faq-${index}`} className="flat-toggle accordion-item">
                                        <div className="toggle-title accordion-header" 
                                            id={`heading-${index}`}  data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${index}`}
                                            aria-expanded="false"
                                            aria-controls={`collapse-${index}`}
                                        >
                                            {data.question}
                                        </div>
                                        <div
                                            id={`collapse-${index}`}
                                            className=" accordion-collapse collapse"
                                            aria-labelledby={`heading-${index}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p dangerouslySetInnerHTML={{ __html: data.answer }}></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Accordion defaultActiveKey={0}>
                            {
                                faq?.faq?.map((data, index) => (
                                    <Card key={`faq-${index}`} className='mb-3'>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey={index.toString()}
                                            className="toggle-title"
                                        >
                                            {data.question}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={index.toString()}>
                                            <Card.Body className="toggle-content">
                                                <p dangerouslySetInnerHTML={{ __html: data.answer }}></p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))
                            }
                            </Accordion>
                        </div>
                        <div className="box tf-other-content radius-10 bg_grey-8">
                            <h5 className="mb_20">Have a question</h5>
                            <p className="text_black-2 mb_40">If you have an issue or question that requires immediate assistance, you can click the button below to chat live with a Customer Service representative.<br/><br/>Please allow 06 - 12 business days from the time your package arrives back to us for a refund to be issued.</p>
                            <div className="d-flex gap-20 align-items-center">
                                <NavLink to="/contact" className="tf-btn radius-3 btn-fill animate-hover-btn justify-content-center">Contact us</NavLink>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}
