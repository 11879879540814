import React, { useEffect } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BASE_URL, BlogList } from '../../gobal/Global';
import { Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogData, fetchSingleBlog } from '../../redux/slice/blogSlice';
import DateFormat from '../../utils/DateFormat';
import Preloader from '../include/Preloader';

export default function BlogDetail() {

    const { slug } = useParams();
    const dispatch = useDispatch();
    const { blogs, singleBlog, loading, error } = useSelector((state) => state.blog);

    useEffect(() => {
        if (slug) {
            dispatch(fetchSingleBlog(slug));
            dispatch(fetchBlogData());
        }
    }, [dispatch, slug]);

    // Get the index of the current blog in BlogList array
    const currentIndex = blogs.findIndex((blogItem) => blogItem.slug === slug);

    // Get previous and next blogs based on the index
    const prevBlog = currentIndex > 0 ? blogs[currentIndex - 1] : null;
    const nextBlog = currentIndex < blogs.length - 1 ? blogs[currentIndex + 1] : null;

    // Render loading state if the blog data is still being fetched
    if (loading) {
        return <Preloader/>;
    }

    // Handle case when singleBlog or singleBlog.blog is undefined
    if (!singleBlog || !singleBlog.blog) {
        return <div>Blog not found</div>;
    }

    return (
        <React.Fragment>
            <InnerBanner title={singleBlog.blog.title || "Blog Detail"} />

            {/* blog detail */}
            <section className="blog-detail">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="blog-detail-main">
                                <div className="blog-detail-main-heading">
                                    <ul className="tags-lists justify-content-center">
                                        <li>
                                            <NavLink to="#" className="tags-item">{singleBlog.blog_category_id || "Category"}</NavLink>
                                        </li>
                                    </ul>
                                    <div className="meta">by <span>admin</span> on <span>{DateFormat(singleBlog.blog.created_at)}</span></div>
                                    <div className="image">
                                        <img className="lazyload" data-src={`${BASE_URL}/${singleBlog.blog.image}`} src={`${BASE_URL}/${singleBlog.blog.image}`} alt=""/>
                                    </div>
                                </div>
                                <blockquote>
                                    <div className="icon">
                                        <img src="assets/images/item/quote.svg" alt=""/>
                                    </div>
                                    <div className="text" dangerouslySetInnerHTML={{ __html: singleBlog.blog.description }} ></div>
                                </blockquote>
                                
                                <div className="bot d-flex justify-content-between flex-wrap align-items-center">
                                    <ul className="tags-lists">
                                        <li>
                                            <NavLink to="#" className="tags-item"><span>Computer</span></NavLink>
                                        </li>
                                    </ul>
                                </div>

                                {/* Dynamic Previous and Next navigation */}
                                <div className="tf-article-navigation">
                                    <div className="item position-relative d-flex w-100 prev">
                                        {prevBlog ? (
                                            <NavLink to={`/blog-detail/${prevBlog.slug}`} className="icon">
                                                <i className="icon-arrow-left"></i>
                                            </NavLink>
                                        ) : (
                                            <span className="icon icon-disabled">
                                                <i className="icon-arrow-left"></i>
                                            </span>
                                        )}
                                        {prevBlog && (
                                            <div className="inner">
                                                <NavLink to={`/blog-detail/${prevBlog.slug}`}>PREVIOUS</NavLink>
                                                <h6>
                                                    <NavLink to={`/blog-detail/${prevBlog.slug}`}>{prevBlog.title}</NavLink>
                                                </h6>
                                            </div>
                                        )}
                                    </div>
                                    <div className="item position-relative d-flex w-100 justify-content-end next">
                                        {nextBlog ? (
                                            <div className="inner text-end">
                                                <NavLink to={`/blog-detail/${nextBlog.slug}`}>NEXT</NavLink>
                                                <h6>
                                                    <NavLink to={`/blog-detail/${nextBlog.slug}`}>{nextBlog.title}</NavLink>
                                                </h6>
                                            </div>
                                        ) : (
                                            <span className="inner text-end disabled">
                                                <span>NEXT</span>
                                            </span>
                                        )}
                                        {nextBlog ? (
                                            <NavLink to={`/blog-detail/${nextBlog.slug}`} className="icon">
                                                <i className="icon-arrow-right"></i>
                                            </NavLink>
                                        ) : (
                                            <span className="icon icon-disabled">
                                                <i className="icon-arrow-right"></i>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Related article */}
            <section className="mb_30">
                <Container>
                    <div className="flat-title">
                        <h5 className="">Related Articles</h5>
                    </div>
                    <div className="hover-sw-nav view-default hover-sw-5">
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={30}
                            slidesPerView={3}
                            navigation={{
                                nextEl: '.nav-next-slider',
                                prevEl: '.nav-prev-slider',
                            }}
                            pagination={{ clickable: false }}
                            breakpoints={{
                                768: { slidesPerView: 3 },
                                576: { slidesPerView: 2 }
                            }}
                        >
                        {blogs.map((data, index) => (
                            <SwiperSlide key={`blog-list${index}`}>
                                <div className="blog-article-item">
                                    <div className="article-thumb radius-10">
                                        <NavLink to={`/blog-detail/${data.slug}`}>
                                            <img className="lazyload" data-src={`${BASE_URL}/${data.image}`} src={`${BASE_URL}/${data.image}`} alt="img-blog"/>
                                        </NavLink>
                                        <div className="article-label">
                                            <NavLink to="shop-collection-list" className="tf-btn style-2 btn-fill radius-3 animate-hover-btn">{data.label}</NavLink>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <NavLink to={`/blog-detail/${data.slug}`} className="">{data.title}</NavLink>
                                        </div>
                                        <div className="article-btn">
                                            <NavLink to={`/blog-detail/${data.slug}`} className="tf-btn btn-line fw-6">Read more<i className="icon icon-arrow1-top-left"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="nav-sw nav-next-slider nav-next-recent box-icon w_46 round"><span className="icon icon-arrow-left"></span></div>
                        <div className="nav-sw nav-prev-slider nav-prev-recent box-icon w_46 round"><span className="icon icon-arrow-right"></span></div>
                        </Swiper>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}
