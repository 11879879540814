import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';


// Async thunk for fetching flash sale data
export const fetchFlashSale = createAsyncThunk(
    'flashSale/fetchFlashSale',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${API_URL}/flash-sale`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);
  
// Initial state
const initialState = {
    flashSale: null,
    products: [],
    seoSetting: null,
    status: 'idle', // idle | loading | succeeded | failed
    error: null,
};
    
// Slice definition
const flashSaleSlice = createSlice({
     name: 'flashSale',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
    builder
        .addCase(fetchFlashSale.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        })
        .addCase(fetchFlashSale.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.flashSale = action.payload.flashSale;
        state.products = action.payload.products.data;
        state.seoSetting = action.payload.seoSetting;
        })
        .addCase(fetchFlashSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Something went wrong';
        });
    },
});
  
// Export the async thunk and reducer
export default flashSaleSlice.reducer;
  

