import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { BASE_URL } from '../../../gobal/Global';
import { currency } from '../../../Currency';
import { NavLink } from 'react-router-dom';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { useDispatch, useSelector } from "react-redux";
import { fetchProductData } from "../../../redux/slice/productSlice";
import { useAddToCart } from '../../../hooks/useAddToCart';
import { useWishlist } from '../../../hooks/useWishlist';
import {fetchWishlist} from "../../../redux/slice/wishlistSlice";

export default function HotDeal({ newArrivalProducts, title }) {
    const swiperRef = useRef(null);
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { flashSale } = useSelector(state => state.flashSale);

    const { handleCartListToggle, isProductInCartlist } = useAddToCart();
    const { handleWishlistToggle, isProductInWishlist } = useWishlist();

    useEffect(() => {
        dispatch(fetchProductData());
    }, [dispatch]);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchWishlist());
        }
    }, [isLoggedIn, dispatch]);

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    if(!newArrivalProducts){
        return <div></div>
    }

    // console.log("New Arrival Pro__",newArrivalProducts);

    return (
        <section className="flat-spacing-2">
            <div className="container">
                <div className="flat-title flex-row justify-content-between gap-10 flex-wrap px-0">
                    <span className="title wow fadeInUp" data-wow-delay="0s">{title}</span>
                </div>
                <div className="wrap-carousel wrap-sw-3">
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={10}
                        slidesPerView={4}
                        breakpoints={{
                            0: { slidesPerView: 1 },
                            500: { slidesPerView: 3 },
                            1000: { slidesPerView: 4 }
                        }}
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        navigation={false}
                        modules={[Navigation, Autoplay]}
                    >
                        {
                            newArrivalProducts.map((data, index) => (
                                <SwiperSlide key={`product-${index}`}>
                                    <div className="card-product style-8 border-0 bg_grey-11">
                                        <div className="card-product-wrapper">
                                            <NavLink to={`/product-detail/${data.slug}`} className="product-img">
                                                <img className="lazyload img-product" data-src={`${BASE_URL + "/" + data.thumb_image}`} src={`${BASE_URL + data.thumb_image}`} alt="image-product" />
                                                <img className="lazyload img-hover" data-src={`${BASE_URL + "/" + data.thumb_image}`} src={`${BASE_URL + data.thumb_image}`} alt="image-product" />
                                            </NavLink>
                                            <div className="list-product-btn absolute-2">
                                                <button
                                                    className="box-icon bg_white wishlist btn-icon-action"
                                                    onClick={() => handleCartListToggle(data.id)}
                                                >
                                                    <span className={isProductInCartlist(data.id) ? "icon icon-delete" : "icon icon-bag"}></span>
                                                    <span className="tooltip">{isProductInCartlist(data.id) ? "Remove from Cart" : "Add to Cart"}</span>
                                                </button>
                                                <button
                                                    className="box-icon bg_white wishlist btn-icon-action"
                                                    onClick={() => handleWishlistToggle(data.id)}
                                                >
                                                    <span className={isProductInWishlist(data.id) ? "icon icon-delete" : "icon icon-heart"}></span>
                                                    <span className="tooltip">{isProductInWishlist(data.id) ? "Remove from Wishlist" : "Add to Wishlist"}</span>
                                                </button>
                                            </div>
                                            {
                                                data?.is_flash_sale ? 
                                                <div class="on-sale-wrap text-end">
                                                    <div class="on-sale-item pre-order bg-danger">Flash Sale {flashSale?.offer}%</div>
                                                </div> : null
                                            }
                                        </div>
                                        <div className="card-product-info text-center">
                                            <NavLink to={`/product-detail/${data.slug}`} className="title link">{data.name}</NavLink>
                                            <span className="price">
                                                {
                                                   data?.is_flash_sale ?  `${currency.icon} ${data?.flash_price.toFixed(2)}` : `${currency.icon} ${data.offer_price}`
                                                }
                                            </span>
                                            {/* <span className="price">{`${currency.icon} ${data.price}`}</span> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    <div className="nav-sw disable-line nav-next-slider nav-next-product box-icon w_46 round" onClick={handlePrev}><span className="icon icon-arrow-left"></span></div>
                    <div className="nav-sw disable-line nav-prev-slider nav-prev-product box-icon w_46 round" onClick={handleNext}><span className="icon icon-arrow-right"></span></div>
                </div>
            </div>
        </section>
    );
}
