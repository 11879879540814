import React, { useRef } from 'react';
import { BASE_URL } from '../../../gobal/Global';
import { NavLink } from 'react-router-dom';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';



export default function Categories({homepageCategories}) {
    const swiperRef = useRef(null);

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };


  return (
        <section className='flat-spacing-2'>
            <div className="container">
                <div className="flat-title">
                    <span className="title wow fadeInUp" data-wow-delay="0s">Shop by category</span>
                </div>
                <div className="flat-categories-bg wrap-carousel">
                    <div className="swiper tf-sw-recent wow fadeInUp" data-preview="6" data-tablet="3" data-mobile="2" data-space-lg="70" data-space-md="30" data-space="15" data-pagination="2" data-pagination-md="3" data-pagination-lg="3">
                        <Swiper 
                            ref={swiperRef} // Use ref to control the swiper
                            spaceBetween={30}
                            slidesPerView={6}
                            breakpoints={{
                                 0: { slidesPerView: 3 }, // For mobile
                                 500: { slidesPerView: 4 }, // For tablet
                                 1000: { slidesPerView: 6 } // For desktop
                            }}
                            autoplay={{ delay: 3000, disableOnInteraction: false }}
                            navigation={false} // We will add custom navigation
                            modules={[Navigation, Autoplay]}
                        >
                        {
                            homepageCategories.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <div className="collection-item-circle hover-img">
                                        <NavLink to={`/product/${data.id}`} className="collection-image img-style">
                                            <img className=" lazyloaded img-fluid" style={{padding:"10px 30%"}} data-src={`${BASE_URL+"/"+data.image}`} alt="collection-img" src={`${BASE_URL+"/"+data.image}`}/>
                                        </NavLink>
                                        <div className="collection-content text-center">
                                            <NavLink  to={`/product/${data.id}`} className="link title fw-5 text-line-clamp-1">{data.name}</NavLink>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                        </Swiper>
                        <div className="sw-dots style-2 sw-pagination-recent justify-content-center"></div>
                    </div>
                    <div className="nav-sw nav-next-slider nav-next-recent box-icon w_46 round" onClick={handlePrev}><span className="icon icon-arrow-left"></span></div>
                    <div className="nav-sw nav-prev-slider nav-prev-recent box-icon w_46 round" onClick={handleNext}><span className="icon icon-arrow-right"></span></div>
                </div>
            </div>
        </section>
  )
}
