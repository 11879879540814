import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPolicyData } from '../../redux/slice/policySlice';
import InnerBanner from '../elements/InnerBanner';
import Preloader from '../include/Preloader';

export default function ShippingPolicy() {

    const dispatch = useDispatch();
    const {privacyPolicy, loading, error} = useSelector((state) => state.privacyPolicy);

    useEffect(() => {
        dispatch(fetchPolicyData());
    }, []);

    console.log(privacyPolicy);

    if(loading) {
        return <Preloader/>
    }

    return (
        <React.Fragment>
            <InnerBanner title="Shipping Policy"/>

            <section className="flat-spacing-25">
                <div className="container">
                    <div className="tf-main-area-page"  dangerouslySetInnerHTML={{ __html: privacyPolicy?.privacy_policy }}></div>
                </div>
            </section>
        </React.Fragment>
    )
}
