import React, { useState } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showConfirm, showError } from '../../gobal/sweetalertManage';
import { resetPassword } from '../../redux/slice/resetPasswordSlice';

export default function VerifyResetPassword() {

    const { register, handleSubmit, formState: { errors, isSubmitting }} = useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error } = useSelector((state) => state.auth);

    const[submitting, setSubmitting] = useState(false);

    // Handle form submission
    const onSubmit = async (data) => {
        try {
            setSubmitting(true);
            // Assuming your verification token is in the form data
            const response = await dispatch(resetPassword({token: data.verifyCode})).unwrap();
            console.log(response);
            if(response){
                showConfirm("User verified");
                navigate('/reset-password');
            }
        } catch (errors) {
            console.log(errors);
            showError(errors?.notification || 'Verification failed!');
        }finally{
            setSubmitting(false);
        }
    };


    return (
    <React.Fragment>
        <InnerBanner title="Mail OTP"/>

        <section className="flat-spacing-10">
                <div className="container">
                    <div className="tf-grid-layout lg-col-2 tf-login-wrap">
                        <div className="tf-login-form">
                            <div >
                                <h5 className="mb_24">Verify OTP</h5>
                                <p className="mb_30">Please enter verification code</p>
                                <div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="tf-field style-1 mb_15">
                                        <input 
                                            className="tf-field-input tf-input" 
                                            placeholder="" 
                                            type="text" 
                                            id="property3" 
                                            {...register("verifyCode", { required: true })} 
                                        />
                                            <label className="tf-field-label fw-4 text_black-2" for="property3">Code *</label>
                                            {errors.verifyCode && <p className="text-danger">{errors.verifyCode.message}</p>}
                                        </div>
                                        <div className="mb_20">
                                            <NavLink to="/register" className="tf-btn btn-line">Cancel</NavLink>
                                        </div>
                                        <div className="">
                                            <button 
                                                type="submit" 
                                                className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center" 
                                                disabled={isSubmitting || loading}
                                            >
                                                { submitting ? 'Verifying...' : 'Verify'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="tf-login-content">
                            <h5 className="mb_36">I'm new here</h5>
                            <p className="mb_20">Sign up for early Sale access plus tailored new arrivals, trends and promotions. To opt out, click unsubscribe in our emails.</p>
                            <NavLink to="/login" className="tf-btn btn-line">Login<i className="icon icon-arrow1-top-left"></i></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        
    </React.Fragment>
  )
}
