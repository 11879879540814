import React, { useEffect, useState } from 'react'

export default function GoTop() {

    const [scrollProgress, setScrollProgress] = useState(0);
    const [isActive, setIsActive] = useState(false);
    
    useEffect(() => {
        const progressWrap = document.querySelector(".progress-wrap path");
        const pathLength = progressWrap.getTotalLength();

        // Initialize the progress path
        progressWrap.style.strokeDasharray = `${pathLength} ${pathLength}`;
        progressWrap.style.strokeDashoffset = pathLength;

        const updateProgress = () => {
        const scroll = window.scrollY;
        const height = document.documentElement.scrollHeight - window.innerHeight;
        const progress = pathLength - (scroll * pathLength) / height;
        progressWrap.style.strokeDashoffset = progress;
        setScrollProgress(progress);
        };

        const handleScroll = () => {
        if (window.scrollY > 200) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
        };

        window.addEventListener('scroll', updateProgress);
        window.addEventListener('scroll', handleScroll);

        // Cleanup function
        return () => {
        window.removeEventListener('scroll', updateProgress);
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    };
  return (
    <div
      className={`progress-wrap ${isActive ? 'active-progress' : ''}`}
      onClick={scrollToTop}
      style={{ cursor: 'pointer' }}
    >
      <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style={{
            transition: 'stroke-dashoffset 10ms linear',
            strokeDasharray: '307.919 307.919',
            strokeDashoffset: scrollProgress,
          }}
        ></path>
      </svg>
    </div>
  )
}
