// redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance, { updateToken } from '../../gobal/AxiosSetting';

export const verifyUser = createAsyncThunk(
  'auth/verifyUser',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/user-verification/${token}`);
      return response.data; // Assuming the API returns user data on success
    } catch (error) {
      const errorMessage =
        error.response?.data?.notification || 'Verification failed. Please try again.';
      return rejectWithValue({ notification: errorMessage });
    }
  }
);


export const loginUser = createAsyncThunk('auth/login', async (credentials) => {
  try{
    const response = await axiosInstance.post(`/store-login`, credentials);
    // console.log("data",response.data);
    return response.data; // Adjust according to your API response
  }catch(error){
    // console.log("Err",error.response.data);
    return error.response.data
  }
});



export const fetchUserData = createAsyncThunk('auth/fetchUserData', async () => {
  const response = await axiosInstance.get(`/user`);
  // console.log("User__:", response.data);
  return response.data; // Adjust according to your API response
});


export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { rejectWithValue }) => {
  try {
      const response = await axiosInstance.post(`/user/logout`);
      return response.data;
  } catch (error) {
      return rejectWithValue(error.response.data);
  }
});

// Initial state based on localStorage data
const getInitialState = () => {
  const token = localStorage.getItem('token');
  let user = null;
  
  if (token) {
    updateToken(token);
    user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
    }
  }
  // console.log(token,  Boolean(user), "lllllllllllllllllllllllllllllll")
  return {
    user: user || null,
    token: token || null,
    isVendor: true,
    isLoggedIn: Boolean(user),
    loading: false,
    error: null,
  };
};

// Auth slice
const AuthSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    clearAuth: (state) => {
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      updateToken('');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if(!action.payload.errors){
          const { access_token, user, is_vendor } = action.payload;
        console.log(action,"asdasuidyuyyyyyyyyyyyyyyyyyyyy")
        // state.loading = false;
        state.isLoggedIn = true;
        state.user = user;
        state.token = access_token;
        state.isVendor = true;
        updateToken(access_token);
        localStorage.setItem('token', access_token);
        localStorage.setItem('user', JSON.stringify(user));
    

        }
        state.loading = false;

      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        console.log("Auth__", action);

        state.error = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.user = null;
        state.token = null;
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      })
      .addCase(verifyUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload;
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.notification;
      });
  },
});

export const { login, clearAuth } = AuthSlice.actions;
export default AuthSlice.reducer;
