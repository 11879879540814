import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAddToCart } from '../../hooks/useAddToCart';
import { useWishlist } from '../../hooks/useWishlist';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../gobal/Global';

export default function ProductCard({inCart, inWishlist, data}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.auth);
    const { setting } = useSelector(state => state.setup);
    const { handleCartListToggle } = useAddToCart(); // No longer needs productId as a parameter
    const { handleWishlistToggle } = useWishlist();
    const { flashSale } = useSelector(state => state.flashSale);

    const handleAddToCart = async (productId) => {
        if (isLoggedIn) {
            await handleCartListToggle(productId);
            // showConfirm(isProductInCartlist(productId) ? "Removed from cart" : "Added to cart");
        } else {
            navigate('/login'); // Redirect to login if not logged in
        }
    };


    return (
        <div className="card-product style-8 mb_30">
            <div className="card-product-wrapper">
                <NavLink to={`/product-detail/${data.slug}`} className="product-img">
                    <img className="lazyload img-product" data-src={`${BASE_URL}/${data.thumb_image}`} src={`${BASE_URL}/${data.thumb_image}`} alt="image-product"/>
                    <img className="lazyload img-hover" data-src={`${BASE_URL}/${data.thumb_image}`} src={`${BASE_URL}/${data.thumb_image}`} alt="image-product"/>
                </NavLink>
                <div className="list-product-btn absolute-2">
                    <button onClick={() => handleAddToCart(data.id)} className="box-icon bg_white quick-add tf-btn-loading">
                        <span className={inCart ? "icon icon-delete" : "icon icon-bag"}></span>
                        <span className="tooltip">{inCart ? "Remove from cart" : "Add to cart"}</span>
                    </button>

                    <button onClick={() => handleWishlistToggle(data.id)}  className="box-icon bg_white wishlist btn-icon-action">
                        <span className={inWishlist ? "icon icon-delete" : "icon icon-heart"}></span>
                        <span className="tooltip">{inWishlist ? "Remove from Wishlist" : "Add to Wishlist"}</span>
                    </button>
                </div>
                {
                    data?.is_flash_sale ? 
                    <div class="on-sale-wrap text-end">
                        <div class="on-sale-item pre-order bg-danger">Flash Sale {flashSale?.offer}%</div>
                    </div> : null
                }
                <div className="card-product-info">
                    <NavLink to={`/product-detail/${data?.slug}`} className="title link">{data.name}</NavLink>
                    <span className='price'>MRP: 
                    {
                        data?.is_flash_sale ? `${setting.currency_icon}${data?.offer_price}` : `${setting.currency_icon}${data?.price}`
                    }
                    </span>
                    
                    <span className="price">Offer price: 
                    {
                        data?.is_flash_sale ?  `${setting.currency_icon} ${data?.flash_price.toFixed(2)}` : `${setting.currency_icon} ${data.offer_price}`
                    }
                    </span>
                </div>
            </div>
        </div>
        
    )
}
