import React from 'react';
import { Card } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../css_files/dashboard.css';



export default function DashboardCard({ title, count, type, variant }) {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <NavLink to={`/account/my-account-status-order-list/${type}`}>
                <Card className="dashboard-card">
                    <Card.Body>
                        <Card.Title>
                        <NavLink to={`/account/my-account-status-order-list/${type}`} className={`order-status-title text-${variant}`}>
                            {title}
                        </NavLink>
                        </Card.Title>
                        <div className="top-reviews">
                            <div className="tab-reviews-heading">
                                <div className="top">
                                    <div className="text-center">
                                        <h1 className={`number fw-6 text-${variant}`}>{count}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </NavLink>
        </React.Fragment>
    )
}
