import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { clearAuth } from '../../../redux/slice/authSlice';
import '../../css_files/profile.css';
import { fetchSetupData } from '../../../redux/slice/setupSlice';
import { BASE_URL } from '../../../gobal/Global';

export default function Profile() {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { defaultProfile } = useSelector(state => state.setup);
    const navigate = useNavigate();
    const modalRef = useRef();

    useEffect(() => {
        dispatch(fetchSetupData());
    }, [dispatch]);

    const handleLogout = () => {
        dispatch(clearAuth());
        navigate('/login');
    };


    const closeModal = () => {
        const modalEl = modalRef.current;
        const modalInstance = new window.bootstrap.Modal(modalEl); // Use `window.bootstrap` to access the global `bootstrap` object
        modalInstance.hide();
    };

    return (
        <div className="modal fullRight fade modal-shopping-cart" id="model" tabIndex="-1" aria-labelledby="shoppingCartModalLabel" aria-hidden="true" ref={modalRef}>
            <div className="modal-dialog">
                <div className="modal-content   shadow-lg">
                    <div className="modal-header border-0">
                        <h5 className="modal-title" id="shoppingCartModalLabel">User Profile</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  ></button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="user-credantial d-flex align-items-center mb-3">
                            <img
                                src={defaultProfile ? `${BASE_URL}/${defaultProfile.image}` : "assets/images/default/default_image.png"}
                                alt="default-image"
                                className="img-fluid rounded-circle"
                                width="100"
                                height="100"
                            />
                            <div className="user-detail ms-3">
                                <h4>{user ? user.name : "User"}</h4>
                                <p>{user ? user.email : ""}</p>
                                <p>{user ? user.phone : ""}</p>
                            </div>
                        </div>

                        <div className="quick-links">
                            <div className="tf-search-content-title fw-bold mb-3">Quick links</div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item border-0 p-2">
                                    <NavLink
                                        className="text-decoration-none text-dark d-block"
                                        to="/account/my-account-dashboard"
                                        onClick={()=>{
                                            navigate("/account/my-account-dashboard")
                                        }}
                                        data-bs-dismiss="modal" aria-label="Close"
                                    >
                                        <i  className="bi bi-person-circle me-2"></i> Account
                                    </NavLink>
                                </li>
                                <li className="list-group-item border-0 p-2">
                                    <NavLink
                                        className="text-decoration-none text-dark d-block"
                                        onClick={()=>{
                                            navigate("/account/my-account-orders")
                                        }}
                                        data-bs-dismiss="modal" aria-label="Close"                                    >
                                        <i className="bi bi-box me-2"></i> Orders
                                    </NavLink>
                                </li>
                                <li className="list-group-item border-0 p-2">
                                    <NavLink
                                        className="text-decoration-none text-dark d-block"
                                        to="/account/my-account-address"
                                        onClick={()=>{
                                            navigate("/account/my-account-address")
                                        }}
                                        data-bs-dismiss="modal" aria-label="Close"                                      >
                                        <i className="bi bi-house-door me-2"></i> Address
                                    </NavLink>
                                </li>
                                <li className="list-group-item border-0 p-2">
                                    <NavLink
                                        className="text-decoration-none text-dark d-block"
                                        to="#"
                                        data-bs-dismiss="modal" aria-label="Close"

                                    onClick={(e) => { e.preventDefault(); handleLogout(); }}
                                    >
                                        <i className="bi bi-door-open me-2"></i> Logout
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}