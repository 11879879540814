import React, {useEffect, useRef} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/controller';
import { Navigation, Pagination, Controller } from 'swiper/modules';
import { BASE_URL } from '../../../gobal/Global';
import {useDispatch, useSelector} from "react-redux";
import {fetchAboutData} from "../../../redux/slice/aboutSlice";

export default function Testimonial() {
    const contentSwiperRef = useRef(null);
    const imageSwiperRef = useRef(null);

    const dispatch = useDispatch()
    const {testimonials} = useSelector(state => state.about)
    
    useEffect(() => {
        dispatch(fetchAboutData());
    }, [dispatch])

    
    return (
        <section>
            <div className="container">
                <div className="wrapper-thumbs-testimonial-v2 flat-thumbs-testimonial flat-thumbs-testimonial-v2 bg-gradient-3">
                    {/* Content Slider */}
                    <div className="box-left">
                        <Swiper
                            modules={[Navigation, Pagination, Controller]}
                            controller={{ control: imageSwiperRef.current }} // Link to image swiper
                            onSwiper={(swiper) => { contentSwiperRef.current = swiper; }}
                            navigation={{
                                nextEl: '.nav-next-tes-2',
                                prevEl: '.nav-prev-tes-2',
                            }}
                            pagination={{
                                el: '.sw-pagination-tes-2',
                                clickable: true,
                                dynamicBullets: true,
                            }}
                            spaceBetween={40}
                            slidesPerView={1}
                            className="tf-sw-tes-2"
                            breakpoints={{
                                768: {
                                    pagination: false, // Disable pagination for screens >= 768px
                                },
                                0: {
                                    pagination: {
                                        el: '.sw-pagination-tes-2', // Enable pagination for screens < 768px
                                        clickable: true,
                                        dynamicBullets: true,
                                    },
                                },
                            }}
                        >
                            {testimonials.map((data) => (
                                <SwiperSlide key={`testmonial_id_${data.id}`}>
                                    <div className="testimonial-item">
                                        <div className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="31"
                                                 viewBox="0 0 46 31" fill="none">
                                                <path
                                                    d="M32.4413 30.5L37.8204 19.9545L38.1913 19.2273H37.375H26.375V0.5H45.5V19.6071L39.9438 30.5H32.4413ZM6.56633 30.5L11.9454 19.9545L12.3163 19.2273H11.5H0.5V0.5H19.625V19.6071L14.0688 30.5H6.56633Z"
                                                    stroke="#B5B5B5"></path>
                                            </svg>
                                        </div>
                                        {/*<div className="heading fs-14 mb_18 text-white">{data.heading}</div>*/}
                                        <div className="heading fs-14 mb_18 text-white">Our customers reviews</div>

                                        <div className="rating">
                                            {([...new Array(data.rating).keys()].map(() => <i className="icon-start"></i>))}
                                            {/*<i className="icon-start"></i>*/}
                                            {/*<i className="icon-start"></i>*/}
                                            {/*<i className="icon-start"></i>*/}
                                            {/*<i className="icon-start"></i>*/}
                                            {/*<i className="icon-start"></i>*/}
                                        </div>
                                        <p className="text text-white">{data.comment}</p>
                                        <div className="author box-author">
                                            <div className="box-img d-md-none">
                                                <img className="lazyload img-product"
                                                    data-src={`${BASE_URL+"/"+data.image}`}
                                                    src={`${BASE_URL+"/"+data.image}`} alt="image-product"/>
                                            </div>
                                            <div className="content">
                                                <div className="name text-white fw-6">{data.name}</div>
                                                {/*<a href="product-detail.html" className="metas link text-white">Purchase*/}
                                                {/*    item : <span className="fw-6">{data.productName}</span></a>*/}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        {/* Navigation Buttons */}
                        <div className="d-md-flex d-none box-sw-navigation">
                            <div className="nav-sw nav-next-slider line-white nav-next-tes-2">
                                <span className="icon icon-arrow-left"></span>
                            </div>
                            <div className="nav-sw nav-prev-slider line-white nav-prev-tes-2">
                                <span className="icon icon-arrow-right"></span>
                            </div>
                        </div>
                        {/* Pagination for mobile screens */}
                        <div className="d-md-none sw-dots style-2 dots-white sw-pagination-tes-2">
                            <span className="swiper-pagination-bullet" tabIndex="0" role="button" aria-label="Go to slide 1"></span>
                            <span className="swiper-pagination-bullet" tabIndex="0" role="button" aria-label="Go to slide 2" aria-current="true"></span>
                        </div>
                    </div>

                    {/* Image Slider */}
                    <div className="box-right">
                        <Swiper
                            modules={[Navigation, Pagination, Controller]}
                            controller={{ control: contentSwiperRef.current }} // Link to content swiper
                            onSwiper={(swiper) => { imageSwiperRef.current = swiper; }}
                            navigation={{
                                nextEl: '.nav-next-tes-2',
                                prevEl: '.nav-prev-tes-2',
                            }}
                            spaceBetween={30}
                            slidesPerView={1}
                            className="tf-thumb-tes"
                        >
                            {testimonials.map((data) => (
                                <SwiperSlide key={`testi-${data.id}`}>
                                    <div className="box-img item-2 hover-img radius-10 o-hidden">
                                        <div className="img-style">
                                            <img className="lazyload" data-src={`${BASE_URL+"/"+data.image}`} src={` ${BASE_URL+"/"+data.image}`} alt="img-slider" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}
