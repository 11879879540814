import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddresses } from '../../../redux/slice/addresSlice';
import { useForm } from 'react-hook-form';
import { fetchCountryData } from '../../../redux/slice/Location';
import { showConfirm, showError } from '../../../gobal/sweetalertManage';
import AddressForm from '../../elements/AddressForm';
import axiosInstance from '../../../gobal/AxiosSetting';


export default function Address() {

    const dispatch = useDispatch();
    const { addresses  } = useSelector((state) => state.address);
    const { reset } = useForm();
    
    // State to control form visibility
    const [isFormVisible, setFormVisible] = useState(false);
    const [editMode, setEditMode] = useState(false); // Track if editing
    const [selectedAddress, setSelectedAddress] = useState(null); // Store address to edit
    

    useEffect(() => {
        dispatch(fetchAddresses());
        dispatch(fetchCountryData());
    }, []);


    const loadAddresses = () => {
        dispatch(fetchAddresses());
    };


    const handleEdit = (address) => {
        setSelectedAddress(address);
        setFormVisible(true);
        setEditMode(true);
    };

    const handleDelete = async (address_id) => {
        try {
            const result = await axiosInstance.delete(`user/address/${address_id}`);
            console.log('result_delete___', result);
            showConfirm(result.data.notification || "Removed successfully");
            
            // Fetch updated addresses after deletion
            dispatch(fetchAddresses());
        } catch (error) {
            // console.log('error_delete___', error);
            showError(error.response?.data?.notification);
        }
    };
    
    // console.log("Address___", addresses);

    return (
        <React.Fragment>
                <div className="my-account-content account-address">
                    <div className="text-center widget-inner-address">
                         <button 
                            className="tf-btn btn-fill animate-hover-btn btn-address mb_20" 
                            onClick={() => { 
                                setFormVisible(!isFormVisible); 
                                setEditMode(false); 
                                reset();
                            }}>
                            { editMode ? "Edit Address" : "Add a new address" }
                        </button>
                        {
                            isFormVisible && (
                                <AddressForm 
                                    isFormVisible={isFormVisible}
                                    setFormVisible={setFormVisible}
                                    editMode={editMode}
                                    selectedAddress={selectedAddress}
                                    setEditMode={setEditMode}
                                    refreshAddresses={loadAddresses}
                                />
                            )
                        }
                        <div className="list-account-address">
                        {
                            addresses?.addresses?.map((data, index) => (
                            <div key={`address-${index}`} className='account-address-item'>
                                <h6 className="mb_20">{data.default_shipping === 1 ? 'Default' : ''}</h6>
                                <p className='text-capitalize fw-6 fs-6 mb-2'>{data.name}</p>
                                <p className='text-capitalize fw-6'>{data.type}</p>
                                <p className='text-capitalize fw-6 text-danger'>GSTN Number - {data.gst_number}</p>
                                <p className='text-capitalize'>{data.address}, {data.city?.name}, </p>
                                <p>{data.postal_code} - {data.country_state?.name},</p>
                                <p className='text-capitalize'>{data.country?.name}</p>
                                <p>{data.email}</p>
                                <p className="mb_10">{data.phone}</p>
                                <div className="d-flex gap-10 justify-content-center">
                                    <button onClick={() => handleEdit(data)}  className="tf-btn btn-fill animate-hover-btn justify-content-center btn-edit-address">
                                        <span>Edit</span>
                                    </button>
                                {
                                    data?.default_billing || data?.default_shipping ? null :
                                    <button onClick={ () => handleDelete(data.id) } className="tf-btn btn-outline animate-hover-btn justify-content-center">
                                        <span>Delete</span>
                                    </button>
                                }
                                </div>
                            </div>
                        ))
                    }
                </div>
                    </div>
                </div>    
        </React.Fragment>
    )
}
