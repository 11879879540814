import React, { useCallback, useEffect, useState } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { NavLink, useNavigate } from 'react-router-dom'
import axiosInstance from '../../gobal/AxiosSetting';
import { Skeleton } from '@mui/material';
import { API_URL, BASE_URL } from '../../gobal/Global';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductData } from '../../redux/slice/productSlice';
import { Container } from 'react-bootstrap';
import { useAddToCart } from '../../hooks/useAddToCart';
import { useWishlist } from '../../hooks/useWishlist';
import ProductCard from '../elements/ProductCard';

export default function HomeSearch() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [results, setResults] = useState({
        categories: [],
        sub_categories: [],
        brands: [],
        products: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { isLoggedIn } = useSelector(state => state.auth);
    const { categories, setting } = useSelector(state => state.setup);
    const { handleCartListToggle, isProductInCartlist } = useAddToCart(); // No longer needs productId as a parameter
    const { handleWishlistToggle, isProductInWishlist } = useWishlist();



    useEffect(() => {
        dispatch(fetchProductData);
    },[])

    const debounce = (func, delay) => {
        let debounceTimer;
        return function (...args) {
            const context = this;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const handleSearch = async (query) => {
        if (query.trim() === '') {
            setResults({
                categories: [],
                sub_categories: [],
                brands: [],
                products: []
            });
            return;
        }

        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.get(`${API_URL}/product-search?search=${query}`);
            const { categories, sub_categories, brands, products } = response.data;

            if (
                categories.length === 0 &&
                sub_categories.length === 0 &&
                brands.length === 0 &&
                products.data.length === 0
            ) {
                setError(`No results found for "${query}".`);
            } else {
                setResults({
                    categories,
                    sub_categories,
                    brands,
                    products: products.data
                });
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            setError('An error occurred while fetching search results.');
            setResults({
                categories: [],
                sub_categories: [],
                brands: [],
                products: []
            });
        } finally {
            setLoading(false);
        }
    };

    const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

    useEffect(() => {
        debouncedSearch(search);
    }, [search, debouncedSearch]);

    // Function to close modal when a product is clicked
    const closeModal = () => {
        const modalElement = document.getElementById('canvaSearchModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.hide();
    };

    const handleAddToCart = async (productId) => {
        if (isLoggedIn) {
            await handleCartListToggle(productId);
            // showConfirm(isProductInCartlist(productId) ? "Removed from cart" : "Added to cart");
        } else {
            navigate('/login'); // Redirect to login if not logged in
        }
    };


    console.log(results);

    return (
        <React.Fragment>
            <InnerBanner title="Search"/>
            <div className="flat-spacing-8 page-search-inner">
                <div className="tf-search-head">
                    <Container>
                        <form className="tf-mini-search-frm" onSubmit={(e) => e.preventDefault()}>
                            <fieldset className="text">
                                <input 
                                    type="text" 
                                    placeholder="Search" 
                                    className="" 
                                    name="text" 
                                    tabindex="0" 
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    aria-required="true" 
                                    required=""/>
                            </fieldset>
                            <button className="" type="submit" onClick={() => handleSearch(search)}><i className="icon-search"></i></button>
                        </form>
                        <div className="tf-col-quicklink">
                            <span className="title">Quick link:</span>
                            {
                                categories?.map(cat => (
                                    <NavLink key={`categories_${cat.slug}`} className="link mx-1" to={`/product/${cat.id}`}>{cat.name},</NavLink>
                                ))    
                            }
                        </div>
                    </Container>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tf-search-content-title">
                                Need some inspiration?
                            </div>
                        </div>

                        {/* Results Section */}
                        {loading && <Skeleton count={5} />}
                        {!loading && error && <p>{error}</p>}
                        {!loading && results.products.length > 0 && (
                            <React.Fragment>
                                {results.products.map((product) => {
                                    const inCart = isProductInCartlist(product.id);
                                    const inWishlist = isProductInWishlist(product.id); // Check if product is in wishlist
                                    return(
                                    <div key={product.id} className="col-lg-3 col-md-4 col-6">
                                        <ProductCard inCart={inCart} inWishlist={inWishlist} data={product}/>
                                    </div>
                                )})}
                            </React.Fragment>
                        )}
                        
                    </div>    
                </div>
            </div>
        </React.Fragment>
  )
}
