import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from "../../gobal/Global";

// Define the initial state for setup data
const initialState = {
    footer: {},
    setting: {},
    socialLinks: [],
    categories: [],
    customPages: [],
    defaultProfile: {},
    seoSetting: [], // Assuming you might have SEO settings
    announcementModal: {}, // Assuming you might have SEO settings
    image_content: [],
    status: 'idle', // Status can be 'idle', 'loading', 'succeeded', or 'failed'
    error: null,    // Error message, if any
};

// Create async thunk for fetching setup data
export const fetchSetupData = createAsyncThunk('setup/fetchSetupData', async () => {
    const response = await axios.get(`${API_URL}/website-setup`);
    console.log("setup__",response);
    return response.data;
});

// Create the slice
const setupSlice = createSlice({
    name: 'setup',
    initialState,
    reducers: {
        // getFooter: (state, action) => {
        //     state.footer = action.payload; // This can be used to set footer data from elsewhere
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSetupData.pending, (state) => {
                state.status = 'loading'; // Set loading state
            })
            .addCase(fetchSetupData.fulfilled, (state, action) => {
                state.status = 'succeeded'; // Data fetch succeeded
                // console.log(fetchSetupData)
                const data = action.payload;

                // Update state with the fetched data
                state.footer = data.footer || {};
                state.setting = data.setting || {};
                state.categories = data.productCategories || [];
                state.customPages = data.customPages || [];
                state.socialLinks = data.social_links || [];
                state.defaultProfile = data.defaultProfile || {};
                state.error = data.errorPage || {};
                state.flashSale  = {flashSaleActive:data.flashSaleActive,flashSaleProducts:data.flashSaleProducts,flashSale:data.flashSale} || {};
                state.announcementModal  = data.announcementModal || {};
                state.seoSetting = data.seo_setting || [];
                state.image_content = data.image_content || [];
            })
            .addCase(fetchSetupData.rejected, (state, action) => {
                state.status = 'failed'; // Data fetch failed
                state.error = action.error.message; // Capture error message
            });
    },
});

// Export the async thunk and the reducer
export const { getFooter } = setupSlice.actions; // Exporting the getFooter action
export default setupSlice.reducer; // Exporting the reducer
