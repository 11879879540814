import React, { useEffect, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchOrders } from '../../../redux/slice/orderSlice';
import DateFormat from '../../../utils/DateFormat';
import { currency } from '../../../Currency';
import { statuses } from '../../../gobal/Global';


export default function Orders() {

    const dispatch = useDispatch();
    const { orders, loading, error, pagination } = useSelector((state) => state.orders);

    const [ currentPage, setCurrentPage] = useState(1); // Track current page

    useEffect(() => {
        dispatch(fetchOrders({ page: currentPage })); // Fetch the first page of orders
    }, [dispatch, currentPage ]);

    console.log("Orders___", orders);

    const handlePageChange = (page) => {
        if (page) {
            setCurrentPage(Number(page));
        }
    };
    
    if(loading) {
        return <Spinner animation='border'/>
    }
    
    return (
        <React.Fragment>
            <Col lg="9">
                <div className="my-account-content account-order">
                    <div className="wrap-account-order">
                        <table>
                            <thead>
                                <tr>
                                    <th className="fw-6">Order</th>
                                    <th className="fw-6">Date</th>
                                    <th className="fw-6">Status</th>
                                    <th className="fw-6">Total</th>
                                    <th className="fw-6">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                orders?.data?.map(data => (
                                    <tr key={`orders-${data.id}`} className="tf-order-item">
                                        <td>
                                            #{data.order_id}
                                        </td>
                                        <td>
                                            {DateFormat(data.created_at)}
                                        </td>
                                        <td>
                                            <span className="text-capitalize">{statuses.find(status => status.id === data.order_status)?.name}</span>
                                        </td>
                                        <td>
                                            {currency.icon} {data.total_amount.toFixed(2)} for {data.product_qty} items
                                        </td>
                                        <td>
                                            <NavLink to={`/account/my-account-orders-details/${data.order_id}`} className="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">
                                                <span>View</span>
                                            </NavLink>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                    <ul className="tf-pagination-wrap tf-pagination-list">
                        {orders?.links?.map((link, index) => (
                            <li
                                key={index}
                                className={link.active ? "active" : ""}
                                onClick={() =>
                                    link.url && handlePageChange(new URL(link.url).searchParams.get("page"))
                                }
                            >
                                <NavLink to="#" className="pagination-link">
                                    {link.label.replace("&laquo;", "«").replace("&raquo;", "»")}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </Col>
        </React.Fragment>
    )
}
