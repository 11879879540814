import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../gobal/AxiosSetting';

// Async Thunks for Wishlist actions
export const fetchWishlist = createAsyncThunk(
    'wishlist/fetchWishlist',
    async () => {
        const response = await axiosInstance.get(`/user/wishlist`);
        return response.data; // Return the response data (wishlist with products)
    }
);

export const addToWishlist = createAsyncThunk(
    'wishlist/addToWishlist',
    async (productId) => {
        const response = await axiosInstance.get(`/user/add-to-wishlist/${productId}`);
        return response.data; // Return updated wishlist
    }
);

export const removeFromWishlist = createAsyncThunk(
    'wishlist/removeFromWishlist',
    async (productId) => {
        const response = await axiosInstance.delete(`/user/delete-wishlist/${productId}`);
        return productId; // Return the ID of the removed product
    }
);

export const clearWishlist = createAsyncThunk(
    'wishlist/clearWishlist',
    async () => {
        const response = await axiosInstance.get(`/user/clear-wishlist`);
        return response.data; // Clear wishlist response
    }
);

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState: {
        wishlist: [],
        loading: false,
        error: null,
        message: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch wishlist
            .addCase(fetchWishlist.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchWishlist.fulfilled, (state, action) => {
                state.loading = false;
                state.wishlist = action.payload.wishlists.data; // Store the wishlist data
                state.error = null;
            })
            .addCase(fetchWishlist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Add to wishlist
            .addCase(addToWishlist.pending, (state) => {
                state.loading = true;
            })
            .addCase(addToWishlist.fulfilled, (state, action) => {
                state.loading = false;
                state.wishlist.push(action.payload.data); // Add the item to the wishlist
                state.error = null;
                state.message = "Added to wishlist successfully";
            })
            .addCase(addToWishlist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Remove from wishlist
            .addCase(removeFromWishlist.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeFromWishlist.fulfilled, (state, action) => {
                state.loading = false;
                state.wishlist = state.wishlist.filter(item => item.id !== action.payload); // Remove the item by ID
                state.error = null;
                state.message = "Removed from wishlist successfully";
            })
            .addCase(removeFromWishlist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Clear wishlist
            .addCase(clearWishlist.pending, (state) => {
                state.loading = true;
            })
            .addCase(clearWishlist.fulfilled, (state, action) => {
                state.loading = false;
                state.wishlist = []; // Clear the entire wishlist
                state.message = action.payload.message;
                state.error = null;
            })
            .addCase(clearWishlist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default wishlistSlice.reducer;
