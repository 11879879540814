// src/redux/aboutSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../gobal/Global';
import axios from 'axios';

// Async action to fetch data
export const fetchCategoryListData = createAsyncThunk(
  'categoryList/fetchCategoryListData',
  async () => {
    const response = await axios.get(`${API_URL}/category-list`);
    return response.data; // This assumes response.data contains { categories: [...] }
  }
);

// Create a slice
const categorySlice = createSlice({
  name: 'categoryList',
  initialState: {
    categories: [],  // Adjusting to reflect the structure you are returning
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryListData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategoryListData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.categories = payload.categories || []; // Handle the structure safely
      })
      .addCase(fetchCategoryListData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;
