import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeliveryData } from '../../redux/slice/deliverySlice';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../gobal/AxiosSetting';
import { toast } from 'react-toastify';

export default function CheckDelivery() {
    const dispatch = useDispatch();
    const { loading, city } = useSelector(state => state.delivery);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [deliveryMessage, setDeliveryMessage ] = useState();
    const [messageType, setMessageType] = useState(''); // Tracks message type: success or error
    const [submitting, setSubmitting] = useState(false); // Tracks form submission state


    useEffect(() => {
        dispatch(fetchDeliveryData());
    },[]);

    // Handle form submission
    const onSubmit = async (data) => {

        setSubmitting(true); // Start form submission state
        setDeliveryMessage(''); // Reset message
        setMessageType(''); // Reset message type
        
        try {
            const response = await axiosInstance.post('/user/delivery', {
                postal_code: data.postal_code || null,
            });
    
            // Use the notification variable from API response
            const notification = response.data?.notification;
    
            if (response.status === 200 && notification) {
                toast.success(notification); // Display success message
                setDeliveryMessage(notification);
                setMessageType('success'); // Set message type
            }
        } catch (error) {
            const errorMessage = error.response?.data?.notification || 'An error occurred. Please try again.';
            setDeliveryMessage(errorMessage);
            setMessageType('error'); // Set message type
            toast.error(errorMessage); // Display API-provided or generic error message
        }finally{
            setSubmitting(false); // End form submission state
        }
    };
    

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="shipping-calculator">
                <summary className="accordion-shipping-header d-flex justify-content-between align-items-center collapsed" data-bs-target="#shipping" data-bs-toggle="collapse" aria-controls="shipping">
                    <h3 className="shipping-calculator-title">Check delivery availability</h3>
                    <span className="shipping-calculator_accordion-icon"></span>
                </summary>
                <div className="collapse" id="shipping">
                    <div className="accordion-shipping-content">
                        <fieldset className="field">
                            <label htmlFor='zipcode' className="label">Zip code</label>
                            <input type="text" id='zipcode'  {...register('postal_code', {
                                    pattern: {
                                        value: /^[0-9]{5,6}$/,
                                        message: 'Invalid zip code format',
                                    },
                                })} placeholder=""/>
                            {errors.postal_code && (
                                <span className="text-danger">{errors.postal_code.message}</span>
                            )}
                        </fieldset>
                        <span className={messageType === 'success' ? 'text-success' : 'text-danger'}>
                            {deliveryMessage}
                        </span>
                        <button type='submit' className="tf-btn btn-fill w-100 my-1 fw-5 animate-hover-btn radius-3 justify-content-center"  disabled={submitting || loading}>
                            <span>{submitting ? 'Checking...' : 'Check'}</span>
                        </button>
                    </div>
                </div>
            </div>   
        </form>
    )
}
