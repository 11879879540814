import React, { useEffect, useState, useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../gobal/AxiosSetting';
import Skeleton from 'react-loading-skeleton';
import { API_URL, BASE_URL } from '../../../gobal/Global';
import '../../css_files/search.css';

export default function CanvaSearch() {

    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [results, setResults] = useState({
        categories: [],
        sub_categories: [],
        brands: [],
        products: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    const debounce = (func, delay) => {
        let debounceTimer;
        return function (...args) {
            const context = this;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const handleSearch = async (query) => {
        if (query.trim() === '') {
            setResults({
                categories: [],
                sub_categories: [],
                brands: [],
                products: []
            });
            return;
        }

        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.get(`${API_URL}/product-search?search=${query}`);
            const { categories, sub_categories, brands, products } = response.data;

            if (
                categories.length === 0 &&
                sub_categories.length === 0 &&
                brands.length === 0 &&
                products.data.length === 0
            ) {
                setError(`No results found for "${query}".`);
            } else {
                setResults({
                    categories,
                    sub_categories,
                    brands,
                    products: products.data.slice(0,4) // limit products to 4
                });
            }
        } catch (error) {
            setError(error?.response?.data?.notification || 'An error occurred while fetching search results.');
            setResults({
                categories: [],
                sub_categories: [],
                brands: [],
                products: []
            });
        } finally {
            setLoading(false);
        }
    };

    const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

    useEffect(() => {
        debouncedSearch(search);
    }, [search, debouncedSearch]);

    

    // console.log(results);

    return (
        <div className="modal fade" id="canvaSearchModal" tabIndex="-1" aria-labelledby="canvaSearchModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="canvaSearchModalLabel">Search our catalog</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="mb-3" onSubmit={(e) => e.preventDefault()}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search categories, brands, products..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    aria-required="true"
                                    required
                                />
                                <button className="btn btn-outline-secondary" type="button" onClick={() => handleSearch(search)}>
                                    <i className="icon-search"></i>
                                </button>
                            </div>
                        </form>

                        {/* Results Section */}
                        {loading && <Skeleton count={5} />}
                        {!loading && error && <p className="text-danger">{error}</p>}
                        {!loading && !error && (
                            <>
                                {/* Brands */}
                                {/* {results.brands.length > 0 && (
                                    <div>
                                        <h6>Brands</h6>
                                        <ul className="list-group mb-3">
                                            {results.brands.map((brand) => (
                                                <li
                                                    key={brand.id}
                                                    className="list-group-item"
                                                    onClick={closeModal}
                                                >
                                                    <NavLink to={`/brand/${brand.id}`}>
                                                        {brand.name}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )} */}

                                {/* Products */}
                                {results.products.length > 0 && (
                                    <div>
                                        {/* <h6>Products</h6> */}
                                        <ul className="list-group">
                                            
                                            {/* category */}
                                            {results.categories.map((category) => (
                                                <li
                                                    key={category.id}
                                                    className="list-group-item"
                                                >
                                                    <NavLink
                                                        onClick={(e) => {
                                                            e.stopPropagation(
                                                                navigate(`/product/${category.id}`)
                                                            )
                                                        }} 
                                                        data-bs-dismiss="modal"
                                                    >
                                                        {category.name}
                                                    </NavLink>
                                                </li>
                                            ))}
                                            
                                            {/* subcategory */}
                                            {results.sub_categories.map((subCategory) => (
                                                <li
                                                    key={subCategory.id}
                                                    className="list-group-item"
                                                >
                                                    <NavLink
                                                        onClick={(e) => {
                                                            e.stopPropagation(
                                                                navigate(`/product/${subCategory.category.slug}/${subCategory.id}`)
                                                            )
                                                        }} 
                                                        data-bs-dismiss="modal"
                                                    >
                                                        {subCategory.name}
                                                    </NavLink>
                                                </li>
                                            ))}

                                            {/* products */}
                                            {results.products.map((product) => (
                                                <li
                                                    key={product.id}
                                                    className="list-group-item"
                                                >
                                                    <img className="search-product-img" src={`${BASE_URL}/${product.thumb_image}`} alt={product.slug} />
                                                    <NavLink to={`/product-detail/${product.slug}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation(
                                                                navigate(`/product-detail/${product.slug}`)
                                                            )
                                                        }} 
                                                        data-bs-dismiss="modal"
                                                    >
                                                        {product.name}
                                                    </NavLink>
                                                </li>
                                            ))}
                                            <NavLink
                                                className="btn btn-link"
                                                onClick={(e) => {
                                                    e.stopPropagation(
                                                        navigate("/product")
                                                    )
                                                }} 
                                                data-bs-dismiss="modal"

                                            >View All Products</NavLink>
                                        </ul>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
