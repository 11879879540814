import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../../gobal/AxiosSetting';

// Async Thunks for Cart actions
export const fetchAddToCart = createAsyncThunk(
    'addToCart/fetchAddToCart',
    async () => {
        const response = await axiosInstance.get(`/cart`);
        return response.data;
    }
);

export const addCart = createAsyncThunk(
    'addToCart/addCart',
    async (credentials) => {
        try {
            const response = await axiosInstance.post(`/add-to-cart`, {...credentials});
            return response.data;

        } catch (error) {
            throw Error(error.response.data.message);
        }

    }
);

export const removeFromCart = createAsyncThunk(
    'addToCart/removeFromCart',
    async (cartId) => {
        const response = await axiosInstance.get(`/cart-item-remove/${cartId}`);
        return response.data;
    }
);

export const clearFromCart = createAsyncThunk(
    'addToCart/clearFromCart',
    async () => {
        const response = await axiosInstance.delete(`/cart-clear`);
        return response.data;
    }
);

export const cartItemIncrement = createAsyncThunk(
    'addToCart/cartItemIncrement',
    async (cartId) => {
       try {const response = await axiosInstance.get(`/cart-item-increment/${cartId}`);
        return response.data;

        } catch (error) {
            throw Error(error.response.data.message);
        }
    }
);

export const cartItemDecrement = createAsyncThunk(
    'addToCart/cartItemDecrement',
    async (cartId) => {
        const response = await axiosInstance.get(`/cart-item-decrement/${cartId}`);
        return response.data;
    }
);

const addToCartSlice = createSlice({
    name: 'addToCart',
    initialState: {
        addToCart: [],
        loading: false,
        error: null,
        message: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch cart items
            .addCase(fetchAddToCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAddToCart.fulfilled, (state, action) => {
                state.loading = false;
                state.addToCart = action.payload.cartProducts;
                state.error = null;
            })
            .addCase(fetchAddToCart.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Add to cart
            .addCase(addCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCart.fulfilled, (state, action) => {
                state.loading = false;
                const newItem = action.payload.data;
                state.addToCart.push({
                    id: newItem.id,
                    product_id: newItem.product_id,
                    qty: newItem.qty,
                    product: newItem.product,
                    variants: newItem.variants,
                });
                state.error = null;
                state.message = "Added to cart successfully";
            })
            .addCase(addCart.rejected, (state, action) => {
                state.loading = false;
                console.log(action)
                // state.error = action.error.message;
            })
            // Remove from cart
            .addCase(removeFromCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeFromCart.fulfilled, (state, action) => {
                state.loading = false;
                state.addToCart = state.addToCart.filter(item => item.id !== action.meta.arg);
                state.error = null;
                state.message = "Removed from cart successfully";
            })
            .addCase(removeFromCart.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Clear cart
            .addCase(clearFromCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(clearFromCart.fulfilled, (state, action) => {
                state.loading = false;
                state.addToCart = [];
                state.message = action.payload.message;
                state.error = null;
            })
            .addCase(clearFromCart.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Increment cart item
            .addCase(cartItemIncrement.pending, (state) => {
                state.loading = true;
            })
            .addCase(cartItemIncrement.fulfilled, (state, action) => {
                state.loading = false;
                console.log("abkjxbhas")
                // const updatedItem = action.payload.cartProducts.find(
                //     product => product.id === action.meta.arg
                // );
                // if (updatedItem) {
                //     const item = state.addToCart.find(product => product.id === updatedItem.id);
                //     if (item) {
                //         item.qty = updatedItem.qty;
                //     }
                // }
                state.error = null;
            })
            .addCase(cartItemIncrement.rejected, (state, action) => {
                state.loading = false;
                console.log(action,"aaaaaaaaaa")
                state.error = action.error.message;
            })
            // Decrement cart item
            .addCase(cartItemDecrement.pending, (state) => {
                state.loading = true;
            })
            .addCase(cartItemDecrement.fulfilled, (state, action) => {
                state.loading = false;
                // const updatedItem = action.payload.cartProducts.find(
                //     product => product.id === action.meta.arg
                // );
                // if (updatedItem) {
                //     const item = state.addToCart.find(product => product.id === updatedItem.id);
                //     if (item) {
                //         item.qty = updatedItem.qty;
                //     }
                // }
                state.error = null;
            })
            .addCase(cartItemDecrement.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default addToCartSlice.reducer;
