import React from 'react';
// import { CampaignData } from '../../../gobal/Global';
import { NavLink } from 'react-router-dom';
import {BASE_URL } from "../../../gobal/Global";
import { useSelector} from "react-redux";



export default function Campaign() {

    // const dispatch = useDispatch()
    const { banners} = useSelector(state => state.home)


    // Default image URL
    const DEFAULT_IMAGE = "/assets/images/default/comming-soon.jpg";


    // Function to determine width based on screen size
    const getSlideStyles = () => {
        const screenWidth = window.innerWidth;
        let slideWidth = '100%'; // default for mobile
        let marginRight = '15px';

        if (screenWidth > 1200) {
            slideWidth = '643.5px'; // large screens
            marginRight = '30px';
        } else if (screenWidth > 768) {
            slideWidth = '50%'; // tablets
            marginRight = '20px';
        } else {
            slideWidth = '100%'; // mobile screens
            marginRight = '15px';
        }
        
        return { width: slideWidth, marginRight };
    }

    // Check if banners are available, replace null banners with default image
    const hasBannerOne = banners?.bannerOne;
    const hasBannerTwo = banners?.bannerTwo;

    const bannerOneImage = hasBannerOne?.image ? `${BASE_URL + "/" + hasBannerOne.image}` : DEFAULT_IMAGE;
    const bannerTwoImage = hasBannerTwo?.image ? `${BASE_URL + "/" + hasBannerTwo.image}` : DEFAULT_IMAGE;

    if (!hasBannerOne && !hasBannerTwo) {
        return null; // Don't render anything if both banners are null
    }


    return (
        <section className="flat-spacing-28">
            <div className="container">
                <div className="swiper tf-sw-collection swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden" data-preview="2" data-tablet="2" data-mobile="1.4" data-space-lg="30" data-space-md="30" data-space="15" data-loop="false" data-auto-play="false">
                    <div className="swiper-wrapper" id="swiper-wrapper-a41eaaa10c587108a6" aria-live="polite" style={{ transform: "translate3d(0px, 0px, 0px)", transitionDuration: "0ms" }}>
                        {
                            <div key={`compaign-1`} className="swiper-slide swiper-slide-active" lazy="true"
                              role="group" aria-label="1 / 2" style={getSlideStyles()}>
                                <div className="collection-item-v4 st-lg hover-img">
                                    <div className="collection-inner">
                                        <NavLink to={`/product/${hasBannerOne?.product_slug}/${hasBannerOne?.categories_id}`}
                                            className="collection-image img-style radius-10 o-hidden">
                                            <img className="lazyload" data-src={bannerOneImage} src={bannerOneImage} alt="collection-img" />
                                        </NavLink>
                                        <div className="collection-content text-start wow fadeInUp" data-wow-delay="0s">
                                            <h5 className="heading">{hasBannerOne?.title_one || null}</h5>
                                            <p className="subtext">{hasBannerOne?.title_two || null}</p>
                                            {
                                                hasBannerOne ? 
                                                <NavLink to={`/product/${hasBannerOne?.categories_id}`} className="tf-btn btn-line collection-other-link fw-6">
                                                    <span>Shop Collection</span>
                                                    <i className="icon icon-arrow1-top-left"></i>
                                                </NavLink> : 
                                                <NavLink to="/" className="tf-btn btn-line collection-other-link fw-6" >
                                                    <span>Shop Collection</span>
                                                    <i className="icon icon-arrow1-top-left"></i>
                                                </NavLink>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {<div key={`compaign-2`} className="swiper-slide swiper-slide-active" lazy="true"
                                role="group" aria-label="1 / 2" style={getSlideStyles()}>
                                <div className="collection-item-v4 st-lg hover-img">
                                    <div className="collection-inner">
                                        <NavLink to={`/product/${hasBannerTwo?.product_slug}/${hasBannerTwo?.categories_id}`}
                                            className="collection-image img-style radius-10 o-hidden">
                                            <img className="lazyload" data-src={bannerTwoImage}
                                                src={bannerTwoImage} alt="collection-img" />
                                        </NavLink>
                                        <div className="collection-content text-start wow fadeInUp" data-wow-delay="0s">
                                            <h5 className="heading">{hasBannerTwo?.title_one || "Default Title"}</h5>
                                            <p className="subtext">{hasBannerTwo?.title_two || "Default Subtitle"}</p>
                                        {
                                            hasBannerTwo ? 
                                            <NavLink to={`/product/${hasBannerTwo?.categories_id}`} className="tf-btn btn-line collection-other-link fw-6">
                                                <span>Shop Collection</span>
                                                <i className="icon icon-arrow1-top-left"></i>
                                            </NavLink> : 
                                            <NavLink to="/" className="tf-btn btn-line collection-other-link fw-6">
                                                <span>Shop Collection</span>
                                                <i className="icon icon-arrow1-top-left"></i>
                                            </NavLink>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </section>
    )
}
