import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {API_URL, BASE_URL} from "../../gobal/Global";
import apiRequest from "../../utils/apiRequest";

// Define the initial state for home data
const initialState = {
    // aboutUs: {},
    contact: {},
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    loading: true,
};

// Create async thunk for submitting contact data
export const submitContact = createAsyncThunk(
    'contact/submitContact',
    async (contactData, { rejectWithValue }) => {
        try {
            const response = await apiRequest.contact(contactData);
            return response.data; // Adjust based on the actual response structure
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
// Create async thunk for fetching contact data
export const fetchContactDetails = createAsyncThunk(
    'contact/fetchContactDetails',
    async () => {
            const response = await axios.get(`${API_URL}/contact-us`);
            return response.data; // Adjust based on the actual response structure
    }
);

// Create the slice
const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitContact.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(submitContact.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const data = action.payload;

                // Update state with the fetched data

                // state.aboutUs = data.aboutUs || {};
                // state.testimonials = data.testimonials || [];
            })
            .addCase(submitContact.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchContactDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchContactDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const data = action.payload;
                state.loading = false;

                // Update state with the fetched data

                state.contact = data.contact || {};
                // state.testimonials = data.testimonials || [];
            })
            .addCase(fetchContactDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.loading = false;

            });
    },
});

// Export the async thunk and the reducer
export default contactSlice.reducer;
