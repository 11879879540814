import React from 'react'
import { BASE_URL } from '../../../gobal/Global'
import CountDown from '../../../gobal/CountDown';
import { NavLink } from 'react-router-dom';

export default function FlashSale({sale}) {
    const { 
        showDate, 
        showHour, 
        showMinute, 
        showSecound 
    } = CountDown(`${sale?.end_time}`);

    return (
        <section className="flat-spacing-8 pb_0">
            <div className="container">
                <div className="tf-grid-layout gap-0 md-col-2 bg-gradient-3 tf-img-with-text style-5">
                    <div className="tf-image-wrap wow fadeInUp" data-wow-delay="0s">
                        <img className="lazyload" data-src={`${BASE_URL}/${sale?.homepage_image}`} src={`${BASE_URL}/${sale?.homepage_image}`} alt="collection-img"/>
                    </div>
                    <div className="tf-content-wrap wow fadeInUp" data-wow-delay="0s">
                        <div className="sub-heading fw-7 text-white">{sale?.title}</div>
                        <div className="heading text-white">{sale?.offer}% Discount</div>
                        <p className="description text_white">{sale?.description}</p>
                        <div className="count-down">
                            <div className="description text-white"><strong>Hungry up ! </strong>Deals end in :</div>
                            <div className="tf-countdown-v2">
                                <div className="js-countdown" data-timer="8007500" data-labels="Days,Hours,Mins,Secs">
                                    <div className="countdown__timer">
                                        <div className="countdown__item">
                                            <span className="countdown__value countdown__value--0 js-countdown__value--0">{showDate}</span>
                                            <span className='countdown-label'>Days</span>
                                        </div>
                                        <div className="countdown__item">
                                            <span className="countdown__value countdown__value--0 js-countdown__value--0">{showHour}</span>
                                            <span className='countdown-label'>Hours</span>
                                        </div>
                                        <div className="countdown__item">
                                            <span className="countdown__value countdown__value--0 js-countdown__value--0">{showMinute}</span>
                                            <span className='countdown-label'>Mins</span>
                                        </div>
                                        <div className="countdown__item">
                                            <span className="countdown__value countdown__value--0 js-countdown__value--0">{showSecound}</span>
                                            <span className='countdown-label'>Secs</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <NavLink to="/flashsale" className="tf-btn style-2 btn-fill rounded-full animate-hover-btn">Shop Now</NavLink>
                    </div>
                </div>
            </div>
        </section>
    )
}
