import React from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BASE_URL } from '../../gobal/Global';
import "swiper/css";
import "swiper/css/pagination";
import { useSelector } from "react-redux";

export default function Brands() {
    const { brands, brandVisibility } = useSelector(state => state.home);

    return (
        <section className="flat-spacing-27 wow fadeIn" data-wow-delay="0s">
            <div className="container">
                <div className="wrap-carousel wrap-brand wrap-brand-v2">
                    <Swiper
                        className="swiper tf-sw-brand border-0"
                        spaceBetween={10} // Default space between slides
                        slidesPerView={2} // Default number of slides per view
                        loop={false}
                        autoplay={false}
                        pagination={{
                            clickable: true,
                            el: ".sw-pagination-brand",
                        }}
                        breakpoints={{
                            1200: {
                                slidesPerView: 6,
                                spaceBetween: 30,
                            },
                            992: {
                                slidesPerView: 5,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 15,
                            },
                            576: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                        }}
                        modules={[Pagination]}
                    >
                        {brands.map((data, index) => (
                            <SwiperSlide key={`brand${index}`}>
                                <div className="brand-item-v2">
                                    <img
                                        className="lazyload"
                                        data-src={`${BASE_URL}/${data.logo}`}
                                        src={`${BASE_URL}/${data.logo}`}
                                        style={{ height: "100px", objectFit: "contain" }}
                                        alt={`brand-logo-${index}`}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className="sw-dots style-2 sw-pagination-brand justify-content-center"></div>
                </div>
            </div>
        </section>
    );
}
