import Swal from "sweetalert2";



export const showConfirm = (message) => {
    Swal.fire({
        title: 'Success', 
        icon: 'success',
        text: message,
        confirmButtonText: 'OK',
    });
};

export const showError = (message) => {
    Swal.fire({
        title: 'Error', 
        icon: 'error',
        text: message,
        confirmButtonText: 'OK',
    })
}