import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../gobal/AxiosSetting';
import { API_URL } from '../../gobal/Global';

// Initial state
const initialState = {
    orders: [],
    loading: false,
    error: null,
    pagination: {
        currentPage: 1,
        totalPages: 1,
        totalOrders: 0,
    }
};

// Async thunk to fetch orders
export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async (page = 1, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/user/order?page=${page}`);
            return response.data; // Adjust this if the structure is different
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Create order slice
const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.loading = false;
                state.orders = action.payload.orders || []; // Adjust according to the actual structure
                state.pagination = {
                    currentPage: action.payload.current_page,
                    totalPages: action.payload.last_page,
                    totalOrders: action.payload.total,
                };
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.notification || 'Something went wrong';
            });
    },
});

// Export the actions and reducer
export default orderSlice.reducer;
