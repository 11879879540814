import React, { useEffect } from 'react';
import InnerBanner from '../elements/InnerBanner';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';

export default function RegisterPage() {

    const { register, handleSubmit, formState: { errors, isSubmitting }} = useForm();


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isLoggedIn } = useSelector(state => state.auth);

    useEffect(() => {
        // Redirect if the user is already logged in
        if (isLoggedIn) {
            navigate('/verify-account');
        }
    }, [isLoggedIn, navigate]);

    // Handle form submission
    const onSubmit = async (data) => {
        try {
            const response = await axios.post(`${API_URL}/store-register`, data);
            
            if (response.data.notification) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.notification,
                    confirmButtonText: 'OK'
                }).then(() => {
                    navigate('/verify-account');
                });
            } else {
                throw new Error('Registration failed. Please try again.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Registration failed. Please try again.';
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
                confirmButtonText: 'OK'
            });
        }
    };

  return (
    <React.Fragment>
        <InnerBanner title="Register"/>

        <section className="flat-spacing-10">
            <div className="container">
                <div className="form-register-wrap">
                    <div className="flat-title align-items-start gap-0 mb_30 px-0">
                        <h5 className="mb_18">Register</h5>
                        <p className="text_black-2">Sign up for early Sale access plus tailored new arrivals, trends and promotions. To opt out, click unsubscribe in our emails</p>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="text" 
                                    {...register("name", { required: "Name is required" })} 
                                />
                                <label className="tf-field-label" htmlFor="">Name</label>
                                {errors.name && <p className="text-danger mb-3">{errors.name.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="email" 
                                    {...register("email", { 
                                        required: "Email is required", 
                                        pattern: { 
                                            value: /^\S+@\S+$/i, 
                                            message: "Invalid email address" 
                                        }
                                    })} 
                                />
                                <label className="tf-field-label" htmlFor="">Email *</label>
                                {errors.email && <p className="text-danger mb-3">{errors.email.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="text" 
                                    {...register("phone", { required: "Mobile number is required" })} 
                                />
                                <label className="tf-field-label" htmlFor="">Mobile *</label>
                                {errors.phone && <p className="text-danger mb-3">{errors.phone.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="password" 
                                    {...register("password", { required: "Password is required" })} 
                                />
                                <label className="tf-field-label" htmlFor="">Password *</label>
                                {errors.password && <p className="text-danger mb-3">{errors.password.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="password"  
                                    {...register("password_confirmation", { required: "Confirm password is required"})}
                                />
                                <label className="tf-field-label" htmlFor="">Confirm Password *</label>
                                {errors.password_confirmation && <p className="text-danger mb-3">{errors.password_confirmation.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_20">
                                <input 
                                    id="agree"
                                    type="checkbox" 
                                    className='me-2' 
                                    {...register("agree", { required: "Consent is required" })} 
                                />
                                <label htmlFor="agree">I agree to the <NavLink to="terms-conditions">terms and conditions</NavLink></label>
                                {errors.consent && <p className="text-danger mb-3">{errors.consent.message}</p>}
                            </div>

                            <div className="mb_20">
                                <button 
                                    type="submit" 
                                    className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center"
                                    disabled={isSubmitting || loading}
                                >
                                    {loading ? 'Registering...' : 'Register'}
                                </button>

                            </div>

                            <div className="text-center">
                                <NavLink to="/login" className="tf-btn btn-line">Already have an account? Log in here<i className="icon icon-arrow1-top-left"></i></NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}
