import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCouponData } from '../../../redux/slice/couponSlice';
import "../../css_files/dashboard.css";

export default function CouponList({ setCouponCode }) {
    const dispatch = useDispatch();
    const { appliedCoupon, loading } = useSelector((state) => state.coupon);

    useEffect(() => {
        dispatch(fetchCouponData());
    }, [dispatch]);

    const handleCouponSelect = (couponCode) => {
        setCouponCode(couponCode); // Update couponCode in parent component
    };

    return (
        <div className="modal modalCentered fade modalDemo tf-product-modal modal-part-content" id="coupon_code">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="header ">
                        <div className="demo-title">Coupon Code</div>
                        <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                    </div>
                    <div className="overflow-y-auto">
                        {loading ? (
                            <p>Loading...</p>
                        ) : appliedCoupon?.length > 0 ? (
                            appliedCoupon.map((coupon, index) => (
                                <div 
                                    key={`coupon-${index}`} 
                                    className="couponlist" 
                                    data-bs-dismiss="modal"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCouponSelect(coupon.code);
                                        e.currentTarget.setAttribute("data-bs-dismiss", "modal"); 
                                    }}  
                                >
                                    <hr className="hr-line" />
                                    <label htmlFor='coupon' className="tf-product-popup-delivery">
                                        <div className="title">{coupon.code}</div>
                                        <p className="text-paragraph">{coupon.description}</p>
                                    </label>
                                </div>
                            ))
                        ) : (
                            <p>No coupons available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

