import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';


// Define the initial state for home data
const initialState = {
    privacyPolicy: null,  // Store blogs
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    loading: false,
    error: null,
};


// Create async thunk for fetching home data
export const fetchTermsData = createAsyncThunk('termsConditions/fetchTermsData', async () => {
    const response = await axios.get(`${API_URL}/terms-and-conditions`);
    return response.data;
});


// Create the slice
const termsConditionSlice = createSlice({
    name: 'termsConditions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTermsData.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchTermsData.fulfilled, (state, action) => {
                state.termsConditions = action.payload.terms_conditions;
                state.status = 'succeeded';
                state.loading = false;
            })
            .addCase(fetchTermsData.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export the async thunk and the reducer
export default termsConditionSlice.reducer;