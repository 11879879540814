import React, { useEffect, useState } from 'react';
import InnerBanner from '../elements/InnerBanner';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../redux/slice/authSlice';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { showConfirm, showError } from '../../gobal/sweetalertManage';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';

export default function ResetPassword() {

    const { register, handleSubmit, formState: { errors, isSubmitting }} = useForm();


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, isLoggedIn } = useSelector(state => state.auth);
    const { user } = useSelector(state => state.resetPassword);


    // console.log("User__",user);

    
    // Handle form submission
    const onSubmit = async (data) => {
        try {
            const email = user?.email || data.email; // Fallback to email from input if user is not in state
            const requestData = {
                email,
                password: data.password,
                password_confirmation: data.password_confirmation,
            };

            const response = await axios.post(`${API_URL}/store-reset-password/${user.forget_password_token}`, requestData);
            console.log("ResponseRESET__", response);
            const responseMessege = response.data.notification;

            // Update success criteria to reflect actual response
            if (responseMessege || response?.status === 200) {
                showConfirm(responseMessege || 'Password reset successfully.')
                navigate('/login'); // Ensure navigation happens after confirmation
            }
        } catch (error) {
            console.log("Error__",error);

            const errorMessage = error.response?.data?.message || 'Password reset failed. Please try again.';
            showError(errorMessage);
        }
    };


  return (
    <React.Fragment>
        <InnerBanner title="Create New Password"/>

        <section className="flat-spacing-10">
            <div className="container">
                <div className="form-register-wrap">
                    <div className="flat-title align-items-start gap-0 mb_30 px-0">
                        <h5 className="mb_18">Create New Password</h5>
                        <p className="text_black-2">
                        Enter and confirm your new password below to complete the reset process.
                        </p>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="password" 
                                    {...register("password", { required: "Password is required" })} 
                                />
                                <label className="tf-field-label" htmlFor="">Password *</label>
                                {errors.password && <p className="text-danger mb-3">{errors.password.message}</p>}
                            </div>

                            <div className="tf-field style-1 mb_15">
                                <input 
                                    className="tf-field-input tf-input" 
                                    placeholder=" " 
                                    type="password"  
                                    {...register("password_confirmation", { required: "Confirm password is required"})}
                                />
                                <label className="tf-field-label" htmlFor="">Confirm Password *</label>
                                {errors.password_confirmation && <p className="text-danger mb-3">{errors.password_confirmation.message}</p>}
                            </div>

                            <div className="mb_20">
                                <button 
                                    type="submit" 
                                    className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center"
                                    disabled={isSubmitting || loading}
                                >
                                    { isSubmitting ? 'Reseting...' : 'Reset' }
                                </button>

                            </div>

                            <div className="text-center">
                                <NavLink to="/login" className="tf-btn btn-line">Already have an account? Log in here<i className="icon icon-arrow1-top-left"></i></NavLink>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}
