import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSetupData } from "../../redux/slice/setupSlice";
import { Box, Typography, Button, Container } from "@mui/material";
import { BASE_URL } from "../../gobal/Global";

const ErrorPage = () => {
    const dispatch = useDispatch();
    const { error } = useSelector((state) => state.setup);

    useEffect(() => {
        dispatch(fetchSetupData());
    }, [dispatch]);

    return (
        <Container
            maxWidth="lg"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
                textAlign: "center",
                background: `url(${BASE_URL + error?.image}) no-repeat center center`,
                backgroundSize: "contain",
                padding: 5,
            }}
        >
            <Box
                sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)", // Slightly transparent background
                    borderRadius: 2,
                    padding: 4,
                    boxShadow: 3,
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Oops! Something went wrong.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {error?.header}
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    It seems we can't find the page you're looking for.
                </Typography>
                <Button variant="contained" color="primary" href="/" sx={{ mt: 2 }}>
                    Go Back to Home
                </Button>
            </Box>
        </Container>
    );
};

export default ErrorPage;
