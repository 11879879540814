import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {Avatar} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import "../../css_files/profile.css";
import { clearAuth } from '../../../redux/slice/authSlice';

export default function MobileMenu() {

    const dispatch = useDispatch();
    const {footer} = useSelector((state) => state.setup);
    const { categories }  = useSelector((state) => state.setup);
    const {isLoggedIn, user} = useSelector(state => state.auth);

    
    const handleLogout = () => {
        dispatch(clearAuth());
        navigate('/login');
    };
    
    const navigate = useNavigate();

    return (
        <div className="offcanvas offcanvas-start canvas-mb" id="mobileMenu">
            <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
            {
                isLoggedIn ? <NavLink className="site-nav-icon logout" data-bs-dismiss="offcanvas" aria-label="Close" onClick={(e) => { e.preventDefault(); handleLogout(); }}><i className="icon icon-box-arrow-right"></i>Logout</NavLink> : null
            }
            
            <div className="mb-canvas-content">
                <div className="mb-body">
                    <ul className="nav-ul-mb" id="wrapper-menu-navigation">
                        <li className="nav-mb-item">
                            <NavLink to="/" className="mb-menu-link" onClick={(e) => {
                                    e.stopPropagation(
                                        navigate("/")
                                    )
                                }} data-bs-dismiss="offcanvas" aria-label="Close">
                                <span>Home</span>
                            </NavLink>
                        </li>
                        <li className="nav-mb-item">
                            <NavLink to="/about" className="collapsed mb-menu-link current" onClick={(e) => {
                                    e.stopPropagation(
                                        navigate("/about")
                                    )
                                }} data-bs-dismiss="offcanvas" aria-label="Close">
                                <span>About us</span>
                            </NavLink>
                        </li>
                        <li className="nav-mb-item">
                            <NavLink to="/products" data-bs-target="#dropdown-menu-two" className="collapsed mb-menu-link current" data-bs-toggle="collapse" aria-expanded="true" aria-controls="dropdown-menu-two">
                                <span>Shop</span>
                                <span className="btn-open-sub"></span>
                            </NavLink>
                            <div id="dropdown-menu-two" className="collapse">
                                <ul className="sub-nav-menu" id="sub-menu-navigation">
                                {
                                    categories.map((cat,index) => {
                                        return cat.active_sub_categories.find((sub, index) => {
                                            // console.log(sub,"sub")
                                            return sub.products_count > 0
                                        }) ? (
                                            <li key={`cat-${index}`}>
                                                <NavLink  data-bs-target={`#sub-shop-${cat.slug}`} className="sub-nav-link collapsed"  data-bs-toggle="collapse" aria-expanded="true" aria-controls={`sub-shop-${cat.slug}`}>
                                                    <span>{cat.name}</span>
                                                    <span className="btn-open-sub"></span>
                                                </NavLink>
                                                <div id={`sub-shop-${cat.slug}`} className="collapse">
                                                    <ul className="sub-nav-menu sub-menu-level-2">
                                                    {
                                                        cat.active_sub_categories.map((sub,index) => {
                                                            return sub.products_count > 0 ? <li key={`sub-${index}`}><NavLink to={`/product/${cat.slug}/${sub.id}`} className="sub-nav-link" onClick={(e) => {
                                                                e.stopPropagation(
                                                                    navigate(`/product/${cat.slug}/${sub.id}`)
                                                                )
                                                            }}  data-bs-dismiss="offcanvas" aria-label="Close">{sub.name}</NavLink></li> : null
                                                        })
                                                    }
                                                    </ul>
                                                </div>
                                            </li>) : null
                                        })
                                }
                                </ul>
                            </div>
                        </li>
                        
                        
                        <li className="nav-mb-item">
                            <NavLink to="/blog" className="mb-menu-link" onClick={(e) => {
                                    e.stopPropagation(
                                        navigate("/blog")
                                    )
                                }} data-bs-dismiss="offcanvas" aria-label="Close">
                                <span>Blog</span>
                            </NavLink>
                        </li>
                        <li className="nav-mb-item">
                            
                        </li>
                    </ul>
                    <div className="mb-other-content">
                        <div className="d-flex group-icon">
                            <NavLink to="/wishlist" className="site-nav-icon" onClick={(e) => {
                                    e.stopPropagation(
                                        navigate("/wishlist")
                                    )
                                }} data-bs-dismiss="offcanvas" aria-label="Close"><i className="icon icon-heart"></i>Wishlist</NavLink>
                            <NavLink to="/home-search" className="site-nav-icon" onClick={(e) => {
                                    e.stopPropagation(
                                        navigate("/home-search")
                                    )
                                }} data-bs-dismiss="offcanvas" aria-label="Close"><i className="icon icon-search"></i>Search</NavLink>
                        </div>
                        <div className="mb-notice">
                            <NavLink to="/contact" className="text-need" onClick={(e) => {
                                    e.stopPropagation(
                                        navigate("/contact")
                                    )
                                }} data-bs-dismiss="offcanvas" aria-label="Close">Contact us</NavLink>
                        </div>
                        <ul className="mb-info">
                            <li>{footer.address}</li>
                            <li>Email: <b>{footer.email?footer.email:"demo@gmail.com"}</b></li>
                            <li>Phone: <b>{footer.phone?footer.phone:9999999999}</b></li>
                        </ul>
                    </div>
                    <div className="mb-bottom">
                        <NavLink to={isLoggedIn ? "/account/my-account-dashboard" : '/login'} className="site-nav-icon" data-bs-dismiss="offcanvas" aria-label="Close">
                        {
                            isLoggedIn ? 
                            <div className="user-block" onClick={(e) => {
                                e.stopPropagation(
                                    navigate("/account/my-account-dashboard")
                                )
                            }}>
                                <Avatar sx={{bgcolor: deepOrange[500]}} >{user?.name?.slice(0, 1)}</Avatar>
                                <span className="user-name">{user?.name}</span>
                            </div> : <><i className="icon icon-account"></i>Login</>
                        }
                        </NavLink>
                    </div>
                </div>
               
            </div>       
        </div>
    )
}
