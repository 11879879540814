import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { API_URL } from "../../gobal/Global";

export default function ContactForm() {
    // Define validation schema using Yup
    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        subject: Yup.string().required("Subject is required"),
        message: Yup.string().required("Message is required"),
    });

    // Use Formik
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
        validationSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                const response = await fetch(`${API_URL}/send-contact-message`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                });

                if (response.ok) {
                    const result = await response.json();
                    showConfirm(result.message || "Message sent successfully!");
                    resetForm(); // Reset form after successful submission
                } else {
                    const error = await response.json();
                    showError(error.message || "Failed to send message!");
                }
            } catch (error) {
                showError("An error occurred. Please try again.");
            } finally {
                setSubmitting(false); // Reset the submitting state
            }
        },
    });

    const showConfirm = (message) => {
        Swal.fire({
            title: 'Success',
            icon: 'success',
            text: message,
            confirmButtonText: 'OK',
        });
    };

    const showError = (message) => {
        Swal.fire({
            title: 'Error',
            icon: 'error',
            text: message,
            confirmButtonText: 'OK',
        });
    };

    return (
        <section className="bg_grey-7 flat-spacing-9">
            <div className="container">
                <div className="flat-title">
                    <span className="title">Get in Touch</span>
                    <p className="sub-title text_black-2">If you’ve got great products you're making or looking to work with us, then drop us a line.</p>
                </div>
                <div>
                    <form className="mw-705 mx-auto text-center form-contact" onSubmit={formik.handleSubmit}>
                        <div className="d-flex gap-15 mb_15">
                            <div className="w-100">
                                <input
                                    type="text"
                                    placeholder="Name *"
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <p className="text-danger mb-3">{formik.errors.name}</p>
                                )}
                            </div>
                            <div className="w-100">
                                <input
                                    type="email"
                                    placeholder="Email *"
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <p className="text-danger mb-3">{formik.errors.email}</p>
                                )}
                            </div>
                        </div>
                        <div className="d-flex gap-15 mb_15">
                            <div className="w-100">
                                <input
                                    type="text"
                                    placeholder="Subject *"
                                    {...formik.getFieldProps('subject')}
                                />
                                {formik.touched.subject && formik.errors.subject && (
                                    <p className="text-danger mb-3">{formik.errors.subject}</p>
                                )}
                            </div>
                        </div>
                        <div className="mb_15">
                            <textarea
                                placeholder="Message"
                                cols="30"
                                rows="10"
                                {...formik.getFieldProps('message')}
                            ></textarea>
                            {formik.touched.message && formik.errors.message && (
                                <p className="text-danger mb-3">{formik.errors.message}</p>
                            )}
                        </div>
                        <div className="send-wrap">
                            <button
                                type="submit"
                                className="tf-btn radius-3 btn-fill animate-hover-btn justify-content-center"
                                disabled={formik.isSubmitting}
                            >
                                {formik.isSubmitting ? 'Sending...' : 'Send'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}
