import React, { useEffect, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { fetchOrders } from '../../../redux/slice/orderSlice';
import DateFormat from '../../../utils/DateFormat';
import { currency } from '../../../Currency';
import { statuses } from '../../../gobal/Global';
import { fetchCompleteOrders, fetchDeclinedOrders, fetchPendingOrders } from '../../../redux/slice/dashboardSlice';


export default function OrderList() {

    const { type } = useParams();
    const dispatch = useDispatch();
    const { pendingOrders, completeOrders, declinedOrders, loading } = useSelector((state) => state.dashboard);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (type === 'pending') dispatch(fetchPendingOrders(1));
        if (type === 'complete') dispatch(fetchCompleteOrders(1));
        if (type === 'declined') dispatch(fetchDeclinedOrders(1));
    }, [type, dispatch]);
    

    const orders =
        type === 'pending'
            ? pendingOrders.data
            : type === 'complete'
            ? completeOrders.data
            : declinedOrders.data;


    const handlePageChange = (page) => {
        if (page !== currentPage) {
            setCurrentPage(page);
        }
    };
        

    if(loading) {
        return <Spinner animation='border'/>
    }

    const showPagination = orders?.pagination?.last_page > 1;

    
    return (
        <React.Fragment>
            <Col lg="9">
                <div className="my-account-content account-order">
                    <div className="wrap-account-order">
                        <table>
                            <thead>
                                <tr>
                                    <th className="fw-6">Order</th>
                                    <th className="fw-6">Date</th>
                                    <th className="fw-6">Status</th>
                                    <th className="fw-6">Total</th>
                                    <th className="fw-6">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {   
                                orders?.data?.length > 0 ? (
                                    orders.data.map((data) => (
                                        <tr key={`orders-${data.id}`} className="tf-order-item">
                                            <td>#{data.order_id}</td>
                                            <td>{DateFormat(data.created_at)}</td>
                                            <td>
                                                <span className="text-capitalize">
                                                    {statuses.find((status) => status.id === data.order_status)?.name}
                                                </span>
                                            </td>
                                            <td>
                                                {currency.icon} {data.total_amount.toFixed(2)} for {data.product_qty} items
                                            </td>
                                            <td>
                                                <NavLink
                                                    to={`/account/my-account-orders-details/${data.order_id}`}
                                                    className="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center"
                                                >
                                                    <span>View</span>
                                                </NavLink>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No orders found.</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination Controls */}
                    {showPagination && (
                        <ul className="tf-pagination-wrap tf-pagination-list">
                            {/* Previous Page */}
                            <li>
                                <button
                                    className="pagination-link"
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    &laquo; Previous
                                </button>
                            </li>
                            {/* Page Numbers */}
                            {Array.from({ length: orders.pagination.last_page }, (_, i) => (
                                <li key={i + 1}>
                                    <button
                                        className={`pagination-link ${currentPage === i + 1 ? 'active' : ''}`}
                                        onClick={() => handlePageChange(i + 1)}
                                    >
                                        {i + 1}
                                    </button>
                                </li>
                            ))}
                            {/* Next Page */}
                            <li>
                                <button
                                    className="pagination-link"
                                    disabled={currentPage === orders.pagination.last_page}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    Next &raquo;
                                </button>
                            </li>
                        </ul>
                    )}
                </div>
            </Col>
        </React.Fragment>
    )
}
