import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, fetchWishlist, removeFromWishlist } from '../redux/slice/wishlistSlice';
import { showConfirm, showError } from '../gobal/sweetalertManage';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useWishlist = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { wishlist, loading } = useSelector(state => state.wishlist);
    const { isLoggedIn } = useSelector(state => state.auth);

    // Fetch wishlist items when the user is logged in
    const fetchWishlistItems = useCallback(() => {
        if (isLoggedIn) {
            dispatch(fetchWishlist());
        } else {
            // navigate("/login");
        }
    }, [isLoggedIn, dispatch, navigate]);

    // Check if a product is already in the wishlist
    const isProductInWishlist = (productId) => {
        const wishlistItem = wishlist?.find(item => item.product_id === productId);
        return wishlistItem ? wishlistItem.id : null;
    };

    // Add product to wishlist
    const handleAddToWishlist = async (productId) => {
        try {
            if (!isLoggedIn) {
                navigate('/login');
                return;
            }

            const existingWishlistItem = isProductInWishlist(productId);
            if (existingWishlistItem) {
                showError("Product already in wishlist");
                return;
            }

            await dispatch(addToWishlist(productId)).unwrap();
            showConfirm("Added to wishlist successfully");
            dispatch(fetchWishlist()); // Refresh wishlist
        } catch (error) {
            showError(error.message || "Failed to add to wishlist");
        }
    };

    // Remove product from wishlist
    const handleRemoveFromWishlist = async (productId) => {
        try {
            if (!isLoggedIn) {
                navigate('/login');
                return;
            }

            const wishlistItemId = isProductInWishlist(productId);
            if (!wishlistItemId) {
                showError("Product not found in wishlist");
                return;
            }

            await dispatch(removeFromWishlist(wishlistItemId)).unwrap();
            toast.success("Removed from wishlist successfully");
            dispatch(fetchWishlist()); // Refresh wishlist
        } catch (error) {
            toast.error(error.message || "Failed to remove from wishlist");
        }
    };

    // Toggle product between wishlist and non-wishlist state
    const handleWishlistToggle = (productId) => {
        const existingWishlistItem = isProductInWishlist(productId);
        if (existingWishlistItem) {
            handleRemoveFromWishlist(productId);
        } else {
            handleAddToWishlist(productId);
        }
    };

    // Fetch wishlist items on mount if logged in
    useEffect(() => {
        fetchWishlistItems();
    }, [fetchWishlistItems]);

    return {
        wishlist,
        loading,
        isProductInWishlist,
        handleWishlistToggle,
        handleAddToWishlist,
        handleRemoveFromWishlist,
        fetchWishlistItems,
    };
};
