import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../gobal/AxiosSetting';


// Async action to fetch addresses
export const fetchAddresses = createAsyncThunk(
    'address/fetchAddresses',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get('user/address');
            // console.log("Address__:", response.data);
            return response.data; // Adjust based on actual response structure
        } catch (error) {
            // console.log("Address_error__:", error);
            return rejectWithValue(error.response.data);
        }
    }
);



// Create the address slice
const addressSlice = createSlice({
    name: 'address',
    initialState: {
        addresses: [],
        loading: false,
        error: null,
        successMessage: null,
    },
    reducers: {
        clearSuccessMessage: (state) => {
            state.successMessage = null;
        },
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
        // Fetch Addresses
        .addCase(fetchAddresses.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchAddresses.fulfilled, (state, action) => {
            state.addresses = action.payload;
            state.loading = false;
        })
        .addCase(fetchAddresses.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.notification;
        })
    },
});
  

// Export actions and reducer
export const { clearSuccessMessage, clearError } = addressSlice.actions;
export default addressSlice.reducer;