import React, { useState } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';
import { showConfirm, showError } from '../../gobal/sweetalertManage';

export default function ForgotPage() {

    const navigate = useNavigate();

    const { 
        register, 
        handleSubmit, 
        formState: { errors }, 
        reset, 
        setValue 
    } = useForm();
    const[submitting, setSubmitting] = useState(false);


    const onSubmit = async (data) => {
        setSubmitting(true);
        try {
            const response = await axios.post(`${API_URL}/send-forget-password`, { email: data.email });
            showConfirm(response?.data?.notification || 'Password reset email sent successfully. Please check your inbox.');
            navigate("/verify-reset-password");
            reset(); // Reset the form after successful submission
        } catch (error) {
            const errorMessage = error.response?.data?.notification || 'Failed to send password reset email. Please try again.';
            showError(errorMessage);
        }finally{
            setSubmitting(false);
        }
    }

    return (
    <React.Fragment>
        <InnerBanner title="Password Reset"/>

        <section className="flat-spacing-10">
                <div className="container">
                    <div className="tf-grid-layout lg-col-2 tf-login-wrap">
                        <div className="tf-login-form">
                            <div >
                                <h5 className="mb_24">Reset your password</h5>
                                <p className="mb_30">We will send you an email to reset your password</p>
                                <div>
                                    <form  onSubmit={handleSubmit(onSubmit)} id="login-form" >
                                        <div className="tf-field style-1 mb_15">
                                            <input className="tf-field-input tf-input" 
                                                type="email" 
                                                id="property3" 
                                                {...register('email', {
                                                    required: 'Email is required',
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                        message: 'Invalid email address'
                                                    }
                                                })}
                                            />
                                            <label className="tf-field-label fw-4 text_black-2" htmlFor="property3">Email *</label>
                                            {errors.email && <span className="text-danger">{errors.email.message}</span>}
                                        </div>
                                        <div className="mb_20">
                                            <NavLink to="/login" className="tf-btn btn-line">Cancel</NavLink>
                                        </div>
                                        <div className="">
                                            <button type="submit" className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center">{ submitting ? "Resetting.." : "Reset password" }</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="tf-login-content">
                            <h5 className="mb_36">Welcome Back to Apoorva Products!</h5>
                            <p className="mb_20">Explore a wide range of premium products tailored for your needs. Login to manage your orders, access exclusive offers, and more.</p>
                            <NavLink to="/login" className="tf-btn btn-line">Login<i className="icon icon-arrow1-top-left"></i></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        
    </React.Fragment>
  )
}
