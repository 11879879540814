import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../gobal/AxiosSetting';
import { API_URL } from '../../gobal/Global';


// Thunks for fetching orders
export const fetchPendingOrders = createAsyncThunk(
  'dashboard/fetchPendingOrders',
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/user/pending-order?page=${page}`);
      return response.data.orders;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchCompleteOrders = createAsyncThunk(
  'dashboard/fetchCompleteOrders',
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/user/complete-order?page=${page}`);
      return response.data.orders;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchDeclinedOrders = createAsyncThunk(
  'dashboard/fetchDeclinedOrders',
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/user/declined-order?page=${page}`);
      return response.data.orders;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    pendingOrders: {
      data: [],
      loading: false,
      error: null,
    },
    completeOrders: {
      data: [],
      loading: false,
      error: null,
    },
    declinedOrders: {
      data: [],
      loading: false,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // Pending Orders
    builder
      .addCase(fetchPendingOrders.pending, (state) => {
        state.pendingOrders.loading = true;
        state.pendingOrders.error = null;
      })
      .addCase(fetchPendingOrders.fulfilled, (state, action) => {
        state.pendingOrders.loading = false;
        state.pendingOrders.data = action.payload;
      })
      .addCase(fetchPendingOrders.rejected, (state, action) => {
        state.pendingOrders.loading = false;
        state.pendingOrders.error = action.payload;
      });

    // Complete Orders
    builder
      .addCase(fetchCompleteOrders.pending, (state) => {
        state.completeOrders.loading = true;
        state.completeOrders.error = null;
      })
      .addCase(fetchCompleteOrders.fulfilled, (state, action) => {
        state.completeOrders.loading = false;
        state.completeOrders.data = action.payload;
      })
      .addCase(fetchCompleteOrders.rejected, (state, action) => {
        state.completeOrders.loading = false;
        state.completeOrders.error = action.payload;
      });

    // Declined Orders
    builder
      .addCase(fetchDeclinedOrders.pending, (state) => {
        state.declinedOrders.loading = true;
        state.declinedOrders.error = null;
      })
      .addCase(fetchDeclinedOrders.fulfilled, (state, action) => {
        state.declinedOrders.loading = false;
        state.declinedOrders.data = action.payload;
      })
      .addCase(fetchDeclinedOrders.rejected, (state, action) => {
        state.declinedOrders.loading = false;
        state.declinedOrders.error = action.payload;
      });
  },
});

export default dashboardSlice.reducer;
