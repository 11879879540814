import React from 'react';
import { NavLink } from 'react-router-dom';
import {useSelector} from "react-redux";
import {BASE_URL} from "../../gobal/Global";

export default function Footer() {
    const {footer} = useSelector((state) => state.setup);
    const {socialLinks} = useSelector((state) => state.setup);
    const {setting} = useSelector((state) => state.setup);

// console.log(socialLinks)
    return (
      <footer id="footer" className="footer md-pb-70">
            <div className="footer-wrap">
                <div className="footer-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-md-6 col-12">
                                <div className="footer-infor">
                                    <div className="footer-logo">
                                        <NavLink to="/">
                                            <img src={ BASE_URL + "/" + setting.logo } alt="logo"/>
                                        </NavLink>
                                    </div>
                                    <ul>
                                        <li>
                                            {/*<p>SCO 32, GF, near ICICI Bank Commercial Complex, HSIIDC, Sector 53, <br /> Sonipat - 131028</p>*/}
                                            <p>{footer.address}</p>

                                        </li>
                                        <li>
                                            <p>Email: <NavLink to={`mail:${footer.email}`}>{footer.email ? footer.email: "demo@gmail.com"}</NavLink></p>
                                        </li>
                                        <li>
                                            <p>Phone: <NavLink to={`telto:${footer.phone}`}>{footer.phone?footer.phone:9999999999}</NavLink> </p>
                                        </li>
                                    </ul>
                                    <NavLink to="/contact" className="tf-btn btn-line">Get direction<i className="icon icon-arrow1-top-left"></i></NavLink> 
                                    <ul className="tf-social-icon d-flex gap-10">
                                        {socialLinks.map((item, i) => {
                                            // console.log(item)
                                            return (<li key={i}><NavLink to={item.link} target={"_blank"}
                                                         className={"box-icon w_34 round social-facebook social-line"}><i
                                                className={item.icon}></i></NavLink></li>)
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 col-12 footer-col-block">
                                <div className="footer-heading footer-heading-desktop">
                                    <h6>Help</h6>
                                </div>
                                <div className="footer-heading footer-heading-moblie">
                                    <h6>Help</h6>
                                </div>
                                <ul className="footer-menu-list tf-collapse-content">
                                    <li>
                                        <NavLink to="/privacy-policy" className="footer-menu_item">Privacy Policy</NavLink> 
                                    </li>
                                    <li> 
                                        <NavLink to="/delivery-return" className="footer-menu_item">  Returns + Exchanges </NavLink> 
                                    </li>
                                    <li> 
                                        <NavLink to="/shipping-policy" className="footer-menu_item">Shipping</NavLink> 
                                    </li>
                                    <li> 
                                        <NavLink to="/terms-conditions" className="footer-menu_item">Terms &amp; Conditions</NavLink> 
                                    </li>
                                    <li> 
                                        <NavLink to="/faq" className="footer-menu_item">FAQ’s</NavLink> 
                                    </li>
                                    <li> 
                                        <NavLink to="/wishlist" className="footer-menu_item">My Wishlist</NavLink> 
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-3 col-md-6 col-12 footer-col-block">
                                <div className="footer-heading footer-heading-desktop">
                                    <h6>About us</h6>
                                </div>
                                <div className="footer-heading footer-heading-moblie">
                                    <h6>About us</h6>
                                </div>
                                <ul className="footer-menu-list tf-collapse-content">
                                    <li>
                                        <NavLink to="/about" className="footer-menu_item">Our Story</NavLink> 
                                    </li>
                                    <li> 
                                        <NavLink to="/contact" className="footer-menu_item">Contact Us</NavLink> 
                                    </li>
                                    <li> 
                                        <NavLink to="/account/my-account-dashboard" className="footer-menu_item">Account</NavLink> 
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-3 col-md-6 col-12">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.2146263454815!2d77.1229752348877!3d28.871045399999986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390daba2b6255555%3A0xea3993432e12c132!2sSoftech%20Solutions!5e1!3m2!1sen!2sin!4v1734089044177!5m2!1sen!2sin" width="100%" height="100%" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </footer>
    )
}
