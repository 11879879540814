import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from "../../gobal/Global";

// Define the initial state for home data
const initialState = {
    aboutUs: {},
    testimonials: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

// Create async thunk for fetching home data
export const fetchAboutData = createAsyncThunk('about/fetchAboutData', async () => {
    const response = await axios.get(`${API_URL}/about-us`);
    return response.data;
});

// Create the slice
const aboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAboutData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAboutData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const data = action.payload;

                // Update state with the fetched data

                state.aboutUs = data.aboutUs || {};
                state.testimonials = data.testimonials || [];
            })
            .addCase(fetchAboutData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Export the async thunk and the reducer
export default aboutSlice.reducer;
