import React, { useEffect } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogData } from '../../redux/slice/blogSlice';
import { BASE_URL } from '../../gobal/Global';
import { NavLink } from 'react-router-dom';
import Preloader from '../include/Preloader';

export default function Blog() {

    const dispatch = useDispatch();
    const { blogs, loading, seoSetting, status, error } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(fetchBlogData());
    },[]);

    // console.log(blogs);

    if(loading){
        return <Preloader/>
    }

    return (
        <React.Fragment>
            <InnerBanner title="Blog"/>

            {/* blog-grid-main */}
            <div className="blog-grid-main">
                <Container>
                    <Row>
                    {
                        blogs.map((data, index) => (
                            <Col key={`blog-${index}`} xl="4" md="6" sm="12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <NavLink to={`blog-detail/${data.slug}`}>
                                            <img className="lazyload" data-src={`${BASE_URL}/${data.image}`} src={`${BASE_URL}/${data.image}`} alt="img-blog"/>
                                        </NavLink>
                                        <div className="article-label">
                                            <NavLink to={`/blog-detail/${data.slug}`} className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</NavLink>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <NavLink to={`/blog-detail/${data.slug}`} className="">{data.title}</NavLink>
                                        </div>
                                        <div className="article-btn">
                                            <NavLink to={`/blog-detail/${data.slug}`}  className="tf-btn btn-line fw-6">Read more<i className="icon icon-arrow1-top-left"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
