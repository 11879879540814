import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../gobal/AxiosSetting';
import { API_URL } from '../../gobal/Global';

// Async thunk for fetching coupon data
export const fetchCouponData = createAsyncThunk('coupon/fetchCouponData', async () => {
    const response = await axiosInstance.get(`${API_URL}/coupon`);
    return response.data?.data;
});


// Async thunk for applying a coupon
export const applyCoupon = createAsyncThunk(
    'coupon/applyCoupon',
    async (couponCode, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/apply-coupon?coupon=${couponCode}`);
            return response.data; // Ensure this matches the expected data structure
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Failed to apply coupon');
        }
    }
);

// Create the coupon slice
const couponSlice = createSlice({
    name: 'coupon',
    initialState: {
        appliedCoupon: null,  // Store coupon data as an object
        error: null,
        loading: false,
    },
    reducers: {
        clearCoupon: (state) => {
            state.appliedCoupon = null;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCouponData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCouponData.fulfilled, (state, action) => {
                state.loading = false;
                state.appliedCoupon = action.payload; // Store the coupon data
            })
            .addCase(fetchCouponData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch coupon data';
            })
            .addCase(applyCoupon.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(applyCoupon.fulfilled, (state, action) => {
                state.loading = false;
                state.appliedCoupon = action.payload; // Update state with the applied coupon
            })
            .addCase(applyCoupon.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to apply coupon';
            });
    },
});


// Export actions and reducer
export const { clearCoupon } = couponSlice.actions;
export default couponSlice.reducer;
