import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';

// Async Thunk for Reset Password
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/reset-password/${token}`);
      return response.data; // Expected response as per API
    } catch (error) {
      const errorMessage =
        error.response?.data?.notification || 'Failed to reset the password. Please try again.';
      return rejectWithValue(errorMessage);
    }
  }
);

// Initial State
const initialState = {
  user: null,
  banner: null,
  recaptchaSetting: null,
  loading: false,
  error: null,
};

// Redux Slice
const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    clearResetPasswordState: (state) => {
      state.user = null;
      state.banner = null;
      state.recaptchaSetting = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.banner = action.payload.banner;
        state.recaptchaSetting = action.payload.recaptchaSetting;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set error message
      });
  },
});

// Export Actions and Reducer
export const { clearResetPasswordState } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
