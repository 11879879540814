import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { BASE_URL } from '../../gobal/Global';
import { fetchShowOrder } from '../../redux/slice/orderShowSlice';
import DateFormat from '../../utils/DateFormat';
import { Spinner } from 'react-bootstrap';
import { currency } from '../../Currency';

export default function Invoice() {

    const { order_id } = useParams();
    const dispatch = useDispatch();
    const { setting } = useSelector(state => state.setup);
    const { order, loading, error } = useSelector((state) => state.order);

    useEffect(() => {
        dispatch(fetchShowOrder(order_id)); // Fetch the first page of orders
    }, []);

    console.log("Orders",order);
    
    const handlePrint = () => {
        window.print();
    };

    if(loading) {
        return <Spinner/>
    }

    // console.log("Order__",order);

    return (
                    <section className='invoice-section'>
                        <div className="cus-container2">
                            <div className="top">
                                <button onClick={handlePrint} className="tf-btn btn-fill animate-hover-btn">
                                    Print this invoice
                                </button>
                            </div>
                            <div className="box-invoice">
                                <div className="header">
                                    <div className="wrap-top">
                                        <div className="box-left">
                                            <NavLink to="/">
                                                <img src={ setting ? `${BASE_URL}/${setting.logo}` : "assets/images/logos/apoorva_logo.png"} alt="logo" className="logo" style={{width: "150px"}}/>
                                            </NavLink>
                                        </div>
                                        <div className="box-right">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="title">Invoice #</div>
                                                <span className="code-num">{order_id}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap-date">
                                        <div className="box-left">
                                            <label for="">Invoice date:</label>
                                            <span className="date">{DateFormat(order?.created_at)}</span>
                                        </div>
                                        {/* <div className="box-right">
                                            <label for="">Due date:</label>
                                            <span className="date">03/10/2024</span>
                                        </div> */}
                                    </div>
                                    <div className="wrap-info">
                                        <div className="box-left">
                                            <div className="title">Payment Method</div>
                                            <div className="sub">{order?.payment_method}</div>
                                            <p className="desc">329 Queensberry Street, North Melbourne <br/> VIC 3051, Australia.</p>
                                        </div>
                                        <div className="box-right">
                                            <div className="title">Customer</div>
                                            <div className="sub">{order?.order_address?.billing_name}</div>
                                            <p className="desc">{order?.order_address?.billing_address}, {order?.order_address?.billing_city}, <br /> {order?.order_address?.billing_state}, {order?.order_address?.billing_country}</p>
                                        </div>
                                    </div>
                                    <div className="wrap-table-invoice">
                                        <table className="invoice-table">
                                            <thead>
                                                <tr className="title">
                                                    <th>Description</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                order?.order_products?.map((data, index) => (
                                                <tr key={`product-${index}`} className="content">
                                                    <td>{data.product_name}</td>
                                                    <td>{setting.currency_icon} {data?.unit_price?.toFixed(2)}</td>
                                                    <td>{data.qty}</td>
                                                    <td>{setting?.currency_icon} {(data?.unit_price*data.qty)?.toFixed(2)}</td>
                                                </tr>
                                                ))
                                            }
                                                <tr className="content">
                                                    <td colSpan={3}>Shipping Cost</td>
                                                    <td>{setting?.currency_icon}{order?.shipping_cost?.toFixed(2)}</td>
                                                </tr>
                                                <tr className="content text-success">
                                                    <td colSpan={3}>Discount</td>
                                                    <td>-{setting?.currency_icon}{order?.coupon_coast?.toFixed(2)}</td>
                                                </tr>
                                                <tr className="content">
                                                    <td colSpan={3} className="total">Total Due</td>
                                                    <td className="total">{setting?.currency_icon}{order?.total_amount?.toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                                <div className="footer">
                                    <ul className="box-contact">
                                        <li>www.apoorvaproducts.com</li>
                                        <li>{setting?.topbar_email}</li>
                                        <li>{setting?.topbar_phone}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
    )
}
