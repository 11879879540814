import React from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearAuth } from '../../../redux/slice/authSlice';
import InnerBanner from '../../elements/InnerBanner';
import TabRouting from './TabRouting';

export default function TabLayout() {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(clearAuth());
        navigate('/login');
    };

    return (
        <React.Fragment>
            <InnerBanner title="Account" />
            <section className="flat-spacing-11">
                <div className="container">
                    <div className="row">
                        {/* Left Sidebar for Navigation */}
                        <div className="col-lg-3">
                            <ul className="my-account-nav nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <li className=" nav-item">
                                    <NavLink to="my-account-dashboard" className="my-account-nav-item" activeClassName="active">
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li className="nav-item"></li>
                                <li className=" nav-item">
                                    <NavLink to="my-account-orders" className="my-account-nav-item" activeClassName="active">
                                        Orders
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="my-account-address" className="my-account-nav-item" activeClassName="active">
                                        Address
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="my-account-edit" className="my-account-nav-item" activeClassName="active">
                                        Password Change
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/login" onClick={() => handleLogout()} className="my-account-nav-item">
                                        Logout
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        {/* Content area for the selected tab */}
                        <div className="col-lg-9">
                            <div className="tab-content" id="v-pills-tabContent">
                                <TabRouting/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
