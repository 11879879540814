import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../gobal/Global';
import axiosInstance from '../../gobal/AxiosSetting';


// Define the initial state for home data
const initialState = {
    delivery: [],  // Store blogs
    city: [], // Store a single blog
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    loading: false,
    error: null,
};


// Create async thunk for fetching home data
export const fetchDeliveryData = createAsyncThunk('delivery/fetchDeliveryData', async () => {
    const response = await axiosInstance.get(`${API_URL}/user/delivery`);
    return response.data;
});


const deliverySlice = createSlice({
    name: 'delivery',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDeliveryData.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchDeliveryData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;
                const data = action.payload;

                // Update state with the fetched blog and SEO data
                state.delivery = data || []; // Assign delivery data
                state.city = data.city || []; // Assign City data
            })
            .addCase(fetchDeliveryData.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export default deliverySlice.reducer;
