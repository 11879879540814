// store/index.js
import { configureStore } from '@reduxjs/toolkit';
import categorySlice from '../slice/categorySlice';
import productSlice from '../slice/productSlice';
import AuthSlice from '../slice/authSlice';
import setupSlice from "../slice/setupSlice";
import aboutSlice from "../slice/aboutSlice";
import contactSlice from "../slice/contactSlice";
import homeSlice from "../slice/homeSlice";
import productDetailsSlice from "../slice/productDetailsSlice";
import blogSlice from '../slice/blogSlice';
import privacyPolicySlice from '../slice/policySlice';
import wishlistSlice from '../slice/wishlistSlice';
import updatePasswordSlice from '../slice/updatePasswordSlice';
import addToCartSlice from '../slice/addToCartSlice';
import locationSlice from '../slice/Location';
import faqSlice from '../slice/faqSlice';
import checkoutSlice from '../slice/checkoutSlice';
import addressSlice from '../slice/addresSlice';
import orderSlice from '../slice/orderSlice';
import orderShowSlice from '../slice/orderShowSlice';
import dashboardSlice from '../slice/dashboardSlice';
import deliverySlice from '../slice/deliverySlice';
import resetPasswordSlice from '../slice/resetPasswordSlice';
import couponSlice from '../slice/couponSlice';
import cancelOrderSlice from '../slice/cancelOrderSlice';
import flashSaleSlice from '../slice/flashSaleSlice';
import termsConditionSlice from '../slice/termConditionSlice';



const store = configureStore({
    reducer: {
        home: homeSlice,
        auth: AuthSlice,
        addToCart: addToCartSlice,
        address: addressSlice,
        blog: blogSlice,
        checkout: checkoutSlice,
        categoryList: categorySlice,
        cancelOrder: cancelOrderSlice,
        coupon: couponSlice,
        dashboard: dashboardSlice,
        delivery: deliverySlice,
        faq: faqSlice,
        flashSale: flashSaleSlice,
        product: productSlice,
        orders: orderSlice,
        order: orderShowSlice,
        productDetail: productDetailsSlice,
        about: aboutSlice,
        contact: contactSlice,
        location: locationSlice,
        setup: setupSlice,
        termsConditions: termsConditionSlice,
        resetPassword: resetPasswordSlice,
        privacyPolicy: privacyPolicySlice,
        wishlist: wishlistSlice,
        updatePassword: updatePasswordSlice,
    },
});

export default store;
