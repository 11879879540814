import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../gobal/Global';


// Define the initial state for home data
const initialState = {
    blogs: [],  // Store blogs
    singleBlog: {}, // Store a single blog
    seoSetting: {}, // Store SEO settings
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    loading: false,
    error: null,
};

// Create async thunk for fetching home data
export const fetchBlogData = createAsyncThunk('blog/fetchBlogData', async () => {
    const response = await axios.get(`${API_URL}/blog`);
    return response.data;
});

// Create async thunk for fetching a single blog by slug
export const fetchSingleBlog = createAsyncThunk('singleBlog/fetchSingleBlog', async (slug) => {
    const response = await axios.get(`${API_URL}/blog/${slug}`);
    console.log("SIngleBlog__", response.data);
    return response.data; // Return the single blog data
});


// Create the slice
const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogData.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchBlogData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;
                const data = action.payload;

                // Update state with the fetched blog and SEO data
                state.blogs = data.blogs.data || []; // Assign blog data
                state.seoSetting = data.seoSetting || {}; // Assign SEO setting data
            })
            .addCase(fetchBlogData.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            })

             // Handling fetch single blog by slug
             .addCase(fetchSingleBlog.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchSingleBlog.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;
                

                // Update state with the fetched single blog
                state.singleBlog = action.payload;
            })
            .addCase(fetchSingleBlog.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            });

    },
});

// Export the async thunk and the reducer
export default blogSlice.reducer;