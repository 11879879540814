import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from "../../gobal/Global";

// Define the initial state for product details
const initialState = {
  product: {},
  gallery: [],
  tags: "",
  totalProductReviewQty: 0,
  totalReview: 0,
  productReviews: [],
  specifications: [],
  recaptchaSetting: {},
  relatedProducts: [],
  defaultProfile: {},
  is_seller_product: false,
  seller: null,
  sellerTotalProducts: 0,
  this_seller_products: [],
  sellerReviewQty: 0,
  sellerTotalReview: 0,
  status: 'idle', // Status can be 'idle', 'loading', 'succeeded', or 'failed'
  loading: true, // Set to true by default to indicate loading state
  error: null, // Error message, if any
};

// Create async thunk for fetching product details
export const fetchProductDetails = createAsyncThunk('productDetails/fetchProductDetails', async (slug) => {
  const response = await axios.get(`${API_URL}/product/${slug}`);
  return response.data;
});

// Create the slice
const productDetailsSlice = createSlice({
  name: 'productDetails',
  initialState,
  reducers: {
    // Define the resetProductDetails action to reset the state to initial values
    resetProductDetails(state) {
      state.product = {};
      state.gallery = [];
      state.tags = "";
      state.totalProductReviewQty = 0;
      state.totalReview = 0;
      state.productReviews = [];
      state.specifications = [];
      state.recaptchaSetting = {};
      state.relatedProducts = [];
      state.defaultProfile = {};
      state.is_seller_product = false;
      state.seller = null;
      state.sellerTotalProducts = 0;
      state.this_seller_products = [];
      state.sellerReviewQty = 0;
      state.sellerTotalReview = 0;
      state.status = 'idle';
      state.loading = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDetails.pending, (state) => {
        state.status = 'loading'; // Set loading state
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.status = 'succeeded'; // Data fetch succeeded
        const data = action.payload;
        state.loading = false; // Set loading state

        // Update state with the fetched data
        state.product = data.product || {};
        state.gallery = data.gallery || [];
        state.tags = data.tags || "";
        state.totalProductReviewQty = data.totalProductReviewQty || 0;
        state.totalReview = data.totalReview || 0;
        state.productReviews = data.productReviews || [];
        state.specifications = data.specifications || [];
        state.recaptchaSetting = data.recaptchaSetting || {};
        state.relatedProducts = data.relatedProducts || [];
        state.defaultProfile = data.defaultProfile || {};
        state.is_seller_product = data.is_seller_product || false;
        state.seller = data.seller || null;
        state.sellerTotalProducts = data.sellerTotalProducts || 0;
        state.this_seller_products = data.this_seller_products || [];
        state.sellerReviewQty = data.sellerReviewQty || 0;
        state.sellerTotalReview = data.sellerTotalReview || 0;
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        state.status = 'failed'; // Data fetch failed
        state.loading = false; // Set loading state
        state.error = action.error.message; // Capture error message
      });
  },
});

// Export the async thunk and the resetProductDetails action
export const { resetProductDetails } = productDetailsSlice.actions;

// Export the reducer
export default productDetailsSlice.reducer;
