import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../gobal/AxiosSetting';
import { API_URL } from '../../gobal/Global';


export const cancelOrder = createAsyncThunk(
    'order/cancelOrder',
    async (orderId, { rejectWithValue }) => { // Fixed typo
        try {
            const response = await axiosInstance.get(`${API_URL}/user/cancel-order/${orderId}`);
            console.log("Response CancelOrder__", response);
            return response.data; // Assuming response.data has the required structure
        } catch (error) {
            // Handle both structured error response and generic error
            return rejectWithValue(error.response?.data || { notification: error.message });
        }
    }
);



// Create the slice
const cancelOrderSlice = createSlice({
    name: "cancelOrder",
    initialState: {
        order: null,
        refundDetails: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(cancelOrder.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(cancelOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.order = action.payload.order;
                state.refundDetails = action.payload.refund_details;
            })
            .addCase(cancelOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.notification || 'An error occurred';
            });
    },
});


export default cancelOrderSlice.reducer;