import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from "../../gobal/Global";

const initialState = {
    faq: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
};

// Create async thunk for fetching FAQ data
export const fetchFaqData = createAsyncThunk('faq/fetchFaq', async () => {
    const response = await axios.get(`${API_URL}/faq`);
    // console.log("FAQ__:", response.data);
    return response.data;
});

// Create the FAQ slice
const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFaqData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFaqData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                
                // Update only the FAQ data
                state.faq = action.payload || [];
            })
            .addCase(fetchFaqData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default faqSlice.reducer;
