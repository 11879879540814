import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import {  useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import DashboardCard from '../../elements/dashboard/DashboardCard';
import { fetchCompleteOrders, fetchDeclinedOrders, fetchPendingOrders } from '../../../redux/slice/dashboardSlice';


export default function Dashboard() {

    const dispatch = useDispatch();
    const { user, loading } = useSelector(state => state.auth);
    const { pendingOrders, completeOrders, declinedOrders } = useSelector((state) => state.dashboard);



    // Load data based on current tab and page
    useEffect(() => {
        dispatch(fetchPendingOrders(1)); // Fetch the first page for counts
        dispatch(fetchCompleteOrders(1));
        dispatch(fetchDeclinedOrders(1));
    }, [dispatch]);

    if (loading) {
        return <Skeleton height={50} count={3} />;
    }


    return (
        <React.Fragment>
            <Col lg="9">
                <div className="my-account-content account-dashboard">
                    <div className="mb_60">
                        <h5 className="fw-5 mb_20">Hello {user ? user.name.split(' ')[0] : "User"}</h5>
                        
                    </div>
                </div>
                <Row>
                    <Col sm="12" md="4" lg="4">
                        <DashboardCard
                            title="Pending Orders"
                            count={pendingOrders?.data?.data?.length}
                            type="pending"
                            variant="warning"
                        />
                    </Col>
                    <Col sm="12" md="4" lg="4">
                        <DashboardCard
                            title="Complete Orders"
                            count={completeOrders?.data?.data?.length}
                            type="complete"
                            variant="success"
                        />
                    </Col>
                    <Col sm="12" md="4" lg="4">
                        <DashboardCard
                            title="Declined Orders"
                            count={declinedOrders?.data?.data?.length}
                            type="declined"
                            variant="danger"
                        />
                    </Col>
                </Row>
            </Col>
        </React.Fragment>
    )
}
