import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {BASE_URL} from "../../gobal/Global";
import {fetchSetupData} from "../../redux/slice/setupSlice";
import {fetchWishlist} from '../../redux/slice/wishlistSlice';
import {fetchAddToCart} from '../../redux/slice/addToCartSlice';
import {Helmet} from 'react-helmet';
import {Avatar} from '@mui/material';
import {deepOrange} from '@mui/material/colors';

export default function Header() {

    const [wishlistLength, setWishlistLength] = useState(0);
    const [cartLength, setCartLength] = useState(0);

    const dispatch = useDispatch();
    const {isLoggedIn} = useSelector(state => state.auth);
    const {setting, categories} = useSelector(state => state.setup);
    const {wishlist} = useSelector(state => state.wishlist);
    const {user} = useSelector(state => state.auth);
    const {addToCart} = useSelector(state => state.addToCart);

    const footer = useSelector((state) => {
        // console.log(state)
        return state.setup

    });

    useEffect(() => {
        dispatch(fetchSetupData());
        setTimeout(() => {
            
        }, 2000)
    }, [])

    // Update wishlist & cart count when `isLoggedIn` or `wishlist` & `cart` state changes
    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchWishlist()); // Fetch wishlist on login
            dispatch(fetchAddToCart()); // Fetch cartlist on login
        } else {
            setWishlistLength(0); // Clear wishlist count on logout
            setCartLength(0); // Clear cart count on logout
        }
    }, [isLoggedIn, dispatch]);


    useEffect(() => {
        if (isLoggedIn) {
            setWishlistLength(wishlist?.length);
            setCartLength(addToCart?.length);
        }
    }, [isLoggedIn, wishlist, addToCart]);


    return (
        <React.Fragment>
            <Helmet>
                {/* Favicon */}
                <link href={BASE_URL + setting.logo ? BASE_URL + "/" + setting.logo : "assets/images/logos/favicon.png"}
                      rel="icon"/>
            </Helmet>
            <header id="header" className="header-default">
                <div className="container-full px_15 lg-px_40">
                    <div className="row wrapper-header align-items-center">
                        <div className="col-md-4 col-3 tf-lg-hidden">
                            <NavLink to="#" data-bs-toggle="offcanvas" data-bs-target="#mobileMenu" role="button"
                                     aria-controls="offcanvasleft">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16"
                                     fill="none">
                                    <path
                                        d="M2.00056 2.28571H16.8577C17.1608 2.28571 17.4515 2.16531 17.6658 1.95098C17.8802 1.73665 18.0006 1.44596 18.0006 1.14286C18.0006 0.839753 17.8802 0.549063 17.6658 0.334735C17.4515 0.120408 17.1608 0 16.8577 0H2.00056C1.69745 0 1.40676 0.120408 1.19244 0.334735C0.978109 0.549063 0.857702 0.839753 0.857702 1.14286C0.857702 1.44596 0.978109 1.73665 1.19244 1.95098C1.40676 2.16531 1.69745 2.28571 2.00056 2.28571ZM0.857702 8C0.857702 7.6969 0.978109 7.40621 1.19244 7.19188C1.40676 6.97755 1.69745 6.85714 2.00056 6.85714H22.572C22.8751 6.85714 23.1658 6.97755 23.3801 7.19188C23.5944 7.40621 23.7148 7.6969 23.7148 8C23.7148 8.30311 23.5944 8.59379 23.3801 8.80812C23.1658 9.02245 22.8751 9.14286 22.572 9.14286H2.00056C1.69745 9.14286 1.40676 9.02245 1.19244 8.80812C0.978109 8.59379 0.857702 8.30311 0.857702 8ZM0.857702 14.8571C0.857702 14.554 0.978109 14.2633 1.19244 14.049C1.40676 13.8346 1.69745 13.7143 2.00056 13.7143H16.8577C17.1608 13.7143 17.4515 13.8346 17.6658 14.049C17.8802 14.2633 18.0006 14.554 18.0006 14.8571C18.0006 15.1602 17.8802 15.4509 17.6658 15.6653C17.4515 15.8796 17.1608 16 16.8577 16H2.00056C1.69745 16 1.40676 15.8796 1.19244 15.6653C0.978109 15.4509 0.857702 15.1602 0.857702 14.8571Z"
                                        fill="#0D0D0D"/>
                                </svg>
                            </NavLink>
                        </div>
                        <div className="col-xl-3 col-md-4 col-6">
                            <NavLink to="/" className="logo-header">
                                <img
                                    src={BASE_URL + setting.logo ? BASE_URL + "/" + setting.logo : "assets/images/logos/apoorva_logo.png"}
                                    alt="logo" className="logo"/>
                            </NavLink>
                        </div>
                        <div className="col-xl-6 tf-md-hidden">
                            <nav className="box-navigation text-center">
                                <ul className="box-nav-ul d-flex align-items-center justify-content-center gap-30">
                                    <li className="menu-item">
                                        <NavLink to="/" className="item-link">Home</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="about" className="item-link">About us</NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to="/product" className="item-link">Shop <i
                                            className="icon icon-arrow-down"></i></NavLink>
                                        <div className="sub-menu mega-menu">
                                            <div className="container">
                                                <div className="row">
                                                    {

                                                        categories.map((cat, index) => {
                                                            return cat.active_sub_categories.find((sub, index) => {
                                                                // console.log(sub,"sub")
                                                                return sub.products_count > 0
                                                            }) ? (
                                                                <div key={index} className="col-lg-2">
                                                                    <div className="mega-menu-item">
                                                                        <div className="menu-heading"> {cat.name}</div>
                                                                        <ul className="menu-list">
                                                                            {
                                                                                cat.active_sub_categories.map((sub, index) => {
                                                                                    // console.log(sub,"sub")
                                                                                    return sub.products_count > 0 ?
                                                                                        <li key={index}><NavLink
                                                                                            to={`/product/${cat.slug}/${sub.id}`}
                                                                                            className="menu-link-text link">{sub.name}</NavLink>
                                                                                        </li> : null
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="menu-item">
                                        <NavLink to="blog" className="item-link">Blog </NavLink>
                                    </li>
                                    <li className="menu-item"><NavLink to="contact" className="item-link">Contact us</NavLink></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-xl-3 col-md-4 col-3">
                            <ul className="nav-icon d-flex justify-content-end align-items-center gap-20">
                                <li className="nav-search"><NavLink to="#" data-bs-toggle="modal" data-bs-target="#canvaSearchModal"
                                                                    className="nav-icon-item"><i
                                    className="icon icon-search"></i></NavLink></li>
                                <li className="nav-account">
                                    <NavLink to={isLoggedIn ? '#' : '/login'}
                                             data-bs-toggle={isLoggedIn ? 'modal' : ''}
                                             data-bs-target={isLoggedIn ? '#model' : ''} className="nav-icon-item">
                                        {
                                            isLoggedIn ? <Avatar
                                                    sx={{bgcolor: deepOrange[500]}}>{user?.name?.slice(0, 1)}</Avatar> :
                                                <i className="icon icon-account"></i>
                                        }
                                    </NavLink>
                                </li>
                                <li className="nav-wishlist"><NavLink to="/wishlist" className="nav-icon-item"><i
                                    className="icon icon-heart"></i><span
                                    className="count-box">{wishlistLength || 0}</span></NavLink></li>
                                <li className="nav-cart"><NavLink to="/shoppingCart" data-bs-toggle="modal"
                                                                  data-bs-target="#shoppingCartModal"
                                                                  className="nav-icon-item"><i
                                    className="icon icon-bag"></i><span
                                    className="count-box">{cartLength || 0}</span></NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}
