import React from 'react';
import { NavLink } from 'react-router-dom';

export default function BreadCrumb({title}) {

  return (
        <div className="tf-breadcrumb">
            <div className="container">
                <div className="tf-breadcrumb-wrap d-flex justify-content-between flex-wrap align-items-center">
                    <div className="tf-breadcrumb-list">
                        <NavLink href="/" className="text">Home</NavLink>
                            <i className="icon icon-arrow-right"></i>
                        <NavLink to="/product" className="text">Products</NavLink>
                        <i className="icon icon-arrow-right"></i>
                        <span className="text">{title}</span>
                    </div>
                    
                </div>
            </div>
        </div>
  )
}
