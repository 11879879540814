import React from 'react'
import { NavLink } from 'react-router-dom'

export default function ShareSocial({ productUrl }) {

    // Sharing URLs for various platforms
    const socialLinks = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(productUrl)}`,
        twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(productUrl)}&text=Check+out+this+product!`,
        pinterest: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(productUrl)}`,
        linkedin: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(productUrl)}`,
        whatsapp: `https://api.whatsapp.com/send?text=Check+out+this+amazing+product!+${encodeURIComponent(productUrl)}`,
    };

    return (
        <div className="modal modalCentered fade modalDemo tf-product-modal modal-part-content" id="share_social">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="header">
                        <div className="demo-title">Share</div>
                        <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                    </div>
                    <div className="overflow-y-auto">
                        <ul className="tf-social-icon d-flex gap-10">
                            <li><NavLink to={socialLinks.facebook} className="box-icon social-facebook bg_line" target='_blank'><i className="icon icon-fb"></i></NavLink></li>
                            <li><NavLink to={socialLinks.twitter} className="box-icon social-twiter bg_line" target='_blank'><i className="icon icon-Icon-x"></i></NavLink></li>
                            <li><NavLink to={socialLinks.pinterest} className="box-icon social-pinterest bg_line" target='_blank'><i className="icon icon-pinterest-1"></i></NavLink></li>
                            <li><NavLink to={socialLinks.whatsapp} className="box-icon social-whatsapp bg_line" target='_blank'><i className="icon icon-whatsapp"></i></NavLink></li>
                        </ul>
                        <form className="form-share"  method="post" acceptCharset="utf-8">
                            <fieldset>
                                <input type="text" value={productUrl} tabIndex="0" aria-required="true"/>
                            </fieldset>
                            <div className="button-submit">
                                <button
                                    className="tf-btn btn-sm radius-3 btn-fill btn-icon animate-hover-btn"
                                    type="button"
                                    onClick={() => navigator.clipboard.writeText(productUrl)}
                                >
                                    Copy
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
