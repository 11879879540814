import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import { BASE_URL } from '../../../gobal/Global';
import { NavLink } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import '../.././css_files/slider.css';

export default function Slider({ sliders }) {
    const swiperRef = useRef(null);

    // Adjust breakpoints for responsive design
    const responsiveOptions = {
        0: { slidesPerView: 1 },       // Small screens
        768: { slidesPerView: 2 },     // Mid-sized screens
        1024: { slidesPerView: 3 },    // Large screens
    };

    // Function to trigger the "next" slide
    const handleNext = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    // Function to trigger the "prev" slide
    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <section className="tf-slideshow slider-effect-fade slider-accessories">
            <div className="swiper-container tf-sw-slideshow">
                <Swiper
                    modules={[Navigation, Autoplay]}
                    ref={swiperRef}
                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                    spaceBetween={10}
                    loop={true}
                    // breakpoints={responsiveOptions}
                    slidesPerView={1}
                >
                    {sliders.map((data, index) => (
                        <SwiperSlide key={`slide-${index}`} className="swiper-slide">
                            <div className="wrap-slider">
                                <img src={`${BASE_URL + "/" + data.image}`} alt={`${data.title}-slideshow`} className='slider-image'/>
                                <div className="box-content text-center">
                                    <div className="container">
                                        <h2 className="fade-item fade-item-1 fs-1 slider-h1">{data.title_one}</h2>
                                        <h2 className="fade-item fade-item-1 fs-4 slider-h2">{data.title_two}</h2>
                                        <p className="fade-item fade-item-2">{data.content}</p>
                                        <div className="fade-item fade-item-3">
                                            <NavLink to={`/product-detail/${data.product_slug}`} className="tf-btn btn-outline-dark fw-5 btn-xl radius-60">
                                                <span>Shop Now</span>
                                                <i className="icon icon-arrow-right"></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {/* Custom navigation controls */}
            <div className="container wrap-navigation">
                <div className="nav-sw style-white nav-prev-slider navigation-prev-slider box-icon w_46 round" onClick={handlePrev}>
                    <span className="icon icon-arrow-right"></span>
                </div>
                <div className="nav-sw style-white nav-next-slider navigation-next-slider box-icon w_46 round" onClick={handleNext}>
                    <span className="icon icon-arrow-left"></span>
                </div>
            </div>
        </section>
    );
}
