import React from 'react'
import { NavLink } from 'react-router-dom'

export default function InnerBanner({ title }) {
    return (
        <div className="tf-page-title style-2">
            <div className="container-full">
                <div className="heading text-center">{title}</div>
                <ul className="breadcrumbs d-flex align-items-center justify-content-center">
                    <li>
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                        <i className="icon-arrow-right"></i>
                    </li>
                    <li>{title}</li>
                </ul>
            </div>
        </div>
    )
}
