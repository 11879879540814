import { toast } from "react-toastify";
import axiosInstance from "../gobal/AxiosSetting";
import { API_URL } from "../gobal/Global";



export const checkPostalCode = async (postalCode) => {
    try {
        const response = await axiosInstance.post(`${API_URL}/user/delivery`, { postal_code: postalCode });
        
        const notification = response.data?.notification;

        if (response.status === 200 && notification) {
            return true; // Postal code is deliverable
        } else {
            toast.error(response?.data?.notification || "Your product will not be delivered to your selected address.");
            return false; // Postal code not deliverable
        }
    } catch (error) {
        // Extract the error message properly
        const errorMessage = error?.response?.data?.notification || "Your product will not be delivered to your selected address.";
        toast.error(errorMessage);
        return false; // Treat as non-deliverable in case of error
    }
};

