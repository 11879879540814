import React, { useEffect, useState } from 'react'
import { set, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddresses, submitAddress } from '../../redux/slice/addresSlice';
import { fetchCityByState, fetchCountryData, fetchStateByCountry } from '../../redux/slice/Location';
import { showConfirm, showError } from '../../gobal/sweetalertManage';
import axiosInstance from '../../gobal/AxiosSetting';

export default function AddressForm({ 
    isFormVisible, 
    setFormVisible, 
    editMode, 
    selectedAddress, 
    setEditMode,
    refreshAddresses,
}) {

    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const { country, state, city } = useSelector(state => state.location);

    // State to control form visibility
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [ isGstn, setIsGstn] = useState(false);

    // console.log("selectAdr", selectedAddress);

    useEffect(() => {

        // Fetch country data
        dispatch(fetchCountryData());

        // Populate form if editing an address
        if (editMode && selectedAddress) {
            setValue("name", selectedAddress.name);
            setValue("email", selectedAddress.email);
            setValue("phone", selectedAddress.phone);
            setValue("address", selectedAddress.address);
            setValue("gst_number", selectedAddress.gst_number);
            setValue("country", selectedAddress.country?.id);
            setValue("state", selectedAddress.state?.id);
            setValue("city", selectedAddress.city?.id);
            setValue("postal_code", selectedAddress.postal_code);
            setValue("type", selectedAddress.type);

            setSelectedCountry(selectedAddress.country?.id);
            setSelectedState(selectedAddress.state?.id);

            // Fetch states and cities based on the selected country and state
            if (selectedAddress.country?.id) {
                dispatch(fetchStateByCountry(selectedAddress.country.id));
            }
            if (selectedAddress.state?.id) {
                dispatch(fetchCityByState(selectedAddress.state.id));
            }
        } else {
            reset();
            setSelectedCountry(null);
            setSelectedState(null);
        }
    }, [editMode, selectedAddress, dispatch]);

    const handleCountryChange = (e) => {
        const countryId = e.target.value;
        setSelectedCountry(countryId);
        setSelectedState(null); // Reset state and city when country changes
        dispatch(fetchStateByCountry(countryId));
    };

    const handleStateChange = (e) => {
        const stateId = e.target.value;
        setSelectedState(stateId);
        dispatch(fetchCityByState(stateId));
    };


    const onSubmit = async (data) => {
        try {
            let result;
            if (editMode && selectedAddress) {
                setIsGstn(true);
                result = await axiosInstance.put(`user/address/${selectedAddress.id}`, data);
                console.log("Result_edit_seccuss___",result.data.notification );
                showConfirm(result.data.notification || "Address updated successfully");
                // Refresh the address list
                dispatch(fetchAddresses());
            } else {
                result = await axiosInstance.post('user/address', data);
                console.log("create_result__", result.data.notification);
                showConfirm(result.data.notification || "Address added successfully");
                // Refresh the address list
                dispatch(fetchAddresses());
            }

            reset();
            setFormVisible(false);
            setEditMode(false);
            // Refresh the addresses list
            refreshAddresses();
        } catch (err) {
            // console.log("Error__",err);
            showError(err.response?.data?.message || err.response?.data?.notification || "Something went wrong");
        }
    };

    if (!isFormVisible) return null;

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="show-form-address wd-form-address" id="formnewAddress"  style={{display: isFormVisible ? 'block' : 'none'}}>
            <div className="title">{ editMode ? "Edit Address" : "Add a new address" }</div>
                <div className="box-field">
                    <div className="tf-field style-1">
                        <input 
                            className="tf-field-input tf-input" 
                            placeholder=" " 
                            type="text" 
                            {...register("name", { required: "Name is required" })} 
                        />
                        <label className="tf-field-label fw-4 text_black-2" htmlFor="name">Name</label>
                        {errors.name && <span className="text-danger">{errors.name.message}</span>}
                    </div>
                </div>
                <div className="box-field">
                    <div className="tf-field style-1">
                        <input 
                            className="tf-field-input tf-input" 
                            placeholder=" " 
                            type="email" 
                            id="email" 
                            {...register("email", { required: "Email is required" })}
                        />
                        <label className="tf-field-label fw-4 text_black-2" htmlFor="email">Email</label>
                        {errors.email && <span className="text-danger">{errors.email.message}</span>}
                    </div>
                </div>
                <div className="box-field">
                    <div className="tf-field style-1">
                        <input 
                            className="tf-field-input tf-input" 
                            placeholder=" " 
                            type="text" 
                            id="phone" 
                            {...register("phone", { required: "Phone is required"})}
                        />
                        <label className="tf-field-label fw-4 text_black-2" htmlFor="phone">phone</label>
                        {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
                    </div>
                </div>
                <div className="box-field">
                    <div className="tf-field style-1">
                        <input 
                            className="tf-field-input tf-input" 
                            placeholder=" " 
                            type="text" 
                            id="address" 
                            {...register("address", { required: "Address is required" })}/>
                        <label className="tf-field-label fw-4 text_black-2" htmlFor="address">Address</label>
                        {errors.address && <span className="text-danger">{errors.address.message}</span>}
                    </div>
                </div>
                <div className="box-field">
                    <div className="box-field text-start">
                        <div className="box-checkbox fieldset-radio d-flex align-items-center gap-8">
                            <input 
                                type="checkbox" 
                                id="isGst" 
                                className="tf-check" 
                                checked={isGstn}
                                onClick={() => setIsGstn(!isGstn)} />
                            <label htmlFor="isGst" className="text_black-2 fw-4">If you are corporate customer, provide GST number.</label>
                        </div>
                    </div>
                    <div className={`tf-field style-1 ${isGstn ? "d-block" : "d-none"}`} >
                        <input 
                            className='tf-field-input tf-input'
                            placeholder=""
                            type="text" 
                            id="gstn"
                            {...register("gst_number")}/>
                        <label className="tf-field-label fw-4 text_black-2" htmlFor="gstn">GSTN Number</label>
                    </div>
                </div>
                <div className="box-field">
                    <label htmlFor="country" className="mb_10 fw-4 text-start d-block text_black-2 ">Country/Region</label>
                    <div className="select-custom">
                        <select 
                            className="tf-select w-100" 
                            id="country" 
                            {...register("country", { required: "Country is required" })} 
                            onChange={handleCountryChange}
                        >
                            <option value="---" data-provinces="[]">---</option>
                        {
                            country?.country?.map((data, index) => (
                                <option key={`country-${index}`} value={data.id}  defaultValue={selectedAddress?.country?.id || "---"}>{data.name}</option>
                            ))
                        }
                        </select>
                        {errors.country && <span className="text-danger">{errors.country.message}</span>}
                    </div>
                </div>
                <div className="box-field">
                    <label htmlFor="state" className="mb_10 fw-4 text-start d-block text_black-2 ">State</label>
                    <div className="select-custom">
                        <select 
                            className="tf-select w-100" 
                            id="state" 
                            {...register("state", { required: "State is required" })} 
                            data-default=""
                            onChange={handleStateChange} 
                            disabled={!selectedCountry}
                        >
                            <option value="---" data-provinces="[]">---</option>
                        {
                            state?.states?.map((data, index) => (
                                <option key={`country-${index}`} value={data.id} defaultValue={selectedAddress?.state?.id || "---"}>{data.name}</option>
                            ))
                        }
                        </select>
                        {errors.state && <span className="text-danger">{errors.state.message}</span>}
                    </div>
                </div>
                <div className="box-field">
                    <label htmlFor="city" className="mb_10 fw-4 text-start d-block text_black-2 ">City</label>
                    <div className="select-custom">
                        <select 
                            className="tf-select w-100" 
                            id="city" 
                            {...register("city", { required: "City is required" })} 
                            data-default=""
                        >
                            <option value="---" data-provinces="[]">---</option>
                        {
                            city?.cities?.map((data, index) => (
                                <option key={`country-${index}`} value={data.id} defaultValue={selectedAddress?.city?.id || "---"}>{data.name}</option>
                            ))
                        }
                        </select>
                        {errors.city && <span className="text-danger">{errors.city.message}</span>}
                    </div>
                </div>
                <div className="box-field">
                    <div className="tf-field style-1">
                        <input 
                            className="tf-field-input tf-input" 
                            placeholder=" " 
                            type="text" 
                            id="AddressZipNew" 
                            {...register("postal_code")} 
                        />
                        <label className="tf-field-label fw-4 text_black-2" htmlFor="AddressZipNew">Postal/ZIP code</label>
                    </div>
                </div>
                <div className="box-field">
                    <label htmlFor="type" className="mb_10 fw-4 text-start d-block text_black-2 ">Type</label>
                    <div className="select-custom">
                        <select 
                            className="tf-select w-100" 
                            id="type" 
                            {...register("type", { required: "Type is required" })} 
                            data-default=""
                        >
                            <option value="---" data-provinces="[]">---</option>
                            <option value='home'>Home</option>
                            <option value='office'>Office</option>
                        </select>
                        {errors.type && <span className="text-danger">{errors.type.message}</span>}
                    </div>
                </div>
                <div className="box-field text-start">
                    <div className="box-checkbox fieldset-radio d-flex align-items-center gap-8">
                        <input type="checkbox" id="check-new-address" className="tf-check"/>
                        <label htmlFor="check-new-address" className="text_black-2 fw-4">Set as default address.</label>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-20">
                    <button type="submit" className="tf-btn btn-fill animate-hover-btn">
                        { editMode ? "Update Address" : "Add Address" }
                    </button>
                    <span className="tf-btn btn-fill animate-hover-btn btn-hide-address" onClick={() => setFormVisible(false)}>Cancel</span>
                </div>
        </form>
    )
}
