import React from 'react'
import InnerBanner from '../elements/InnerBanner'
import { useSelector } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import ProductCard from '../elements/ProductCard';
import { useAddToCart } from '../../hooks/useAddToCart';
import { useWishlist } from '../../hooks/useWishlist';

export default function FlashSalePage() {
    const {
        flashSale, 
        products
    } = useSelector(state => state.flashSale);

    const { isProductInCartlist } = useAddToCart(); // No longer needs productId as a parameter
    const { isProductInWishlist } = useWishlist();

    return (
        <React.Fragment>
            <InnerBanner title={flashSale?.title}/>
            <section className="flat-spacing-2">
                <Container>
                    <Row>
                    {
                        products.map((product) => {
                            const inCart = isProductInCartlist(product.id);
                            const inWishlist = isProductInWishlist(product.id); // Check if product is in wishlist
                            return(
                            <div key={product.id} className="col-lg-3 col-md-4 col-6">
                                <ProductCard inCart={inCart} inWishlist={inWishlist} data={product}/>
                            </div>
                        )})
                    }
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
