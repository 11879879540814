import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../gobal/Global';
import axiosInstance from '../../gobal/AxiosSetting';


// Async action to fetch checkout data
export const fetchCheckout = createAsyncThunk(
    'checkout/fetchCheckout',
    async (couponCode, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/user/checkout?coupon=${couponCode}`);
            // console.log(response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Create a slice for checkout
const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        cartProducts: [],
        addresses: [],
        shippings: [],
        couponOffer: "",
        subtotal: 0,
        coupon_price: 0,
        total_price: 0,
        stripePaymentInfo: {},
        razorpayPaymentInfo: {},
        flutterwavePaymentInfo: {},
        paypalPaymentInfo: {},
        bankPaymentInfo: {},
        paystackAndMollie: {},
        instamojo: {},
        sslcommerz: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchCheckout.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchCheckout.fulfilled, (state, action) => {
            const {
            cartProducts,
            addresses,
            shippings,
            couponOffer,
            stripePaymentInfo,
            razorpayPaymentInfo,
            flutterwavePaymentInfo,
            paypalPaymentInfo,
            bankPaymentInfo,
            paystackAndMollie,
            instamojo,
            sslcommerz,
            subtotal,
            coupon_price,
            total_price,
            } = action.payload;

            state.cartProducts = cartProducts;
            state.addresses = addresses;
            state.shippings = shippings;
            state.couponOffer = couponOffer;
            state.subtotal = subtotal;
            state.coupon_price = coupon_price;
            state.total_price = total_price;
            state.stripePaymentInfo = stripePaymentInfo;
            state.razorpayPaymentInfo = razorpayPaymentInfo;
            state.flutterwavePaymentInfo = flutterwavePaymentInfo;
            state.paypalPaymentInfo = paypalPaymentInfo;
            state.bankPaymentInfo = bankPaymentInfo;
            state.paystackAndMollie = paystackAndMollie;
            state.instamojo = instamojo;
            state.sslcommerz = sslcommerz;

            state.loading = false;
        })
        .addCase(fetchCheckout.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || "Failed to fetch checkout data";
        });
    },
});

export default checkoutSlice.reducer;
